<script setup lang="ts">
import DistancePicker from './../distance-picker/DistanceV7.0.vue';
import type { PaddingType, SpacingOption, Spacing } from './../distance-picker/types';
import { ref, provide, computed } from 'vue';
import type { ObjectDevices } from '@gem/control';

type Props = {
  id: string;
  min?: number;
  mobileOnly?: boolean;
  value?: Spacing;
  default?: Spacing;
  label?: string;
  globalSpacing?: Record<string, ObjectDevices<string>>;
  currentScreen?: string;

  devices?: {
    mobile: PaddingType;
    tablet: PaddingType;
    desktop: PaddingType;
  };

  options: SpacingOption[];
  controlChange?: (id: string, value?: any) => void;
};

const props = defineProps<Props>();

provide('globalSpacing', props.globalSpacing);
provide('currentScreen', props.currentScreen);

const emit = defineEmits<{
  (e: 'controlOnChange', controlId: string, value: Spacing): void;
  (e: 'controlChange', controlId: string, value: Spacing): void;
  (e: 'onClickSubAction', type: string, value?: any): void;
}>();

const link = computed(() => {
  return props.value?.link;
});

const onChange = (property: keyof Spacing, value?: string) => {
  const [kind, proty] = property.split('-');
  if (link.value) {
    let newSpacing = {};
    if (kind === 'padding') {
      newSpacing = {
        link: true,
        padding: { bottom: value, left: value, right: value, top: value },
        margin: { ...props.value?.margin },
      };
    } else {
      newSpacing = {
        link: true,
        margin: { bottom: value, left: value, right: value, top: value },
        padding: { ...props.value?.padding },
      };
    }

    emit('controlChange', props.id, newSpacing);
  } else {
    emit('controlChange', props.id, {
      ...props.value,
      link: false,
      [kind]: { ...props.value?.[kind], [proty]: value },
    });
  }
};

const change = (property: string, value?: string) => {
  const [kind, proty] = property.split('-');
  if (link.value) {
    let newSpacing = {};

    if (kind === 'padding') {
      newSpacing = {
        link: true,
        padding: { bottom: value, left: value, right: value, top: value },
        margin: { ...props.value?.margin },
      };
    } else {
      newSpacing = {
        link: true,
        margin: { bottom: value, left: value, right: value, top: value },
        padding: { ...props.value?.padding },
      };
    }

    emit('controlChange', props.id, newSpacing);
  } else {
    emit('controlChange', props.id, {
      ...props.value,
      link: false,
      [kind]: { ...props.value?.[kind], [proty]: value },
    });
  }
};

const onClickSubAction = (type: string, value?: any) => {
  emit('onClickSubAction', type, value);
};

const changeLink = () => {
  const newSpacing = { ...props.value, link: !props.value?.link };
  emit('controlChange', props.id, newSpacing);
};

const margin = computed(() => {
  return props.value?.margin;
});

const padding = computed(() => {
  return props.value?.padding;
});
</script>

<template>
  <div>
    <slot name="label"></slot>
    <DistancePicker
      :id="id"
      :label="label"
      :options="options"
      :margin="margin"
      :padding="padding"
      :link="link"
      :min="min"
      :control-change="change"
      @change-link="changeLink"
      @control-on-change="onChange"
      @on-click-sub-action="onClickSubAction" />
  </div>
</template>
