import type { ThemeStyleUpdateMutationResponse } from '@/api/app/mutations/theme-style-update.generated';
import { useThemeStyleUpdateMutation } from '@/api/app/mutations/theme-style-update.generated';
import useNotification from '@/composables/useNotification';
import { useMobileOnly } from '../../common/hooks/useMobileOnly';
import { builderSetGlobalStyle } from '../../preview/utils/builder';
import useGlobalStyleStore from '../stores/globalStyle';
import type { GlobalStyleResponsiveConfig } from '@/types/global-style';
import { sentryCaptureException } from '../../common/use-cases/sentry';

export default function useSaveGlobalStyle() {
  const isMobileOnly = useMobileOnly();
  const { handleError } = useNotification();
  const themeStyleUpdate = useThemeStyleUpdateMutation({
    onError: (e) => {
      handleError(e);
    },
  });

  const globalStyleStore = useGlobalStyleStore();
  const currentGlobalStyleId = computed(() => globalStyleStore.currentGlobalStyleId);
  const currentGlobalStyle = computed(() => globalStyleStore.currentGlobalStyle);

  const globalStyle = reactive({
    style: currentGlobalStyle.value,
  });

  const setGlobalStyle = (style: GlobalStyleResponsiveConfig) => {
    globalStyle.style = style;
  };

  const save = (callback?: () => any) => {
    updateToDB(callback);
    setGlobalStyleStatusChange();
  };

  const isObjectEmpty = (objectName: object) => {
    return Object.keys(objectName).length === 0;
  };

  const isEmptyGlobalStyle = (style: GlobalStyleResponsiveConfig | undefined) => {
    return (
      !style ||
      isObjectEmpty(style.color) ||
      isObjectEmpty(style.radius) ||
      isObjectEmpty(style.spacing) ||
      isObjectEmpty(style.typography) ||
      isObjectEmpty(style.font)
    );
  };

  const updateToDB = (callback?: () => any) => {
    if (!currentGlobalStyleId.value) {
      return;
    }

    if (isEmptyGlobalStyle(globalStyle.style)) {
      sentryCaptureException('updateGlobalStyleToDB', 'Empty object global style', globalStyle, { level: 'error' });
      return;
    }
    themeStyleUpdate.mutate(
      {
        id: currentGlobalStyleId.value,
        input: {
          data: JSON.stringify(globalStyle.style),
        },
      },
      {
        onSuccess: (res) => {
          onSaveSuccess(res, callback);
        },
      },
    );
  };

  const onSaveSuccess = (res: ThemeStyleUpdateMutationResponse, callback?: () => any) => {
    if (!res.themeStyleUpdate.default) {
      return;
    }

    const globalStyleUpdate = JSON.parse(res.themeStyleUpdate.data);
    builderSetGlobalStyle({ data: globalStyleUpdate, mobileOnly: isMobileOnly.value });
    globalStyleStore.setCurrentGlobalStyle(globalStyleUpdate);
    callback && callback();
  };

  const setGlobalStyleStatusChange = () => {
    globalStyleStore.setIsChangeGlobalStyle(false);
  };

  return {
    save,
    globalStyle,
    currentGlobalStyleId,
    setGlobalStyle,
    setGlobalStyleStatusChange,
    isEmptyGlobalStyle,
  };
}
