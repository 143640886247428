import type * as Types from '@/types/graphql';

import type { ComputedRef, Ref } from 'vue';
import { useQuery, type UseQueryOptions } from 'vue-query';
import { appFetcher } from '@/api/gatewayClient';
export type ShopifyDefaultThemeQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ShopifyDefaultThemeQueryResponse = { shopifyDefaultTheme: Pick<Types.ShopifyTheme, 'id' | 'name'> };

export const ShopifyDefaultThemeDocument = `
    query shopifyDefaultTheme {
  shopifyDefaultTheme {
    id
    name
  }
}
    `;
export const useShopifyDefaultThemeQuery = <TData = ShopifyDefaultThemeQueryResponse, TError = unknown>(
  variables?:
    | ShopifyDefaultThemeQueryVariables
    | ComputedRef<ShopifyDefaultThemeQueryVariables>
    | Ref<ShopifyDefaultThemeQueryVariables>,
  options?: UseQueryOptions<ShopifyDefaultThemeQueryResponse, TError, TData>,
) =>
  useQuery<ShopifyDefaultThemeQueryResponse, TError, TData>(
    variables === undefined ? ['shopifyDefaultTheme'] : ['shopifyDefaultTheme', variables],
    appFetcher<ShopifyDefaultThemeQueryResponse, ShopifyDefaultThemeQueryVariables>(
      ShopifyDefaultThemeDocument,
      variables,
    ),
    options,
  );

useShopifyDefaultThemeQuery.getKey = (
  variables?:
    | ShopifyDefaultThemeQueryVariables
    | ComputedRef<ShopifyDefaultThemeQueryVariables>
    | Ref<ShopifyDefaultThemeQueryVariables>,
) => (variables === undefined ? ['shopifyDefaultTheme'] : ['shopifyDefaultTheme', variables]);
