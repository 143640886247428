<script lang="ts" setup>
import { ref, computed, provide } from 'vue';
import type { PaddingType, SpacingOption, Spacing } from './types';
import Inside from './Inside.vue';
import Outside from './Outside.vue';
import OutsideTriangle from './OutsideTriangle.vue';
import InsideTriangle from './InsideTriangle.vue';
import { onClickOutside } from '@vueuse/core';

type Props = {
  id?: string;
  label?: string;
  margin?: PaddingType;
  padding?: PaddingType;
  inside?: boolean;
  link?: boolean;
  min?: number;
  options: SpacingOption[];
  controlChange?: (id: string, value?: string) => void;
};

const props = defineProps<Props>();

const isHoverOutside = ref(false);
const isHoverInside = ref(false);

const buildInsideProperty = [
  {
    property: 'padding-top',
    class: 'top-0 ',
    pos: 'top',
  },
  {
    property: 'padding-left',
    class: 'top-0 left-0',
    pos: 'left',
  },
  {
    property: 'padding-bottom',
    class: 'bottom-0',
    pos: 'bottom',
  },
  {
    property: 'padding-right',
    class: 'right-0 ',
    pos: 'right',
  },
];

const buildOutsideProperty = [
  {
    property: 'margin-top',
    class: 'top-0 ',
    pos: 'top',
  },
  {
    property: 'margin-left',
    class: 'top-0 left-0',
    pos: 'left',
  },
  {
    property: 'margin-bottom',
    class: 'bottom-0',
    pos: 'bottom',
  },
  {
    property: 'margin-right',
    class: 'right-0',
    pos: 'right',
  },
];

const emit = defineEmits<{
  (e: 'controlOnChange', property: keyof Spacing, value?: string): void;
  (e: 'onClickSubAction', type: string, value?: any): void;
  (e: 'changeLink'): void;
}>();

const target = ref(null);
const insidePropertyDiffList = ref<Record<string, string>>({});
const outsidePropertyDiffList = ref<Record<string, string>>({});
const stateList = ref<Record<string, boolean>>({});
const openSelectSpacingList = ref<Record<string, boolean>>({});
const openSelectSpacingBy = ref<Record<string, boolean>>({});

onClickOutside(target, () => {
  isHoverOutside.value = false;
  isHoverInside.value = false;
});

const activeSquare = computed(() => {
  return (
    isHoverOutside.value ||
    isHoverInside.value ||
    openSelectSpacingBy.value['padding'] ||
    openSelectSpacingBy.value['margin']
  );
});

const fillInside = computed(() => {
  if (isHoverInside.value || openSelectSpacingBy.value['padding']) {
    return '#333333';
  }

  return '#212121';
});

const fillOutside = computed(() => {
  if (isHoverOutside.value || openSelectSpacingBy.value['margin']) {
    return '#333333';
  }

  return '#212121';
});

const squareBackground = computed(() => {
  if (activeSquare.value) {
    return '#424242';
  }

  return '#212121';
});

function changeLink() {
  emit('changeLink');
}

function controlChangeProp(property: keyof Spacing, value?: string): void {
  emit('controlOnChange', property, value);
}

function onControlChange(property: keyof Spacing, value?: string): void {
  props.controlChange?.(property, value);
}

function onClickSubAction(type: string, value?: any) {
  emit('onClickSubAction', type, value);
}

function insideMouseleave(pos: string) {
  stateList.value = {
    ...stateList.value,
    [pos]: false,
  };

  isHoverInside.value = false;
}

function insideMouseover(pos: string) {
  stateList.value = {
    ...stateList.value,
    [pos]: true,
  };

  isHoverInside.value = true;
}

function outsideMouseleave(pos: string) {
  stateList.value = {
    ...stateList.value,
    [pos]: false,
  };

  isHoverOutside.value = false;
}

function outsideMouseover(pos: string) {
  stateList.value = {
    ...stateList.value,
    [pos]: true,
  };

  isHoverOutside.value = true;
}

function insideWithValue(pos: keyof PaddingType) {
  return props.padding?.[pos];
}

function outsideWithValue(pos: keyof PaddingType) {
  return props.margin?.[pos];
}

function onSelectSpacing(property: string, isShow: boolean) {
  openSelectSpacingList.value[property] = isShow;
  const [kind] = property.split('-');
  openSelectSpacingBy.value[kind] = isShow;

  if (kind === 'margin') {
    isHoverOutside.value = isShow;
  } else {
    isHoverInside.value = isShow;
  }
}

function handleChangeInsideDiffValue(property: keyof SpacingOption, diff: string) {
  insidePropertyDiffList.value = {
    ...insidePropertyDiffList.value,
    [property]: diff,
  };
}

function handleChangeOutDiffValue(property: keyof SpacingOption, diff: string) {
  outsidePropertyDiffList.value = {
    ...insidePropertyDiffList.value,
    [property]: diff,
  };
}
</script>

<template>
  <div ref="target" class="box overflow-hidden rounded-xl" :style="{ background: squareBackground }">
    <div data-test="editor-control-spacing-outside" class="outside h-full w-full">
      <svg
        style="grid-area: 1 / 1 / -1 / -1"
        class="overflow-hidden"
        xmlns="http://www.w3.org/2000/svg"
        width="248"
        height="202"
        viewBox="0 0 248 202"
        fill="none">
        <OutsideTriangle
          v-for="(item, index) in buildOutsideProperty"
          :id="id"
          :key="index"
          :item="item"
          :fill-outside="fillOutside"
          :is-open-select-spacing="openSelectSpacingList[item.property]"
          :value="outsideWithValue(item.pos)"
          @outside-mouseover="outsideMouseover"
          @change-diff-value="handleChangeOutDiffValue"
          @outside-mouseleave="outsideMouseleave" />
      </svg>
      <Outside
        v-for="(item, index) in buildOutsideProperty"
        :id="id"
        :key="index"
        :options="options"
        :item="item"
        :is-hover="stateList[item.property]"
        :is-open-select-spacing="openSelectSpacingList[item.property]"
        :diff-value="outsidePropertyDiffList[item.property]"
        :value="outsideWithValue(item.pos)"
        :on-control-change="onControlChange"
        :on-select-spacing="onSelectSpacing"
        :control-change-prop="controlChangeProp"
        :on-click-sub-action="onClickSubAction"
        @outside-mouseover="outsideMouseover"
        @outside-mouseleave="outsideMouseleave"></Outside>
    </div>
    <div
      data-test="editor-control-spacing-inside"
      class="inside rounded-medium relative overflow-hidden border border-[#5B5B5B]">
      <button
        data-test="editor-control-spacing-synchronize"
        class="bg-dark-500 hover:border-dark-500 transform-center flex h-[34px] w-40 items-center justify-center rounded-xl border border-transparent hover:bg-[#424242]"
        @click.stop="changeLink"
        @mouseover="isHoverInside = true"
        @mouseleave="isHoverInside = false">
        <template v-if="isHoverInside || isHoverOutside">
          <g-base-icon
            v-if="!link"
            name="spacing-un-link"
            width="16px"
            height="16px"
            view-box="0 0 16 16"
            class="text-light-400">
          </g-base-icon>
          <g-base-icon
            v-else
            name="spacing-link"
            width="16px"
            height="16px"
            view-box="0 0 16 16"
            class="text-light-400"></g-base-icon>
        </template>
      </button>
      <svg
        class="overflow-hidden"
        style="grid-area: 1 / 1 / -1 / -1"
        width="140"
        height="116"
        viewBox="0 0 140 116"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <InsideTriangle
          v-for="(item, index) in buildInsideProperty"
          :id="id"
          :key="index"
          :is-open-select-spacing="openSelectSpacingList[item.property]"
          :fill-inside="fillInside"
          :item="item"
          :value="insideWithValue(item.pos)"
          @inside-mouseleave="insideMouseleave"
          @inside-mouseover="insideMouseover"
          @change-diff-value="handleChangeInsideDiffValue" />
      </svg>
      <Inside
        v-for="(item, index) in buildInsideProperty"
        :id="id"
        :key="index"
        :options="options"
        :item="item"
        :is-hover="stateList[item.property]"
        :is-open-select-spacing="openSelectSpacingList[item.property]"
        :diff-value="insidePropertyDiffList[item.property]"
        :value="insideWithValue(item.pos)"
        :on-control-change="onControlChange"
        :on-select-spacing="onSelectSpacing"
        :control-change-prop="controlChangeProp"
        :on-click-sub-action="onClickSubAction"
        @inside-mouseleave="insideMouseleave"
        @inside-mouseover="insideMouseover" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.box {
  position: relative;
  display: grid;
  height: 202px;
  grid-template-columns: 48px 4px 36px 1fr 36px 4px 36px;
  grid-template-rows: 38px 4px 16px 1fr 21px 7px 29px;
  outline-style: none;
  cursor: default;
  user-select: none;
}

.inside {
  grid-area: 3 / 3 / span 3 / span 3;
  display: grid;
  grid-template-columns: 49px 1fr 49px;
  grid-template-rows: 39px minmax(16px, 1fr) 39px;
  justify-items: center;
  width: 142px;
  height: 118px;
}

.outside {
  grid-area: 1 / 1 / -1 / -1;
  display: grid;
  grid-template-columns: 51px 1fr 51px;
  grid-template-rows: 41px minmax(16px, 1fr) 41px;
  justify-items: center;
}

.transform-center {
  @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform;
}
</style>
