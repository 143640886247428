import type * as Types from '@/types/graphql';

export type ProductSelectFragment = Pick<
  Types.Product,
  | 'averageRating'
  | 'baseID'
  | 'createdAt'
  | 'currentVersion'
  | 'deletedAt'
  | 'description'
  | 'descriptionMeta'
  | 'featuredImageId'
  | 'handle'
  | 'id'
  | 'platform'
  | 'publishedAt'
  | 'reviewCount'
  | 'sku'
  | 'status'
  | 'templateSuffix'
  | 'title'
  | 'titleMeta'
  | 'updatedAt'
  | 'vendor'
> & {
  featuredImage?: Types.Maybe<Pick<Types.Media, 'contentType' | 'previewImage' | 'src'>>;
  options?: Types.Maybe<
    Array<
      Pick<Types.ProductOption, 'id' | 'name' | 'optionType'> & {
        values: Array<
          Pick<Types.ProductOptionValue, 'baseID' | 'id' | 'isDefault' | 'label' | 'platform' | 'updatedAt'>
        >;
      }
    >
  >;
};

export const ProductSelectFragmentDoc = `
    fragment ProductSelect on Product {
  averageRating
  baseID
  createdAt
  currentVersion
  deletedAt
  description
  descriptionMeta
  featuredImageId
  featuredImage {
    contentType
    previewImage
    src
    contentType
    previewImage
  }
  handle
  id
  platform
  publishedAt
  reviewCount
  sku
  status
  options {
    id
    name
    optionType
    values {
      baseID
      id
      isDefault
      label
      platform
      updatedAt
    }
  }
  templateSuffix
  title
  titleMeta
  updatedAt
  vendor
}
    `;
