<script setup lang="ts">
import { Icon } from '@gem/control';
import { computed, reactive } from 'vue';
import { useInfinityIcons } from '../../hooks/useInfinityIcons';
import type { IconsQueryResponse, IconsQueryVariables } from '@/api/app/queries/icons.generated';

type IconType = { name: string; id: string; data: string };

type Props = {
  id?: string | number;
  value?: string;
  hideInput?: boolean;
  hideLabel?: boolean;
  customLabel?: string;
  sectionId: string;
  componentUid: string;
  controlLabel?: string;
};

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'controlChange', controlId?: string | number, value?: any): void;
}>();

const iconVariables = reactive<IconsQueryVariables>({
  first: 96,
  where: {
    nameContainsFold: '',
  },
});

const keys = computed(() => {
  return iconVariables.where;
});

const { data, isFetchingNextPage, fetchNextPage, isRefetching, isLoading } = useInfinityIcons(keys, iconVariables, {
  getNextPageParam: (args) => (args.icons?.pageInfo?.hasNextPage ? args.icons?.pageInfo?.endCursor : undefined),
});

const loading = computed(() => isFetchingNextPage.value || isRefetching.value || isLoading.value);

const iconList = computed(() => {
  if (!data.value?.pages?.length) {
    return [];
  }
  let list: IconType[] = [];
  data.value.pages.forEach((page) => {
    const pageIcons = getIconsFrom(page);
    list = [...list, ...pageIcons];
  });
  return list;
});

const getIconsFrom = (page: IconsQueryResponse): IconType[] => {
  if (!page.icons?.edges?.length) {
    return [];
  }
  return page.icons.edges.map((edge) => ({
    id: edge?.node?.id || '',
    data: edge?.node?.data || '',
    name: edge?.node?.name || '',
  }));
};

const controlChange = (controlId?: number | string, value?: string) => {
  emit('controlChange', controlId, value);
};

const controlSearch = (value?: string) => {
  iconVariables.where = {
    ...iconVariables.where,
    nameContainsFold: value,
  };
};

const showMoreIcons = () => {
  fetchNextPage.value();
};

const onSearch = (searchValue: string) => {
  if (iconVariables.where) {
    iconVariables.where.nameContainsFold = searchValue;
  }
};
</script>

<template>
  <div class="gemx-control">
    <slot name="label"></slot>
    <Icon
      :id="props.id"
      :value="props.value"
      :data="iconList"
      :loading="loading"
      :custom-label="customLabel"
      :hide-input="hideInput"
      :hide-label="hideLabel"
      @control-search="controlSearch"
      @control-change="controlChange"
      @show-more="showMoreIcons"
      @on-search="onSearch" />
    <slot name="info"></slot>
  </div>
</template>
