<script setup lang="ts">
import type { SpacingOption } from '@gem/control/src/control/distance-picker/types';
import type { ScreenType } from '../../types';
import { ref, computed } from 'vue';
import Inside from '../distance-picker/Inside.vue';
import InsideTriangle from '../distance-picker/InsideTriangle.vue';
import Segment from '../Segment.vue';
import type { PaddingConfig, PaddingOptions, PaddingValue } from '@gem/control/src/control/size-setting/type';

type Props = {
  id: string;
  label?: string;
  value?: PaddingValue;
  paddingConfig?: PaddingConfig;
  globalSpacingValues: SpacingOption[];
  currentScreen?: ScreenType;
  onClickSubAction?: (type: string, value?: any) => void;
};

type SpacingPosition = 'top' | 'left' | 'right' | 'bottom';

type PaddingPropertyKey = `padding-${SpacingPosition}`;

type PaddingProperties = {
  property: `padding-${SpacingPosition}`;
  class: string;
  pos: SpacingPosition;
  default?: number;
};

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'controlChange', id: string, value?: PaddingValue): void;
  (e: 'controlOnChange', id: string, value?: PaddingValue): void;
}>();

const insidePropertyDiffList = ref<Record<string, string>>({});
const isHover = ref(false);
const isOpenSelectSpacing = ref(false);
const stateList = ref<Record<string, boolean>>({});

const sizeConfig = computed(() => {
  return props.paddingConfig
    ? props.paddingConfig
    : {
        small: {
          vertical: '4px',
          horizontal: '16px',
        },
        medium: {
          vertical: '8px',
          horizontal: '24px',
        },
        large: {
          vertical: '12px',
          horizontal: '32px',
        },
      };
});

const spacingOptions = [
  {
    label: 'S',
    value: 'small',
  },
  {
    label: 'M',
    value: 'medium',
  },
  {
    label: 'L',
    value: 'large',
  },
  {
    label: 'Custom',
    value: 'custom',
    baseIcon: 'customize-layout',
  },
];

const buildInsideProperty: PaddingProperties[] = [
  {
    property: 'padding-top',
    class: 'top-0 ',
    pos: 'top',
    default: 0,
  },
  {
    property: 'padding-left',
    class: 'top-0 left-0',
    pos: 'left',
    default: 0,
  },
  {
    property: 'padding-bottom',
    class: 'bottom-0',
    pos: 'bottom',
    default: 0,
  },
  {
    property: 'padding-right',
    class: 'right-0 ',
    pos: 'right',
    default: 0,
  },
];

const padding = computed(() => props.value);
const linked = computed(() => props.value?.linked || false);
const fillInside = computed(() => {
  if (isHover.value) {
    return '#333333';
  }

  return '#212121';
});

const squareBackground = computed(() => {
  if (isHover.value) {
    return '#424242';
  }

  return '#212121';
});

function handleChangeInsideDiffValue(property: keyof SpacingOption, diff: string) {
  insidePropertyDiffList.value = {
    ...insidePropertyDiffList.value,
    [property]: diff,
  };
}

function insideMouseleave(pos: string) {
  stateList.value = {
    ...stateList.value,
    [pos]: false,
  };

  isHover.value = false;
}

function insideMouseover(pos?: SpacingPosition) {
  stateList.value = {
    top: false,
    left: false,
    bottom: false,
    right: false,
  };
  if (pos) {
    stateList.value = {
      ...stateList.value,
      [pos]: true,
    };
  }

  isHover.value = true;
}

const handleClickLink = () => {
  const newLinked = !linked.value;
  const currentPadding = {
    ...padding.value,
    linked: newLinked,
  };
  emit('controlChange', props.id, currentPadding);
};

function onSelectSpacing(property: string, isShow: boolean) {
  isOpenSelectSpacing.value = isShow;
  isHover.value = isShow;
}

function handleChange(property: PaddingPropertyKey, value?: string) {
  emit('controlChange', props.id, getDataChange(property, value));
}

function handleOnChange(property: PaddingPropertyKey, value?: string) {
  emit('controlOnChange', props.id, getDataChange(property, value));
}

function getDataChange(property: PaddingPropertyKey, value?: string, type?: PaddingOptions) {
  let currentPadding = padding.value;
  if (linked.value) {
    currentPadding = {
      top: value,
      bottom: value,
      left: value,
      right: value,
      type: type || 'custom',
      linked: linked.value,
    };
  } else {
    const position = property.replace('padding-', '') as SpacingPosition;
    currentPadding = {
      ...currentPadding,
      [position]: value,
      type: type || 'custom',
    };
  }
  return currentPadding;
}

function handleUpdatePaddingType(type?: string) {
  const newType = type as PaddingOptions;
  if (newType) {
    if (newType !== 'custom') {
      const sizeByOption = sizeConfig.value[newType];
      const newData: PaddingValue = {
        top: sizeByOption.vertical,
        bottom: sizeByOption.vertical,
        left: sizeByOption.horizontal,
        right: sizeByOption.horizontal,
        type: newType,
        linked: sizeByOption.vertical === sizeByOption.horizontal,
      };
      emit('controlChange', props.id, newData);
    } else {
      emit('controlChange', props.id, {
        ...props.value,
        type: 'custom',
      });
    }
  }
}
</script>

<template>
  <Segment
    id="padding-type"
    :value="props.value?.type || 'custom'"
    class="mb-16"
    :options="spacingOptions"
    @control-change="(id, value) => handleUpdatePaddingType(value)"
    @control-on-change="(id, value) => handleUpdatePaddingType(value)" />
  <div
    data-test="editor-control-spacing-inside"
    class="inside border-dark-250 relative overflow-hidden rounded-xl border"
    :style="{ background: squareBackground }">
    <button
      data-test="editor-control-spacing-synchronize"
      class="bg-dark-500 border-dark-250 hover:border-dark-500 transform-center hover:bg-dark-200 flex h-[36px] w-40 items-center justify-center rounded-xl border"
      @click.stop="handleClickLink"
      @mouseover="
        () => {
          isHover = true;
          insideMouseover();
        }
      "
      @mouseleave="isHover = false">
      <template v-if="isHover">
        <g-base-icon
          v-if="!linked"
          class="text-light-400"
          name="spacing-un-link"
          width="16px"
          height="16px"
          view-box="0 0 16 16">
        </g-base-icon>
        <g-base-icon
          v-else
          class="text-light-400"
          name="spacing-link"
          width="16px"
          height="16px"
          view-box="0 0 16 16"></g-base-icon>
      </template>
    </button>
    <svg
      class="overflow-hidden"
      style="grid-area: 1 / 1 / -1 / -1"
      width="140"
      height="116"
      viewBox="0 0 140 116"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <InsideTriangle
        v-for="(item, index) in buildInsideProperty"
        :id="id"
        :key="index"
        :is-open-select-spacing="isOpenSelectSpacing"
        :fill-inside="fillInside"
        :item="item"
        :value="padding?.[item.pos] === null ? item.default : padding?.[item.pos]"
        @inside-mouseleave="insideMouseleave"
        @inside-mouseover="insideMouseover"
        @change-diff-value="handleChangeInsideDiffValue" />
    </svg>
    <Inside
      v-for="(item, index) in buildInsideProperty"
      :id="id"
      :key="index"
      :options="globalSpacingValues"
      :item="item"
      :is-hover="stateList[item.property]"
      :is-open-select-spacing="isOpenSelectSpacing"
      :diff-value="insidePropertyDiffList[item.property]"
      :value="padding?.[item.pos] === null ? item.default : padding?.[item.pos]"
      :on-control-change="handleChange"
      :on-select-spacing="onSelectSpacing"
      :control-change-prop="handleOnChange"
      :on-click-sub-action="onClickSubAction"
      @inside-mouseleave="insideMouseleave"
      @inside-mouseover="insideMouseover" />
  </div>
</template>

<style scoped lang="scss">
.inside {
  grid-area: 3 / 3 / span 3 / span 3;
  display: grid;
  grid-template-columns: 49px 1fr 49px;
  grid-template-rows: 39px minmax(16px, 1fr) 39px;
  justify-items: center;
  width: 142px;
  height: 118px;
}

.transform-center {
  @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform;
}
</style>
