import { getConfig } from '@/config';
import type { UseQueryOptions } from 'vue-query';
import { useQuery } from 'vue-query';
import * as Sentry from '@sentry/vue';
import type { State as ShopState } from '../../../stores/shop';
import { isInIframe } from '@/utils/checkInIframe';

export type ShopLoginResponse = {
  code: number;
  message: string;
  shopID: string;
  details: string | null;
  shopifyDomain?: string;
  userHash?: string;
  token: string;
  status: 'CONNECTED' | 'NOT_CONNECTED';
  firstName?: string;
  isGemPagesV7?: string;
  plan?: string;
};

export default function useInstantShopLoginQuery(
  variable: {
    connectedType: ShopState['connectedType'];
    key?: string;
  },
  configOptions?: UseQueryOptions<any>,
) {
  return useQuery<ShopLoginResponse | undefined>(
    ['InstantShopLogin', variable],
    async () => {
      try {
        const res = await fetchShopLogin(variable.connectedType);
        return res;
      } catch (e) {
        if (configOptions && configOptions['onError']) {
          Object(configOptions)['onError'](e);
        }
        return;
      }
    },
    {
      ...configOptions,
      cacheTime: 0,
    },
  );
}

export const fetchShopLogin = async (
  connectType: ShopState['connectedType'],
  retries = 5,
): Promise<ShopLoginResponse | undefined> => {
  const transaction = Sentry?.getCurrentHub()?.getScope()?.getTransaction();

  const urlPath = connectType == 'gempagesv7' ? 'shop-login/gempagesv7' : 'shop-login/gempages';
  const res = await fetch(`${getConfig('url').gateway}${urlPath}`, {
    credentials: 'include', // include, *same-origin, omit
    headers: {
      'sentry-trace': `${transaction?.traceId}-${transaction?.spanId}`,
    },
  });

  // System error
  if (res.status >= 500) {
    if (retries > 0) {
      await sleep(500 * (5 - retries + 1));
      return fetchShopLogin(connectType, retries - 1);
    }
    throw new Error(res.statusText);
  }

  const resJson = (await res.json()) as ShopLoginResponse;

  // GemPages api me error
  if (resJson.code == 500100) {
    if (retries > 0) {
      await sleep(500 * (5 - retries + 1));
      return fetchShopLogin(connectType, retries - 1);
    }
  }

  // Can't permission access
  if (res.status == 401 || resJson.code == 401101 || resJson.code == 400120 || resJson.code == 400126) {
    const editorBackToUri = import.meta.env.VITE_EDITOR_BACK_TO_URI;
    if (editorBackToUri) {
      const { hostname } = new URL(editorBackToUri);
      if (isInIframe()) {
        window.parent.location.href = `https://${hostname}`;
      } else {
        window.location.href = `https://${hostname}`;
      }
    }
  }

  return resJson;
};

const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
