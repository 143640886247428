import type * as Types from '@/types/graphql';

import type { ComputedRef, Ref } from 'vue';
import { CollectionSelectFragmentDoc } from '../fragments/collection.generated';
import { useQuery, type UseQueryOptions } from 'vue-query';
import { appFetcher } from '@/api/gatewayClient';
export type CollectionsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['Cursor']>;
  before?: Types.InputMaybe<Types.Scalars['Cursor']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  orderBy?: Types.InputMaybe<Types.CollectionOrder>;
  where?: Types.InputMaybe<Types.CollectionWhereInput>;
}>;

export type CollectionsQueryResponse = {
  collections?: Types.Maybe<
    Pick<Types.CollectionConnection, 'totalCount'> & {
      edges: Array<
        Pick<Types.CollectionEdge, 'cursor'> & {
          node?: Types.Maybe<
            Pick<
              Types.Collection,
              | 'baseID'
              | 'createdAt'
              | 'deletedAt'
              | 'description'
              | 'descriptionMeta'
              | 'handle'
              | 'id'
              | 'platform'
              | 'tags'
              | 'templateSuffix'
              | 'title'
              | 'titleMeta'
              | 'updatedAt'
            > & {
              collectionMedia?: Types.Maybe<Pick<Types.CollectionMedia, 'src'>>;
              products?: Types.Maybe<
                Pick<Types.ProductConnection, 'totalCount'> & {
                  edges: Array<{
                    node?: Types.Maybe<{
                      featuredImage?: Types.Maybe<Pick<Types.Media, 'src' | 'contentType' | 'previewImage'>>;
                    }>;
                  }>;
                }
              >;
            }
          >;
        }
      >;
      pageInfo?: Types.Maybe<Pick<Types.PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>>;
    }
  >;
};

export const CollectionsDocument = `
    query collections($after: Cursor, $before: Cursor, $first: Int, $last: Int, $orderBy: CollectionOrder, $where: CollectionWhereInput) {
  collections(
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    where: $where
  ) {
    edges {
      cursor
      node {
        ...CollectionSelect
        collectionMedia {
          src
        }
        products {
          edges {
            node {
              featuredImage {
                src
                contentType
                previewImage
              }
            }
          }
          totalCount
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
  }
}
    ${CollectionSelectFragmentDoc}`;
export const useCollectionsQuery = <TData = CollectionsQueryResponse, TError = unknown>(
  variables?: CollectionsQueryVariables | ComputedRef<CollectionsQueryVariables> | Ref<CollectionsQueryVariables>,
  options?: UseQueryOptions<CollectionsQueryResponse, TError, TData>,
) =>
  useQuery<CollectionsQueryResponse, TError, TData>(
    variables === undefined ? ['collections'] : ['collections', variables],
    appFetcher<CollectionsQueryResponse, CollectionsQueryVariables>(CollectionsDocument, variables),
    options,
  );

useCollectionsQuery.getKey = (
  variables?: CollectionsQueryVariables | ComputedRef<CollectionsQueryVariables> | Ref<CollectionsQueryVariables>,
) => (variables === undefined ? ['collections'] : ['collections', variables]);
