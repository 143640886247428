<script lang="ts" setup>
import { computed, ref, watch } from 'vue';

const PLACEHOLDER = 'E.g. <svg fill="currentColor"...';
type Icon = { name: string; id: string; data: string };

type propsType = {
  id?: string | number;
  value?: string;
  data?: Icon[];
  loading?: boolean;
  customLabel?: string;
  hideInput?: boolean;
  hideLabel?: boolean;
  hideInput?: boolean;
};

const props = defineProps<propsType>();
const emit = defineEmits<{
  (e: 'controlChange', controlId?: number | string, value?: string): void;
  (e: 'controlSearch', value?: string): void;
  (e: 'showMore'): void;
  (e: 'on-search', value: string): void;
}>();

const selectedItem = ref({ id: '', value: '' });
const realValue = ref(props.value);
const refInput = ref<HTMLInputElement>();

watch(
  () => props.value,
  () => {
    realValue.value = props.value;
  },
);

const onShowMore = () => {
  emit('showMore');
};

const change = (svgIcon: string) => {
  realValue.value = svgIcon;
  emit('controlChange', props.id, svgIcon);
};

const options = computed(() => {
  return props.data?.map((record) => {
    return {
      ...record,
      value: record.data,
    };
  });
});

const onChangeSvgInput = (e: any) => {
  change(e.target.value);
};

const onSelect = (value: string, id: string) => {
  selectedItem.value.value =
    `<svg height="100%" width="100%" xmlns="http://www.w3.org/2000/svg" className="w-6 h-6"  viewBox="0 0 512 512" fill="currentColor">
              <path fill="currentColor" strokeLinecap="round" strokeLinejoin="round" fill="currentColor" d="` +
    value +
    `" /></svg>`;

  selectedItem.value.id = id;
  change(selectedItem.value.value);
};

const onClear = () => {
  realValue.value = '';
  refInput.value?.focus();

  emit('controlChange', props.id, '');
};

const listenerScroll = () => {
  onShowMore();
};

const onSearch = (searchValue: string) => {
  emit('on-search', searchValue);
};
</script>

<template>
  <div class="flex items-center justify-between" :class="{ '!justify-end': hideLabel }">
    <div v-if="!hideLabel" class="text-12 text-dark-low font-regular whitespace-nowrap">
      {{ customLabel ? customLabel : 'Choose icon' }}
    </div>
    <div class="w-[60px]" :class="{ 'w-[140px]': realValue === 'Mixed' }">
      <g-select
        id="setting-icons"
        :options="options"
        searchable
        fix-width
        display-value="icon"
        placeholder="Search icons"
        search-term="name"
        dropdown-classes="w-[217px] pt-12"
        :floating="false"
        :on-search="onSearch"
        @select="onSelect"
        @on-scroll="listenerScroll"
        @on-search="onSearch">
        <template #button-content>
          <span class="text-12 inline-block truncate">
            <div v-if="realValue" :class="[realValue !== 'Mixed' ? 'w-20' : 'w-100', 'h-20']" v-html="realValue"></div>
            <div v-else></div>
          </span>
        </template>
      </g-select>
    </div>
  </div>
  <div v-if="!hideInput" class="mt-16 flex items-center justify-between">
    <span class="text-12 text-dark-low font-regular">SVG code</span>
    <div
      class="bg-dark-400 w-input-horizontal border-dark-400 hover:border-dark-200 hover:bg-dark-200 focus-within:!bg-dark-400 focus-within:!border-primary-300 flex h-36 items-center overflow-hidden rounded-xl border px-8 outline-none transition duration-200 disabled:cursor-not-allowed"
      tabindex="0">
      <input
        ref="refInput"
        class="text-12 caret-primary-300 text-dark-high placeholder:text-dark-disabled disabled:border-dark-200 disabled:text-dark-disabled h-36 w-full text-ellipsis bg-transparent outline-none transition duration-200"
        :value="realValue"
        :placeholder="PLACEHOLDER"
        @input="onChangeSvgInput" />
      <button v-if="realValue" @click="onClear">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.85355 5.14645C5.65829 4.95118 5.34171 4.95118 5.14645 5.14645C4.95118 5.34171 4.95118 5.65829 5.14645 5.85355L7.29289 8L5.14645 10.1464C4.95118 10.3417 4.95118 10.6583 5.14645 10.8536C5.34171 11.0488 5.65829 11.0488 5.85355 10.8536L8 8.70711L10.1464 10.8536C10.3417 11.0488 10.6583 11.0488 10.8536 10.8536C11.0488 10.6583 11.0488 10.3417 10.8536 10.1464L8.70711 8L10.8536 5.85355C11.0488 5.65829 11.0488 5.34171 10.8536 5.14645C10.6583 4.95118 10.3417 4.95118 10.1464 5.14645L8 7.29289L5.85355 5.14645Z"
            fill="#E2E2E2" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8Z"
            fill="#E2E2E2" />
        </svg>
      </button>
    </div>
  </div>
</template>
