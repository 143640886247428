import type { ScreenType } from '@/types/custom';
import { fixedPresetConfig } from '@/types/global-style';
import type { Control, ControlUI, GroupTypeSetting } from './types';
export const defaultSupportDevices: ScreenType[] = ['desktop', 'tablet', 'mobile']; // Sort flow media-max
export const defaultSupportPages = [
  {
    id: 'INDEX',
    label: 'Home Page',
    params: {
      pageType: 'index',
    },
  },
  {
    id: 'PRODUCT',
    label: 'Product Page',
    params: {
      pageType: 'product',
    },
  },
  {
    id: 'COLLECTION',
    label: 'Collection Page',
    params: {
      pageType: 'collection',
    },
  },
  {
    id: 'CART',
    label: 'Cart Page',
    params: {
      pageType: 'cart',
    },
  },
  {
    id: 'BLOG',
    label: 'Blog Page',
    params: {
      pageType: 'blog',
    },
  },
  {
    id: 'ARTICLE',
    label: 'Article Page',
    params: {
      pageType: 'article',
    },
  },
  {
    id: 'PAGE',
    label: 'Static Page',
    params: {
      pageType: 'page',
    },
  },
];

export const ROOT_TAG = 'ROOT';
export const MODAL_TAG = 'Dialog';
export const ROW_TAG = 'Row';
export const SECTION_TAG = 'Section';
export const COL_TAG = 'Col';
export const ADD_SECTION_IMAGE_TO_LAYOUT_ID = 'gps-add-section-image-to-layout-of-bottom';
export const STICKY_TAG = 'Sticky';
export const ANY_SHOPIFY_APPS_TAG = 'ThirdPartySlot';

export const defaultSupportContents = [
  {
    id: 'PRODUCT',
    to: 'ProductContent',
    label: 'Products',
  },
  {
    id: 'COLLECTION',
    to: 'CollectionContent',
    label: 'Collections',
  },
  {
    id: 'BLOG',
    to: 'BlogContent',
    label: 'Blogs',
  },
  {
    id: 'ARTICLE',
    to: 'ArticleContent',
    label: 'Articles',
  },
];

export const advancedSetting: { id: GroupTypeSetting; controls: Control[] } = {
  id: 'advanced',
  controls: [
    {
      id: 'd',
      label: 'Visibility',
      option: {
        label: 'large',
      },
      type: 'visibility',
      hideOnMode: {
        mobileOnly: true,
      },
      devices: {
        desktop: {
          default: true,
        },
        tablet: {
          default: true,
        },
        mobile: {
          default: true,
        },
      },
    },
    {
      id: 'adv-spacing',
      type: 'group',
      label: 'Spacing',
      option: {
        label: 'large',
      },
      controls: [
        {
          id: 'spacing-setting',
          label: 'Spacing',
          option: {
            label: 'medium',
          },
          type: 'spacing',
          devices: {
            desktop: {},
          },
        },
      ],
    },
    {
      type: 'group',
      label: 'Border',
      option: {
        label: 'large',
      },
      id: 'behavior-state',
      controls: [
        {
          id: 'border',
          type: 'border',
          label: 'Border',
          option: {
            label: 'medium',
          },
          default: {
            borderType: 'none',
            border: 'solid',
            borderWidth: '0px',
            width: '0px',
            color: '#121212',
            isCustom: true,
          },
        },
        {
          id: 'rounded',
          type: 'radius-preset',
          label: 'Corner',
          option: {
            label: 'medium',
          },
          default: {
            btrr: '0px',
            bblr: '0px',
            bbrr: '0px',
            btlr: '0px',
            radiusType: 'none',
          },
        },
      ],
    },
    {
      type: 'group',
      id: 'boxShadowGroup',
      controls: [
        {
          id: 'hasBoxShadow',
          type: 'toggle',
          label: 'Shadow',
          option: {
            label: 'large',
          },
          simple: true,
          default: false,
          links: [
            {
              value: true,
              control: {
                ids: ['boxShadow'],
                show: true,
              },
            },
          ],
        },
        {
          id: 'boxShadow',
          type: 'boxShadow',
          default: {
            ...fixedPresetConfig.shadow['shadow-1'],
          },
        },
      ],
    },
    {
      type: 'group',
      controls: [
        {
          id: 'op',
          label: 'Opacity',
          option: {
            label: 'large',
          },
          type: 'range',
          min: 0,
          max: 100,
          step: 1,
          units: ['%'],
          devices: {
            desktop: {
              default: '100%',
            },
            tablet: {},
            mobile: {},
          },
        },
      ],
    },
    {
      id: 'position-group',
      type: 'group',
      controls: [
        {
          id: 'pos',
          type: 'select',
          label: 'Position',
          option: {
            label: 'large',
          },
          options: [
            {
              value: 'static',
              label: 'Static',
            },
            {
              value: 'relative',
              label: 'Relative',
            },
            {
              value: 'absolute',
              label: 'Absolute',
            },
            {
              value: 'sticky',
              label: 'Sticky',
            },
            {
              value: 'fixed',
              label: 'Fixed',
            },
          ],
          links: [
            {
              value: 'fixed',
              control: {
                ids: ['position-spacing-group'],
                show: true,
              },
            },
            {
              value: 'sticky',
              control: {
                ids: ['position-spacing-group'],
                show: true,
              },
            },
            {
              value: 'relative',
              control: {
                ids: ['position-spacing-group'],
                show: true,
              },
            },
            {
              value: 'absolute',
              control: {
                ids: ['position-spacing-group'],
                show: true,
              },
            },
          ],
          devices: {
            desktop: 'static',
            tablet: 'static',
            mobile: 'static',
          },
        },
        {
          id: 'position-spacing-group',
          type: 'group',
          label: 'Spacing',
          option: {
            label: 'large',
          },
          hide: true,
          controls: [
            {
              id: 'top',
              label: 'Top',
              option: {
                label: 'large',
              },
              type: 'input:unit',
              units: ['px', '%'],
              devices: {
                desktop: '0%',
                tablet: '0%',
                mobile: '0%',
              },
            },
            {
              id: 'right',
              label: 'Right',
              type: 'input:unit',
              units: ['px', '%'],
              devices: {
                desktop: '0%',
                tablet: '0%',
                mobile: '0%',
              },
            },
            {
              id: 'bottom',
              label: 'Bottom',
              type: 'input:unit',
              units: ['px', '%'],
              devices: {
                desktop: '0%',
                tablet: '0%',
                mobile: '0%',
              },
            },
            {
              id: 'left',
              label: 'Left',
              type: 'input:unit',
              units: ['px', '%'],
              devices: {
                desktop: '0%',
                tablet: '0%',
                mobile: '0%',
              },
            },
            {
              id: 'z',
              label: 'Z-Index',
              type: 'input:number',
              devices: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
              },
            },
          ],
        },
      ],
    },
    {
      id: 'editorData',
      type: 'customCodeEditor',
      rootClassName: '{{rootClassName}}',
    },
    {
      type: 'group',
      controls: [
        {
          id: 'cssClass',
          label: 'CSS class',
          option: {
            label: 'large',
          },
          type: 'input',
        },
      ],
    },
  ],
};

export const advancedSettingUI: ControlUI[] = [
  {
    type: 'group',
    label: {
      en: 'Visibility',
    },
    controls: [
      {
        type: 'control',
        layout: 'vertical',
        setting: {
          id: 'd',
        },
      },
    ],
  },
  {
    type: 'group',
    label: {
      en: 'Spacing (px)',
    },
    controls: [
      {
        type: 'control',
        layout: 'vertical',
        setting: {
          id: 'spacing-setting',
        },
      },
    ],
  },
  {
    type: 'control',
    label: {
      en: 'Opacity',
    },
    setting: {
      id: 'op',
    },
  },
  {
    type: 'group',
    label: {
      en: 'Shape',
    },
    controls: [
      {
        type: 'control',
        label: {
          en: 'Border',
        },
        setting: {
          id: 'border',
        },
      },
      {
        type: 'control',
        label: {
          en: 'Corner',
        },
        setting: {
          id: 'rounded',
        },
      },
      {
        type: 'control',
        label: {
          en: 'Shadow',
        },
        setting: {
          id: 'hasBoxShadow',
        },
      },
      {
        type: 'control',
        condition: 'hasBoxShadow == true',
        layout: 'vertical',
        setting: {
          id: 'boxShadow',
        },
      },
    ],
  },
  {
    type: 'group',
    label: {
      en: '',
    },
    controls: [
      {
        type: 'control',
        label: {
          en: 'Position',
        },
        setting: {
          id: 'pos',
        },
        options: {
          label: 'large',
        },
        searchKeyword: 'position',
      },
      {
        type: 'control',
        condition: 'pos && pos !== "static"',
        label: {
          en: 'Top',
        },
        setting: {
          id: 'top',
        },
        searchKeyword: 'position',
      },
      {
        type: 'control',
        condition: 'pos && pos !== "static"',
        label: {
          en: 'Right',
        },
        setting: {
          id: 'right',
        },
        searchKeyword: 'position',
      },
      {
        type: 'control',
        condition: 'pos && pos !== "static"',
        label: {
          en: 'Bottom',
        },
        setting: {
          id: 'bottom',
        },
        searchKeyword: 'position',
      },
      {
        type: 'control',
        condition: 'pos && pos !== "static"',
        label: {
          en: 'Left',
        },
        setting: {
          id: 'left',
        },
        searchKeyword: 'position',
      },
      {
        type: 'control',
        condition: 'pos && pos !== "static"',
        label: {
          en: 'Z-Index',
        },
        setting: {
          id: 'z',
        },
        searchKeyword: 'position',
      },
    ],
  },
  {
    type: 'control',
    label: {
      en: '',
    },
    setting: {
      id: 'editorData',
    },
    layout: 'vertical',
  },
  {
    type: 'group',
    label: {
      en: '',
    },
    controls: [
      {
        type: 'control',
        label: {
          en: 'CSS class',
        },
        setting: {
          id: 'cssClass',
        },
        options: {
          label: 'large',
        },
      },
    ],
  },
];

export const domainRegex =
  // eslint-disable-next-line no-useless-escape
  /^(((?!\-))(xn\-\-)?[a-z0-9\-_]{0,61}[a-z0-9]{1,1}\.)*(xn\-\-)?([a-z0-9\-]{1,61}|[a-z0-9\-]{1,30})\.[a-z]{2,}$/;

export const MAXIMUM_SECTION = 25;

export const LIMIT_DISPLAY_SECTION_WARNING = 21;
export const DELAY_TIMEOUT = 30;
export const VIEW_PORT_OFFSET = 8;
export const OFFSET_WITH_SELECTION = 8;

export const HIGH_PRIORITY_PARENT_COMPONENT_TAG = ['Product', 'ProductList'];

export const TEXT_ELEMENTS = [
  'Text',
  'Heading',
  'Accordion',
  'Tabs',
  'Button',
  'ProductButton',
  'SubmitButton',
  'ProductTag',
  'IconListV2',
];

export const CONTROL_ID_TEXT_ELEMENTS = ['text', 'childItem', 'label', 'outOfStockLabel', 'customContent'];
export const TEXT_ELEMENTS_PREVENT_ENTER = ['Button', 'ProductButton', 'SubmitButton', 'ProductTag'];

export const DEFAULT_ICON = {
  iconList: `<svg height="100%" width="100%" xmlns="http://www.w3.org/2000/svg" className="w-6 h-6"  viewBox="0 0 512 512" fill="currentColor"><path fill="currentColor" strokeLinecap="round" strokeLinejoin="round" fill="currentColor" d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z" /></svg>`,
  accordion: `<svg height="100%" width="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor"><path fill="currentColor" strokelinecap="round" strokelinejoin="round" d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path></svg>`,
};
