import type { CollectionsQueryResponse, CollectionsQueryVariables } from '@/api/app/queries/collections.generated';
import type { ProductsQueryResponse, ProductsQueryVariables } from '@/api/app/queries/products.generated';
import { useQueryClient } from 'vue-query';
import { useProductsQuery } from '@/api/app/queries/products.generated';
import { useCollectionsQuery } from '@/api/app/queries/collections.generated';
import { useFetchThemePage } from '@/modules/editor/modules/common/services/useFetchThemePage';
import useShopStore from '@/stores/shop';
import useAssignStore from '@/modules/assign/stores/assignment';
import { useShopifyDefaultThemeQuery } from '@/api/app/queries/shopify-default-theme.generated';

export const useViewEditorShopify = () => {
  const shopStore = useShopStore();
  const queryClient = useQueryClient();
  const assignStore = useAssignStore();

  const { data: themPageData } = useFetchThemePage();
  const shopifyDomain = computed(() => shopStore.getShopDomain);

  const shopifyName = computed(() => {
    return shopifyDomain.value?.toString().replace('.myshopify.com', '');
  });

  const templateSuffix = computed(() => {
    return `gp-template-${themPageData.value?.themePage?.id}`;
  });

  const { data: shopifyTheme } = useShopifyDefaultThemeQuery();
  const themeId = computed(() => {
    return shopifyTheme.value?.shopifyDefaultTheme?.id;
  });

  const originalPath = computed(() => {
    return `https://admin.shopify.com/store/${shopifyName.value}/themes/${themeId.value}/editor`;
  });

  const queryProductEnable = ref<boolean>(false);
  const queryCollectionEnable = ref<boolean>(false);
  // query product
  const variableProductQuery = reactive<ProductsQueryVariables>({
    first: 1,
    orderBy: {
      field: 'PLATFORM_UPDATED_AT',
      direction: 'DESC',
    },
  });
  useProductsQuery(variableProductQuery, {
    enabled: queryProductEnable,
    onSuccess(productData) {
      const productHandle = productData?.products?.edges?.[0]?.node?.handle || '';
      const url = originalPath + `?previewPath=/products/${productHandle}?view=${templateSuffix.value}`;
      openTabViewEditorShopify(url);
    },
  });

  // query collection
  const variableCollectionQuery = reactive<CollectionsQueryVariables>({
    first: 1,
    orderBy: {
      field: 'PLATFORM_UPDATED_AT',
      direction: 'DESC',
    },
  });
  useCollectionsQuery(variableCollectionQuery, {
    enabled: queryCollectionEnable,
    onSuccess(collectionData) {
      const collectionHandle = collectionData?.collections?.edges?.[0]?.node?.handle || '';
      const url = originalPath + `?previewPath=/collections/${collectionHandle}?view=${templateSuffix.value}`;
      openTabViewEditorShopify(url);
    },
  });

  const pickedDynamicProduct = computed(() => assignStore.getPickedDynamicProduct);
  const pickedDynamicCollection = computed(() => assignStore.getPickedDynamicCollection);

  const openURL = async () => {
    const themePage = themPageData.value?.themePage;
    const pageType = themePage?.type;
    const pageHandle = themePage?.handle;
    let newUrl = originalPath.value;
    let productHandle = '', collectionHandle = '';
    switch (pageType) {
      case 'GP_STATIC':
        newUrl += `?previewPath=/pages/${pageHandle}`;
        break;
      case 'GP_PRODUCT':
        if (pickedDynamicProduct.value?.productHandle) {
          productHandle = pickedDynamicProduct.value?.productHandle;
        } else {
          const objectBaseIds = themePage?.themePageOnlineStoreData?.onlineStoreObjectBaseIDs;
          if (objectBaseIds?.length) {
            variableProductQuery.where = {
              baseID: objectBaseIds[0],
            };
          } else if (themePage?.default) {
            variableProductQuery.where = {
              status: 'ACTIVE',
              templateSuffix: '',
            };
          } else {
            variableProductQuery.where = {
              status: 'ACTIVE',
            };
          }
          const productData = queryClient.getQueryData<ProductsQueryResponse>(
            useProductsQuery.getKey(variableProductQuery),
          );
          if (productData?.products?.edges?.length) {
            productHandle = productData?.products?.edges?.[0]?.node?.handle || '';
          } else {
            // Trigger query product
            queryProductEnable.value = true;
          }
        }
        newUrl += `?previewPath=/products/${productHandle}?view=${templateSuffix.value}`;
        break;
      case 'GP_COLLECTION':
        if (pickedDynamicCollection.value?.collectionHandle) {
          collectionHandle = pickedDynamicCollection.value?.collectionHandle;
        } else {
          // Add params to query
          const objectBaseIds = themePage?.themePageOnlineStoreData?.onlineStoreObjectBaseIDs;
          if (objectBaseIds?.length) {
            variableCollectionQuery.where = {
              baseID: objectBaseIds[0],
            };
          } else if (themePage?.default) {
            variableCollectionQuery.where = {
              // status: 'ACTIVE',
              templateSuffix: '',
            };
          } else {
            delete variableCollectionQuery.where;
            // variableCollectionQuery.where = {
            //   status: 'ACTIVE',
            // };
          }
          const collectionData = queryClient.getQueryData<CollectionsQueryResponse>(
            useCollectionsQuery.getKey(variableCollectionQuery),
          );
          if (collectionData) {
            collectionHandle = collectionData?.collections?.edges?.[0]?.node?.handle || '';
          } else {
            // Trigger query product
            queryCollectionEnable.value = true;
          }
        }
        newUrl += `?previewPath=/collections/${collectionHandle}?view=${templateSuffix.value}`;
        break;
      case 'GP_ARTICLE':
        newUrl += `?previewPath=/blogs/${pageHandle}`;
        break;
    }
    openTabViewEditorShopify(newUrl);
  };

  return { openURL };
};

const openTabViewEditorShopify = (url: string) => {
  window.open(url, '_blank');
};
