import type { Entries, ObjectDevices } from '../types';

export const removeNullUndefined = <T extends Record<string, any>>(obj: T) =>
  (Object.entries(obj) as Entries<T>).reduce((a, [k, v]) => (v == null ? a : ((a[k] = v), a)), {} as T);

export const getResponsiveValueByScreen = <T>(
  value?: ObjectDevices<T>,
  breakpoint?: keyof ObjectDevices<T>,
  defaultValue?: T,
) => {
  switch (breakpoint) {
    case 'tablet':
      return value?.tablet ?? value?.desktop ?? defaultValue;
    case 'mobile':
      return value?.mobile ?? value?.tablet ?? value?.desktop ?? defaultValue;
    default:
      return value?.desktop ?? defaultValue;
  }
};

export const fontWeightNameMapping: Record<string, string> = {
  '100': 'Thin',
  '200': 'Extra Light',
  '300': 'Light',
  '400': 'Normal',
  '500': 'Medium',
  '600': 'Semi Bold',
  '700': 'Bold',
  '800': 'Extra Bold',
  '900': 'Black',
  '950': 'Extra Black',
};

export function convertRatioStringToObject(ratio: string): { width: number; height: number } {
  const ratioArr = ratio.split('/');
  return ratioArr.length == 2
    ? {
        width: parseInt(ratioArr[0]) || 1,
        height: parseInt(ratioArr[1]) || 1,
      }
    : {
        width: 1,
        height: 1,
      };
}

export function getSizeFromRatioValue(
  ratio: string,
  size: {
    from: 'width' | 'height';
    value: string;
  },
): string {
  const ratioObj = convertRatioStringToObject(ratio);
  const sizeNumber = parseInt(size.value);
  return size.from === 'width'
    ? `${((sizeNumber * ratioObj.height) / ratioObj.width).toFixed(0)}px`
    : `${((sizeNumber * ratioObj.width) / ratioObj.height).toFixed(0)}px`;
}

export function isObjectEqual(obj1: any, obj2: any): boolean {
  // check if the objects are the same object
  if (obj1 === obj2) return true;

  // check if the objects are null or not objects
  if (obj1 == null || typeof obj1 != 'object' || obj2 == null || typeof obj2 != 'object') return false;

  // compare the number of properties in the objects
  const keysA = Object.keys(obj1),
    keysB = Object.keys(obj2);
  if (keysA.length != keysB.length) return false;

  // compare the properties and values of the objects
  for (const key of keysA) {
    if (!keysB.includes(key) || !isObjectEqual(obj1[key], obj2[key])) return false;
  }

  // if all of the properties and values are the same, the objects are equal
  return true;
}
