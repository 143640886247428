import type * as Types from '@/types/graphql';

export type ThemePageEdgeSelectFragment = Pick<Types.ThemePageEdge, 'cursor'> & {
  node?: Types.Maybe<
    Pick<
      Types.ThemePage,
      | 'id'
      | 'name'
      | 'description'
      | 'type'
      | 'default'
      | 'isGlobal'
      | 'isMobile'
      | 'status'
      | 'sectionPosition'
      | 'splitPercentage'
      | 'handle'
      | 'createdAt'
      | 'updatedAt'
      | 'publishedAt'
    > & {
      themeStyle?: Types.Maybe<Pick<Types.ThemeStyle, 'id' | 'data'>>;
      themePageOnlineStoreData?: Types.Maybe<Pick<Types.ThemePageOnlineStoreData, 'id' | 'onlineStoreObjectBaseIDs'>>;
    }
  >;
};

export type ThemePageSelectFragment = Pick<
  Types.ThemePage,
  | 'id'
  | 'name'
  | 'description'
  | 'type'
  | 'default'
  | 'isGlobal'
  | 'isMobile'
  | 'status'
  | 'sectionPosition'
  | 'splitPercentage'
  | 'handle'
  | 'createdAt'
  | 'updatedAt'
  | 'publishedAt'
> & {
  themeStyle?: Types.Maybe<Pick<Types.ThemeStyle, 'id' | 'data'>>;
  themePageOnlineStoreData?: Types.Maybe<Pick<Types.ThemePageOnlineStoreData, 'id' | 'onlineStoreObjectBaseIDs'>>;
};

export const ThemePageSelectFragmentDoc = `
    fragment ThemePageSelect on ThemePage {
  id
  name
  description
  type
  default
  isGlobal
  isMobile
  status
  sectionPosition
  splitPercentage
  themeStyle {
    id
    data
  }
  themePageOnlineStoreData {
    id
    onlineStoreObjectBaseIDs
  }
  handle
  createdAt
  updatedAt
  publishedAt
}
    `;
export const ThemePageEdgeSelectFragmentDoc = `
    fragment ThemePageEdgeSelect on ThemePageEdge {
  cursor
  node {
    ...ThemePageSelect
  }
}
    `;
