import type { PageSection } from '@/types/graphql';
import type { Component, ComponentConfig, GroupTypeSetting, InitComponentType } from '../types';
import { createMapIdControls } from './control';
import type { Control } from '../types';
import { ID } from '@/utils/ID';
import { cloneDeepObject, isDefined, isObject } from '@/utils/common';
import type { ScreenType } from '@/types/custom';
import { useBuilderConfigStore } from '@/modules/editor/modules/component-preset/stores/builder-config';
import { deepMergeObject } from '@/utils/common';
import { sentryCaptureException } from '../../use-cases/sentry';
import type { ComponentTag } from '@gem/control';
import { advancedSetting } from '../const';

export function convertComponentToJSON(component: string): Component | undefined {
  try {
    return JSON.parse(component);
  } catch (e) {
    sentryCaptureException(
      'convertComponentToJSON',
      'Parse component error',
      {
        component,
      },
      {
        level: 'error',
      },
    );
  }
}

export function convertComponentToString(component: Component): string {
  return JSON.stringify(component);
}

export function getComponentByUid(jsonComponent: Component, uid: string): Component | null {
  if (jsonComponent.uid == uid) {
    return jsonComponent;
  }
  if (jsonComponent.childrens?.length) {
    for (let i = 0; i < jsonComponent.childrens.length; i++) {
      const children = jsonComponent.childrens[i];
      const c = getComponentByUid(children, uid);
      if (c) {
        return c;
      }
    }
  }
  return null;
}

export function getComponentsByTag(jsonComponent: Component, tag: string, components: Component[]) {
  if (jsonComponent.tag == tag) {
    components.push(jsonComponent);
  }
  if (jsonComponent.childrens?.length) {
    for (let i = 0; i < jsonComponent.childrens.length; i++) {
      const children = jsonComponent.childrens[i];
      getComponentsByTag(children, tag, components);
    }
  }
  return components;
}

export function getParentComponentByUid(jsonComponent: Component, uid: string): Component | null {
  if (jsonComponent?.childrens?.length) {
    for (let i = 0; i < jsonComponent.childrens.length; i++) {
      const children = jsonComponent.childrens[i];
      if (children.uid == uid) {
        return jsonComponent;
      }
      const c = getParentComponentByUid(children, uid);
      if (c) {
        return c;
      }
    }
  }
  return null;
}

export function getIndexFromParent(parent: Component, uid: string) {
  const childrens = parent?.childrens ?? [];
  let toIndex = 0;
  childrens.forEach((child, index) => {
    if (child.uid === uid) {
      toIndex = index;
    }
  });
  return toIndex;
}

export function findParentByUidAndByParentTag(
  jsonComponent: Component,
  uid: string,
  parentTag: string | string[],
): Component | null {
  const lastParent = getParentComponentByUid(jsonComponent, uid);
  if (lastParent && lastParent?.uid) {
    if (lastParent?.tag === parentTag || (Array.isArray(parentTag) && parentTag.includes(lastParent.tag))) {
      return lastParent;
    }
    return findParentByUidAndByParentTag(jsonComponent, lastParent?.uid, parentTag);
  }
  return null;
}

export function findParentsByUid(jsonComponent: Component, uid: string, parents: Component[]): boolean {
  if (jsonComponent?.uid == uid) {
    parents.push(jsonComponent);
    return true;
  }
  if (jsonComponent?.childrens?.length) {
    for (let i = 0; i < jsonComponent.childrens.length; i++) {
      const node = jsonComponent.childrens[i];
      if (findParentsByUid(node, uid, parents)) {
        parents.push(jsonComponent);
        return true;
      }
    }
  }
  // no matches found - return false
  return false;
}

export function removeComponentByUid(component: string, uid: string): string {
  const jsonComponent = convertComponentToJSON(component);

  if (jsonComponent?.uid == uid) {
    // Remove all components
    return '';
  } else if (jsonComponent) {
    const parent = getParentComponentByUid(jsonComponent, uid);
    if (parent) {
      const childs = parent.childrens;
      if (childs) {
        const index = childs.findIndex((child: { uid: string }) => child.uid == uid);
        if (index > -1) {
          childs.splice(index, 1);
        }
      }
    }

    component = convertComponentToString(jsonComponent);
  }

  return component;
}

export function removeLayoutColByUid(component: string, uid: string): string {
  const jsonComponent = convertComponentToJSON(component);

  if (jsonComponent?.uid == uid) {
    // Remove all components
    return '';
  } else if (jsonComponent) {
    const parent = getParentComponentByUid(jsonComponent, uid);
    if (parent) {
      const childs = parent.childrens;
      if (childs) {
        const newCol = (childs?.length ?? 1) - 1;
        parent.settings = {
          ...parent.settings,
          layout: {
            desktop: {
              ...parent.settings?.layout?.desktop,
              cols: Array.from({ length: newCol }, () => 12 / newCol),
            },
          },
        };
        const index = childs.findIndex((child: { uid: string }) => child.uid == uid);
        if (index > -1) {
          childs.splice(index, 1);
        }
      }
    }
    component = convertComponentToString(jsonComponent);
  }

  return component;
}

export function addComponent(
  components: Component[],
  allComponent: string,
  toComponentUid: string,
  direction: 'before' | 'after' | 'children' | undefined,
): string {
  const jsonComponent = JSON.parse(allComponent);

  if (direction == 'children') {
    // Drag to children component
    const parent = getComponentByUid(jsonComponent, toComponentUid);
    if (parent) {
      const childs = parent.childrens || [];
      if (Array.isArray(components)) {
        for (let i = 0; i < components.length; i++) {
          const item = components[i];
          childs.push(item);
        }
      } else {
        childs.push(components);
      }
      parent.childrens = childs;
    }
  } else {
    const parent = getParentComponentByUid(jsonComponent, toComponentUid);
    if (parent) {
      const childs = parent.childrens;
      if (childs?.length) {
        let index = childs.findIndex((child: { uid: string }) => child.uid == toComponentUid);
        components.forEach((component) => {
          // Drag to before or after component
          if (direction == 'before' || direction == 'after') {
            if (direction == 'before') {
              if (index == 0) {
                childs.unshift(component);
                index++; // After element append
              } else {
                childs.splice(index, 0, component);
                index++; // After element append
              }
            } else {
              childs.splice(index + 1, 0, component);
              index++; // After element append
            }
          }
        });
      }
    }
  }

  allComponent = JSON.stringify(jsonComponent);
  return allComponent;
}

export function mapControlToComponentProp(
  component: InitComponentType,
  controls: Control[],
  groupType: GroupTypeSetting,
): Record<string, any> {
  const cloneData = cloneDeepObject(component);
  const data: Record<GroupTypeSetting, any> = {
    advanced: cloneData.advanced,
    setting: cloneData.settings,
    style: cloneData.styles,
  };

  const setting: Record<string, any> = data[groupType] || {}; // same props react
  const mapControls = createMapIdControls(controls);

  Object.entries(mapControls).forEach(([controlId, control]) => {
    if (control.devices && isObject(control.devices)) {
      Object.entries(control.devices).forEach(([deviceId, controlDevice]) => {
        if (controlDevice) {
          const controlValue = (controlDevice as any)?.default;
          if (controlValue !== undefined && controlValue !== null) {
            setting[controlId] = isObject(setting[controlId]) ? setting[controlId] : {};
            setting[controlId][deviceId] = controlValue;
          }
        }
      });
    } else {
      const controlValue = control.default;
      if (controlValue !== undefined && controlValue !== null) {
        if (control.id) {
          setting[control.id] = controlValue;
        }
      }
    }
  });

  return setting;
}

export function createArrayComponentsInComponent(component: Component): Component[] {
  const arr: Component[] = [];
  loopComponent(component, (component) => {
    arr.push(component);
  });
  return arr;
}

export type UpdateControlInput = {
  component: string;
  settings: {
    groupType: GroupTypeSetting;
    componentUid: string;
    controlType: string;
    controlId: string;
    newValue: any;
    screenId: ScreenType;
    hasDevices?: boolean;
  }[];
};

function handleTypeChildItem(
  currentComponent: Component,
  options: {
    controlType: string;
    newValue: any;
  },
) {
  const { controlType, newValue } = options;
  if (controlType !== 'child-item') return;
  if (!newValue.childrens) return;
  currentComponent.childrens = cloneDeepObject(newValue.childrens);
}

function handleTypeChildren(currentComponent: Component, options: { controlType: string; newValue: any }) {
  if (options?.controlType !== 'childrens') return;
  currentComponent.childrens = cloneDeepObject(options?.newValue);
}

function handleLayoutControl(
  currentComponent: Component,
  options: {
    screenId: ScreenType;
    newValue: any;
    groupType: GroupTypeSetting;
    controlType: string;
    controlId: string;
    hasDevices?: boolean;
    tag?: string;
  },
) {
  const { controlType, newValue, screenId } = options;
  const isLayout = ['layout', 'grid'].includes(controlType);
  const isDesktop = screenId === 'desktop';
  // Keep the column for products element
  if (isLayout && isDesktop) {
    // Regex convert css grid to array
    const gridToArrayRegex = /[^\s()]+(?:\([^\s()]+(?:\([^()]+\))?(?:, *[^\s()]+(?:\([^()]+\))?)*\))?/g; // Regex convert css grid to array

    const nColNumber =
      controlType === 'layout' ? newValue?.cols?.length ?? 0 : newValue.match(gridToArrayRegex).length ?? 0;
    const childComponent = cloneDeepObject(currentComponent.childrens) || [];

    const currentColNumber = childComponent.length;
    if (nColNumber > currentColNumber) {
      // Add new column
      const appendCols = Array.from<number, Component>({ length: nColNumber - currentColNumber }, () => ({
        uid: ID(),
        tag: 'Col',
        label: 'Block',
        settings: {},
        childrens: [],
      }));

      currentComponent.childrens = [...childComponent, ...appendCols];
    } else if (currentColNumber > nColNumber) {
      if (options?.tag === 'Product') return;
      // Remove col
      const nChildComponent = childComponent.slice(0, nColNumber);

      // Merge col left
      const childrenNeedMerge = childComponent
        .slice(nColNumber)
        .map((v) => v.childrens)
        .flat()
        .filter(isDefined);

      const lastChild = nChildComponent[nChildComponent.length - 1];
      if (lastChild) {
        nChildComponent[nChildComponent.length - 1].childrens = [...(lastChild.childrens ?? []), ...childrenNeedMerge];
      }

      currentComponent.childrens = nChildComponent;
    }
  }
}

function handleControlTypeOther(
  currentComponent: Component,
  options: {
    controlType: string;
    newValue: any;
    screenId: ScreenType;
    hasDevices?: boolean;
    groupType: GroupTypeSetting;
    controlId: string;
  },
) {
  if (options?.controlType === 'childrens') return;
  const { hasDevices, groupType, controlId, screenId, newValue, controlType } = options;
  handleLayoutControl(currentComponent, {
    ...options,
    tag: currentComponent?.tag,
  });

  // Control normal
  const styles = currentComponent?.styles || {};
  const settings = currentComponent?.settings || {};
  const advanced = currentComponent?.advanced || {};
  const data: Record<GroupTypeSetting, any> = {
    advanced,
    setting: settings,
    style: styles,
  };

  if (hasDevices) {
    if (newValue == undefined) {
      if (data?.[groupType]?.[controlId]?.[screenId]) {
        delete data[groupType][controlId][screenId];
      }
    } else {
      data[groupType][controlId] = { ...data[groupType][controlId], [screenId]: newValue };
    }
  } else {
    if (controlType === 'child-item') {
      data[groupType][controlId] = newValue.setting;
    } else {
      if (newValue == undefined) {
        if (data[groupType][controlId]) {
          delete data[groupType][controlId];
        }
      } else {
        data[groupType][controlId] = newValue;
      }
    }
  }

  switch (groupType) {
    case 'style': {
      currentComponent.styles = data[groupType];
      break;
    }
    case 'setting': {
      currentComponent.settings = data[groupType];
      break;
    }
    case 'advanced': {
      currentComponent.advanced = data[groupType];
      break;
    }
  }
}

// update value from control to component
export function updateControlValueInComponent({ component, settings }: UpdateControlInput) {
  // Replace setting component
  const jsonComponent = convertComponentToJSON(component);
  if (!jsonComponent) return component;

  for (let i = 0; i < settings.length; i++) {
    const setting = settings[i];
    const { componentUid, controlId, newValue, controlType, hasDevices, screenId, groupType } = setting;

    const currentComponent = getComponentByUid(jsonComponent, componentUid); // ref to memory
    if (!currentComponent) continue;

    handleTypeChildItem(currentComponent, {
      controlType,
      newValue,
    });

    handleTypeChildren(currentComponent, {
      controlType,
      newValue,
    });

    handleControlTypeOther(currentComponent, {
      controlType,
      newValue,
      screenId,
      hasDevices,
      groupType,
      controlId,
    });
  }

  component = convertComponentToString(jsonComponent);
  return component;
}

export function loopComponent(component: Component | undefined, callback: (component: Component) => void) {
  if (component) {
    callback(component);
  }
  if (component?.childrens?.length) {
    for (let i = 0; i < component.childrens.length; i++) {
      const children = component.childrens[i];
      loopComponent(children, callback);
    }
  }
}

export function createBuilderComponentBySection(
  section: PageSection & { isThemeSection?: boolean; needPublishing?: boolean },
  builderSettings: Record<string, any>,
) {
  if (section.component) {
    try {
      const component = convertComponentToJSON(section.component);
      if (component) {
        if (section?.isThemeSection && section?.name) {
          component.name = section.name;
          component.isThemeSection = true;
          component.needPublishing = section.needPublishing;
        }

        modifyComponentBeforeBuilderState(component, builderSettings);
      }
      return component;
    } catch (e) {
      sentryCaptureException(
        'createBuilderComponentBySection',
        'Parse component error',
        {
          section,
        },
        {
          level: 'error',
        },
      );
    }
  }
  return undefined;
}

export function modifyComponentBeforeBuilderState(component: Component, builderSettings: Record<string, any>) {
  loopComponent(component, (comp) => {
    const builderSetting = builderSettings[comp.tag];
    const newComponent: Component = {
      uid: ID(),
      tag: component.tag,
      settings: {},
      styles: {},
      advanced: {},
    };
    const advancedDefaultValue = mapControlToComponentProp(newComponent, advancedSetting.controls, 'advanced');
    comp.advanced = comp.advanced ? Object.assign(advancedDefaultValue, comp.advanced) : advancedDefaultValue;

    if (builderSetting?.editorConfigs) {
      comp.editorConfigs = builderSetting.editorConfigs || {};
    }
  });
}

export function generateNewUid(component: Component) {
  loopComponent(component, (componentItem) => {
    componentItem.uid = ID();
  });
}

export const initNewComponent = (
  tag: ComponentTag,
  override?: {
    uid?: string;
    label?: string;
    settings?: Record<string, any>;
    styles?: Record<string, any>;
    advanced?: Record<string, any>;
    noRenderInit?: boolean;
  },
  overrideFullWidth?: boolean,
): Component[] => {
  const builderConfigStore = useBuilderConfigStore();
  const builderConfigComponent = builderConfigStore.getBuilderSettingByTag(tag);
  if (!builderConfigComponent) {
    sentryCaptureException(
      'initNewComponent',
      "Can't find tag in builder settings",
      {
        tag,
      },
      {
        level: 'fatal',
      },
    );
    return [];
  }
  const initComponent = cloneDeepObject(builderConfigComponent);

  // Init group component
  if (initComponent.init?.length && !override?.noRenderInit) {
    if (tag === 'Section' && overrideFullWidth) {
      initComponent.init[0].styles = {
        fullWidth: {
          desktop: true,
          tablet: true,
          mobile: true,
        },
      };
    }
    const components = initNewPreset(initComponent.init);
    if (components?.length) {
      return components;
    }
  }

  // Init component
  const newComponent: Component = {
    uid: override?.uid ?? ID(),
    tag,
    label: override?.label ?? initComponent.label,
    customLabel: initComponent.customLabel || undefined,
    settings: override?.settings ?? {},
    styles: override?.styles ?? {},
    advanced: override?.advanced ?? {},
  };

  // Merge settings
  addDefaultValueAdvancedSetting(initComponent);
  initComponent?.settings?.forEach((setting) => {
    const data = mapControlToComponentProp(newComponent, setting.controls, setting.id);
    switch (setting.id) {
      case 'setting':
        newComponent.settings = deepMergeObject(data, newComponent.settings);
        break;
      case 'style':
        newComponent.styles = deepMergeObject(data, newComponent.styles);
        break;
      case 'advanced':
        newComponent.advanced = deepMergeObject(data, newComponent.advanced);
        break;
      default:
        break;
    }
  });
  return [newComponent];
};

export const initNewPreset = (components: InitComponentType[]) => {
  return components.map((initItem) => {
    const newComponent = initNewComponent(initItem.tag, {
      label: initItem.label,
      settings: initItem.settings,
      styles: initItem.styles,
      advanced: initItem.advanced,
      noRenderInit: true,
    })[0];
    if (initItem.childrens?.length) {
      newComponent.childrens = initNewPreset(initItem.childrens);
    }
    return { ...newComponent };
  }) as Component[];
};

function addDefaultValueAdvancedSetting(component: ComponentConfig): ComponentConfig {
  const advancedDefaultValue = advancedSetting;
  if (component?.settings) {
    const advancedSetting = component.settings.find((item: { id: string }) => item.id === 'advanced');
    if (advancedSetting) {
      advancedSetting.controls = Object.assign(advancedDefaultValue.controls, advancedSetting.controls);
    } else {
      component.settings.push(advancedDefaultValue);
    }
  }
  return component;
}

export const getFlowTags = (components: Component[] | InitComponentType[]) => {
  const builderConfig = useBuilderConfigStore();
  const builderSettings = builderConfig.getBuilderSettings;
  const flowTags: string[] = [];
  for (let i = 0; i < components.length; i++) {
    const component = components[i];
    const componentConfig = builderSettings[component.tag];
    const flowTag: string[] | undefined = componentConfig?.editorConfigs?.placeholder?.flowTag;
    if (flowTag?.length) {
      flowTag.forEach((item) => {
        flowTags.push(item);
      });
    }
  }
  return flowTags;
};

const getAllChildrenTags = (component: Component | InitComponentType, result: string[]) => {
  component.childrens?.forEach((child) => {
    if (result.indexOf(child.tag) === -1) result.push(child.tag);
    if (child.childrens?.length) getAllChildrenTags(child, result);
  });
};
export const getNotAppendTags = (components: Component[] | InitComponentType[]) => {
  const builderConfig = useBuilderConfigStore();
  const builderSettings = builderConfig.getBuilderSettings;
  let notAppendTags: string[] = [];
  const checkingChildrenTags: string[] = [];
  for (let i = 0; i < components.length; i++) {
    const component = components[i];
    if (component.childrens?.length) {
      const allChildrenTags: string[] = [];
      getAllChildrenTags(component, allChildrenTags);
      allChildrenTags.forEach((componentTag) => {
        const componentConfig = builderSettings[componentTag];
        if (componentConfig?.editorConfigs?.placeholder?.notAppendTags?.length) {
          checkingChildrenTags.push(componentTag);
          notAppendTags = [...notAppendTags, ...componentConfig.editorConfigs.placeholder.notAppendTags];
        }
      });
    }
    const componentConfig = builderSettings[component.tag];
    notAppendTags = [...notAppendTags, ...(componentConfig?.editorConfigs?.placeholder?.notAppendTags || [])];
  }
  return { checkingChildrenTags, notAppendTags };
};

export const getFlowPages = (components: Component[] | InitComponentType[]) => {
  const builderConfig = useBuilderConfigStore();
  const builderSettings = builderConfig.getBuilderSettings;
  const flowPages: string[] = [];
  for (let i = 0; i < components.length; i++) {
    const component = components[i];
    const componentConfig = builderSettings[component.tag];
    const flowPage: string | undefined = componentConfig?.editorConfigs?.placeholder?.flowPage;
    if (flowPage) {
      flowPages.push(flowPage);
    }
  }
  return flowPages;
};

export const checkExistComponentByTag = (jsonComponent: Component, tags: string[]) => {
  if (tags.includes(jsonComponent.tag)) {
    return true;
  }
  if (jsonComponent.childrens?.length) {
    for (let index = 0; index < jsonComponent.childrens?.length; index++) {
      const element = jsonComponent.childrens?.[index];
      const isExist = checkExistComponentByTag(element, tags);
      if (isExist) {
        return true;
      }
    }
  }
  return false;
};

export const convertDynamicProductAndProductListToStatic = (
  jsonComponent: Component,
  isProductPage?: boolean,
  isCollectionPage?: boolean,
) => {
  if (
    jsonComponent?.tag == 'Product' &&
    jsonComponent?.settings?.productSetting &&
    jsonComponent?.settings?.productSetting?.productStatus == 'dynamic' &&
    !isProductPage
  ) {
    jsonComponent.settings.productSetting.productStatus = 'static';
  }

  if (jsonComponent?.tag == 'ProductList' && jsonComponent?.settings?.productSetting) {
    if (isCollectionPage) {
      jsonComponent.settings.productSetting = {
        productSrc: 'DynamicCollection',
        productIds: [],
        collectionId: 'latest',
      };
    } else if (jsonComponent?.settings?.productSetting?.productSrc == 'DynamicCollection') {
      const collectionId = jsonComponent.settings.productSetting.collectionId;
      jsonComponent.settings.productSetting = {
        productSrc: collectionId ? 'Collection' : 'PickProduct',
        productIds: [],
        collectionId: collectionId ?? 'latest',
      };
    }
  }

  if (jsonComponent.childrens?.length) {
    for (let i = 0; i < jsonComponent.childrens.length; i++) {
      const children = jsonComponent.childrens[i];
      convertDynamicProductAndProductListToStatic(children, isProductPage);
    }
  }
  return jsonComponent;
};
