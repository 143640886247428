<script lang="ts" setup>
import { GReorder } from '@gem/uikit';
import { computed, ref, watch } from 'vue';
import ID from '../utils/ID';
import Item from './child-item/Item.vue';
import type { Component } from './child-item/types';

const emit = defineEmits<{
  (e: 'appendItem'): void;
  (e: 'duplicate', uid?: string, index?: string): void;
  (e: 'changeTitle', value: string, index: string): void;
  (e: 'moveItem', childItem: string[], from: number, to: number): void;
  (e: 'delete', uid?: string, index?: string): void;
  (e: 'handleAlert', type: 'error' | 'success', msg: string, time: number): void;
}>();

type Child = {
  id: string;
  title: string;
  icon?: string;
};

export type ChildItemTag =
  | 'AccordionItem'
  | 'Accordion'
  | 'TabItem'
  | 'CarouselItem'
  | 'IconListItem'
  | 'IconListItemHoz';

const props = withDefaults(
  defineProps<{
    id?: string;
    value?: Component;
    hasAddMore?: boolean;
    expandItem?: boolean;
    titleItem?: string;
    iconItem?: string;
    tag?: ChildItemTag;
  }>(),
  { hasAddMore: true, expandItem: false, titleItem: 'New Item' },
);

const list = computed(() => props?.value?.childrens);

const settings = computed<string[]>(() => props?.value?.settings?.childItem);

const convertData = () => {
  return settings.value.map((v) => {
    return {
      id: ID(),
      title: v,
    };
  });
};
const childItem = ref<Child[]>(convertData());
const listIdActive = ref<string[]>([]);

watch(
  () => settings.value,
  () => {
    childItem.value = convertData();
  },
);

const handleEditTitle = (index: string) => {
  if (listIdActive.value.includes(index)) {
    listIdActive.value = listIdActive.value?.filter((v) => v !== index);
  } else {
    listIdActive.value = [...listIdActive.value, index];
  }
};

const handleAppendItem = () => {
  const array = [...childItem.value];
  const lastListItem = array.sort((a, b) => a.title.localeCompare(b.title, undefined, { numeric: true }))[
    childItem.value.length - 1
  ];
  const lastOrder = !isNaN(Number(lastListItem.title.split(`${props.titleItem} `)[1]))
    ? Number(lastListItem.title.split(`${props.titleItem} `)[1])
    : 0;
  childItem.value = [...childItem.value, { id: ID(), title: `${props.titleItem} ${lastOrder + 1}` }];
  emit('appendItem');
};

const handleChangeTitle = (value: string, index: string) => {
  childItem.value[Number(index)].title = value;
  emit('changeTitle', value, index);
};

const handleDelete = (index: string) => {
  if (list.value?.length == 1 || childItem.value?.length == 1) {
    emit('handleAlert', 'error', 'You cannot delete all items', 3000);
    return;
  }

  const uid = list.value?.[Number(index)] ? list.value?.[Number(index)].uid : undefined;
  childItem.value = childItem.value.filter((v, i) => i !== Number(index));
  emit('delete', uid, index);
};

const handleDuplicate = (index: string) => {
  const uid = list.value?.[Number(index)] ? list.value?.[Number(index)].uid : undefined;
  const cloneListItem = JSON.parse(JSON.stringify(childItem.value));
  const items = [...childItem.value];
  const itemsSorted = items.sort((a, b) => a.title.localeCompare(b.title, undefined, { numeric: true }));
  const lastListItem = itemsSorted[itemsSorted.length - 1];
  const lastOrder = !isNaN(Number(lastListItem.title.split(`${props.titleItem} `)[1]))
    ? Number(lastListItem.title.split(`${props.titleItem} `)[1])
    : 0;

  const newItemTitle = props.expandItem ? cloneListItem[Number(index)].title : `${props.titleItem} ${lastOrder + 1}`;
  const newItem = { id: ID(), title: newItemTitle };

  cloneListItem.splice(Number(index) + 1, 0, newItem);
  childItem.value = [...cloneListItem];
  emit('duplicate', uid, index);
};

const handleChangeItem = (childItems: Child[], from?: number, to?: number) => {
  const newChildItem: string[] = childItems.map((v) => v.title);
  childItem.value = childItems;

  if (from?.toString() && to?.toString()) emit('moveItem', newChildItem, from, to);
};

const composeTitle = (title: string, index: number) => {
  if (props.tag && ['TabItem', 'AccordionItem'].includes(props.tag)) {
    return `${props.tag === 'TabItem' ? 'Tab ' : 'Accordion '} ${index + 1}`;
  }

  if (props.tag && ['IconListItemV2'].includes(props.tag)) {
    return `Item ${index + 1}`;
  }

  return title;
};
</script>

<template>
  <div class="gemx-control mt-8">
    <GReorder :items="childItem" @re-order="handleChangeItem">
      <template #default="{ item, index }">
        <Item
          :id="index.toString()"
          :index="index.toString()"
          :icon="iconItem"
          :tag="tag"
          :title="composeTitle(item.title, index)"
          :is-expand="expandItem"
          :is-active="
            tag && !['TabItem', 'AccordionItem'].includes(tag) ? listIdActive?.includes(index.toString()) : false
          "
          @edit="handleEditTitle"
          @change-title="handleChangeTitle"
          @duplicate="handleDuplicate"
          @delete="handleDelete"></Item>
      </template>
    </GReorder>
    <div
      v-if="props.hasAddMore"
      class="bg-dark-300 mt-8 inline-flex h-36 w-full cursor-pointer select-none items-center justify-center rounded-xl text-white transition-colors duration-300 hover:bg-white/20"
      @click="handleAppendItem">
      <span class="mr-8 inline-flex">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.625 4.375C10.625 4.02982 10.3452 3.75 10 3.75C9.65482 3.75 9.375 4.02982 9.375 4.375V9.375H4.375C4.02982 9.375 3.75 9.65482 3.75 10C3.75 10.3452 4.02982 10.625 4.375 10.625H9.375V15.625C9.375 15.9702 9.65482 16.25 10 16.25C10.3452 16.25 10.625 15.9702 10.625 15.625V10.625H15.625C15.9702 10.625 16.25 10.3452 16.25 10C16.25 9.65482 15.9702 9.375 15.625 9.375H10.625V4.375Z"
            fill="white" />
        </svg>
      </span>
      <span class="text-12 text-white">Add more</span>
    </div>
  </div>
</template>
