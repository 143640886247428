import type * as Types from '@/types/graphql';

import type { ComputedRef, Ref } from 'vue';
import { IconSelectFragmentDoc } from '../fragments/icon-select.generated';
import { useQuery, type UseQueryOptions } from 'vue-query';
import { appFetcher } from '@/api/gatewayClient';
export type IconsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['Cursor']>;
  before?: Types.InputMaybe<Types.Scalars['Cursor']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  orderBy?: Types.InputMaybe<Types.IconOrder>;
  where?: Types.InputMaybe<Types.IconWhereInput>;
}>;

export type IconsQueryResponse = {
  icons?: Types.Maybe<
    Pick<Types.IconConnection, 'totalCount'> & {
      edges?: Types.Maybe<
        Array<
          Types.Maybe<
            Pick<Types.IconEdge, 'cursor'> & {
              node?: Types.Maybe<
                Pick<Types.Icon, 'createdAt' | 'createdBy' | 'data' | 'id' | 'name' | 'updatedAt' | 'updatedBy'>
              >;
            }
          >
        >
      >;
      pageInfo: Pick<Types.PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>;
    }
  >;
};

export const IconsDocument = `
    query icons($after: Cursor, $before: Cursor, $first: Int, $last: Int, $orderBy: IconOrder, $where: IconWhereInput) {
  icons(
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    where: $where
  ) {
    edges {
      cursor
      node {
        ...iconSelect
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
  }
}
    ${IconSelectFragmentDoc}`;
export const useIconsQuery = <TData = IconsQueryResponse, TError = unknown>(
  variables?: IconsQueryVariables | ComputedRef<IconsQueryVariables> | Ref<IconsQueryVariables>,
  options?: UseQueryOptions<IconsQueryResponse, TError, TData>,
) =>
  useQuery<IconsQueryResponse, TError, TData>(
    variables === undefined ? ['icons'] : ['icons', variables],
    appFetcher<IconsQueryResponse, IconsQueryVariables>(IconsDocument, variables),
    options,
  );

useIconsQuery.getKey = (
  variables?: IconsQueryVariables | ComputedRef<IconsQueryVariables> | Ref<IconsQueryVariables>,
) => (variables === undefined ? ['icons'] : ['icons', variables]);
