import type * as Types from '@/types/graphql';

export type CollectionSelectFragment = Pick<
  Types.Collection,
  | 'baseID'
  | 'createdAt'
  | 'deletedAt'
  | 'description'
  | 'descriptionMeta'
  | 'handle'
  | 'id'
  | 'platform'
  | 'tags'
  | 'templateSuffix'
  | 'title'
  | 'titleMeta'
  | 'updatedAt'
> & { products?: Types.Maybe<Pick<Types.ProductConnection, 'totalCount'>> };

export const CollectionSelectFragmentDoc = `
    fragment CollectionSelect on Collection {
  baseID
  createdAt
  deletedAt
  description
  descriptionMeta
  handle
  id
  platform
  tags
  templateSuffix
  title
  titleMeta
  updatedAt
  products {
    totalCount
  }
}
    `;
