<script setup lang="ts">
import type { PaddingType, SpacingOption } from '@gem/control/src/control/distance-picker/types';
import type { ScreenType } from '../../types';
import FieldLabel from '../FieldLabel.vue';
import InputUnitWithOptions from './InputUnitWithOptions.vue';
import PaddingSetting from './PaddingSetting.vue';
import { computed, provide, ref } from 'vue';
import useUpdateDataSizeSetting from './useUpdateDataSizeSetting';
import Select from '../Select.vue';
import parseUnit from '@gem/control/src/helpers/parse-unit';

import type {
  ShapeOptions,
  SettingID,
  SizeSettingValue,
  SettingConfig,
} from '@gem/control/src/control/size-setting/type';
import type { ObjectDevices } from '@gem/control';
import { isPercent, reduceRatio } from '../../helpers/global-size';

type SizeSettingProps = {
  id: string;
  value?: SizeSettingValue;
  currentScreen?: ScreenType;
  hiddenSettings?: SettingID[];
  settingConfig?: Record<SettingID, SettingConfig>;
  globalStyleContainerWidth?: string;
  globalSpacingValues: SpacingOption[];
  globalSpacing?: Record<string, ObjectDevices<string>>;
  isShowResponsive?: boolean;
  hiddenShowMore?: boolean;
  collapsed?: boolean;
};

const props = defineProps<SizeSettingProps>();

const emit = defineEmits<{
  (e: 'onChange', controlId: string, value?: string): void;
  (e: 'change', controlId: string, value?: string): void;
  (e: 'changeScreen', screenId: ScreenType): void;
  (e: 'onClickSubAction', type: string, value?: any): void;
  (e: 'onClickCollapse', value: boolean): void;
}>();

const collapsed = computed(() => props.collapsed);
const inputWidthFocus = ref<boolean>(false);
const shapeLinked = computed(() => props.value?.shapeLinked || props.value?.shape !== 'custom' || false);
const isDisableLink = computed(() => props.value?.height?.includes('px') && isPercent(props.value?.width || ''));
const widthHeightLinked = computed(() => props.value?.widthHeightLinked);

provide('globalSpacing', props.globalSpacing);
provide('currentScreen', props.currentScreen);

const hiddenButtonShowMore = computed(
  () => props.hiddenShowMore || (isHiddenSetting('padding') && isHiddenSetting('gap')),
);

const shapeOptions = computed(() => {
  const shapeValueCustom = props.value?.customShapeValue;
  return [
    {
      label: 'Square',
      value: 'square',
      type: 'image-shape',
      icon: 'image-shape-square',
      tooltip: 'Square',
    },
    {
      label: 'Vertical',
      value: 'vertical',
      type: 'image-shape',
      icon: 'image-shape-vertical',
      tooltip: 'Vertical landscape',
    },
    {
      label: 'Horizontal',
      value: 'horizontal',
      type: 'image-shape',
      icon: 'image-shape-horizontal',
      tooltip: 'Horizontal landscape',
    },
    {
      label: 'Original',
      value: 'original',
      type: 'image-shape',
    },
    {
      label: 'Custom',
      des: shapeValueCustom ? reduceRatio(shapeValueCustom) : '',
      value: 'custom',
      baseIcon: 'customize-layout',
      tooltip: 'Custom size',
    },
  ];
});

const previousShapeValue = ref(props.value?.shape);

const { getValueWhenUpdateSetting } = useUpdateDataSizeSetting(computed(() => props));

const emitChange = (type: 'change' | 'onChange', data: SizeSettingValue) => {
  if (type === 'change') emit('change', props.id, data);
  else emit('onChange', props.id, data);
};

const handleUpdateHeight = (type: 'change' | 'onChange', value?: string) => {
  const newData = getValueWhenUpdateSetting('height', value, previousShapeValue, type);
  if (widthHeightLinked.value) {
    newData.width = newData.height;
  }

  if ((!value && props.value?.shape === 'custom' && type === 'change') || (value && props.value?.shape !== 'custom')) {
    emitChange('change', newData);
    return;
  }
  emitChange(type, newData);
};

const handleUpdateShape = (type: 'change' | 'onChange', value?: ShapeOptions) => {
  previousShapeValue.value = value;
  inputWidthFocus.value = value === 'custom';
  const newData = getValueWhenUpdateSetting('shape', value, previousShapeValue);
  emitChange(type, newData);
};

const handleUpdateWidth = (type: 'change' | 'onChange', value?: string) => {
  const newData = getValueWhenUpdateSetting('width', value, previousShapeValue, type);
  if (widthHeightLinked.value) {
    newData.height = newData.width;
  }

  emitChange(type, newData);
};

const handleUpdatePadding = (type: 'change' | 'onChange', value?: PaddingType) => {
  const newData = getValueWhenUpdateSetting('padding', value, previousShapeValue);
  emitChange(type, newData);
};

const handleUpdateGap = (type: 'change' | 'onChange', value?: string) => {
  const newData = getValueWhenUpdateSetting('gap', value, previousShapeValue);
  emitChange(type, newData);
};

const handleUpdateShapeLinked = (type: 'change' | 'onChange', value?: boolean) => {
  const newData = getValueWhenUpdateSetting('shapeLinked', value, previousShapeValue);
  emitChange(type, newData);
};

const handleUpdateData = (type: 'change' | 'onChange', settingID: string, value?: string | PaddingType | boolean) => {
  switch (settingID) {
    case 'height':
      handleUpdateHeight(type, value as string);
      break;
    case 'width':
      handleUpdateWidth(type, value as string);
      break;
    case 'shape':
      handleUpdateShape(type, value as ShapeOptions);
      break;
    case 'padding':
      handleUpdatePadding(type, value as PaddingType);
      break;
    case 'gap':
      handleUpdateGap(type, value as string);
      break;
    case 'shapeLinked':
      handleUpdateShapeLinked(type, value as boolean);
      break;
  }
};

const handleChange = (settingID: string, value?: string | PaddingType) => {
  if (settingID === 'width' && value && value !== 'default') {
    const [_, unit] = parseUnit(value as string);
    if (!widthUnits.value.includes(unit)) {
      value = `${value}px`;
    }
  }

  handleUpdateData('change', settingID, value);
};

const handleOnChange = (settingID: string, value?: string | PaddingType) => {
  handleUpdateData('onChange', settingID, value);
};

const handleLinkWidthHeight = () => {
  if (widthHeightLinked.value) return;
  const link = !props.value?.shapeLinked;
  handleUpdateData('change', 'shapeLinked', link);
};

function isHiddenSetting(settingID: SettingID) {
  return props.hiddenSettings?.includes(settingID);
}

const onClickSubAction = (type: string, value?: any) => {
  emit('onClickSubAction', type, value);
};

const widthUnits = computed(() => {
  return props.settingConfig?.width?.units ? (props.settingConfig?.width?.units as string[]) : ['px', '%'];
});
</script>
<template>
  <div
    v-if="!isHiddenSetting('shape')"
    class="gemx-control mb-16 flex min-h-[36px] w-full items-center justify-between gap-16">
    <FieldLabel
      :label="settingConfig?.shape?.name || 'Proportion'"
      :has-device="isShowResponsive"
      :current-screen="currentScreen"
      @change-screen="$emit('changeScreen', $event)" />
    <div class="max-w-input-horizontal w-full">
      <Select id="shape" disable-default :options="shapeOptions" :value="value?.shape" @control-change="handleChange" />
    </div>
  </div>
  <div v-if="!isHiddenSetting('width') || !isHiddenSetting('height')" class="relative">
    <div
      v-if="!isHiddenSetting('width')"
      class="gemx-control mb-16 flex min-h-[36px] w-full items-center justify-between gap-16">
      <FieldLabel
        :label="settingConfig?.width?.name || 'Width'"
        :has-device="isShowResponsive"
        :current-screen="currentScreen"
        @change-screen="$emit('changeScreen', $event)" />
      <InputUnitWithOptions
        id="width"
        :units="widthUnits"
        :value="value?.width"
        :size-config="settingConfig?.width?.sizeConfig"
        :display-options="settingConfig?.width?.displayOptions"
        :global-style-container-width="globalStyleContainerWidth"
        :focus="inputWidthFocus"
        @change="handleChange"
        @on-change="handleOnChange" />
    </div>
    <div
      v-if="!isHiddenSetting('height')"
      class="gemx-control mb-16 flex min-h-[36px] w-full items-center justify-between gap-16">
      <FieldLabel
        :label="settingConfig?.height?.name || 'Height'"
        :has-device="isShowResponsive"
        :current-screen="currentScreen"
        @change-screen="$emit('changeScreen', $event)" />
      <InputUnitWithOptions
        id="height"
        :units="['px']"
        :value="value?.height"
        :size-config="settingConfig?.height?.sizeConfig"
        :display-options="settingConfig?.height?.displayOptions"
        :global-style-container-width="globalStyleContainerWidth"
        @change="handleChange"
        @on-change="handleOnChange" />
    </div>
    <div
      v-if="!isHiddenSetting('height') && !isHiddenSetting('width') && (widthHeightLinked || !isHiddenSetting('shape'))"
      class="absolute top-[18px] right-[144px] flex items-center">
      <div class="flex h-[52px] w-24 flex-col items-end justify-between">
        <div class="border-dark-300 h-12 w-12 rounded-tl-[8px] border-t border-l"></div>
        <g-tooltip content-class="w-[180px] whitespace-normal !mt-[-20px]" placement="top" :disabled="!isDisableLink">
          <g-button
            :type="shapeLinked ? 'secondary' : 'darkGhost'"
            :only-icon="true"
            :disable="isDisableLink"
            size="small"
            :class="{
              'hover:bg-light-high bg-light-high border-none': widthHeightLinked,
            }"
            @click="handleLinkWidthHeight()">
            <template v-if="widthHeightLinked || !isHiddenSetting('shape')">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8 2C6.61934 2 5.5 3.11948 5.5 4.50025L5.5 6.5C5.5 6.77615 5.27614 7 5 7C4.72385 7 4.5 6.77614 4.5 6.5L4.5 4.50025C4.5 2.5673 6.06695 1 8 1C9.93305 1 11.5 2.56729 11.5 4.50023L11.5 6.5C11.5 6.77615 11.2761 7 11 7C10.7239 7 10.5 6.77614 10.5 6.5L10.5 4.50023C10.5 3.11947 9.38066 2 8 2Z"
                  fill="#757575" />
                <path
                  d="M5.00001 9C5.27615 9 5.50001 9.22386 5.5 9.5L5.5 11.4998C5.5 12.8805 6.61934 14 8 14C9.38066 14 10.5 12.8805 10.5 11.4998L10.5 9.5C10.5 9.22385 10.7239 9 11 9C11.2761 9 11.5 9.22386 11.5 9.5L11.5 11.4998C11.5 13.4327 9.93305 15 8 15C6.06695 15 4.5 13.4327 4.5 11.4998L4.5 9.5C4.50001 9.22385 4.72386 9 5.00001 9Z"
                  fill="#757575" />
                <path
                  d="M7.5 11C7.5 11.2761 7.72386 11.5 8 11.5C8.27614 11.5 8.5 11.2761 8.5 11L8.5 5C8.5 4.72386 8.27614 4.5 8 4.5C7.72386 4.5 7.5 4.72386 7.5 5L7.5 11Z"
                  fill="#757575" />
              </svg>
            </template>
            <template v-else>
              <g-base-icon v-if="!shapeLinked" name="spacing-un-link" width="16px" height="16px" view-box="0 0 16 16">
              </g-base-icon>
              <g-base-icon v-else name="spacing-link" width="16px" height="16px" view-box="0 0 16 16"></g-base-icon>
            </template>
          </g-button>
          <template #content>
            <div class="text-12">Use 'px' for width and height to keep the proportions when resizing</div>
          </template>
        </g-tooltip>
        <div class="border-dark-300 h-12 w-12 rounded-bl-[8px] border-b border-l"></div>
      </div>
    </div>
  </div>
  <template v-if="hiddenButtonShowMore ? true : !collapsed">
    <div
      v-if="!isHiddenSetting('padding')"
      class="gemx-control mb-16 flex min-h-[36px] w-full items-start justify-between gap-16">
      <FieldLabel
        :label="settingConfig?.padding?.name || 'Padding'"
        :has-device="isShowResponsive"
        :current-screen="currentScreen"
        @change-screen="$emit('changeScreen', $event)" />
      <div class="max-w-input-horizontal w-full">
        <PaddingSetting
          id="padding"
          :value="value?.padding"
          :global-spacing-values="globalSpacingValues"
          :padding-config="settingConfig?.padding?.paddingConfig"
          :on-click-sub-action="onClickSubAction"
          :current-screen="currentScreen"
          @control-change="handleChange"
          @control-on-change="handleOnChange" />
      </div>
    </div>
    <div
      v-if="!isHiddenSetting('gap')"
      class="gemx-control mb-16 flex min-h-[36px] w-full items-start justify-between gap-16">
      <FieldLabel
        :label="settingConfig?.gap?.name || 'Gap'"
        :has-device="isShowResponsive"
        :current-screen="currentScreen"
        @change-screen="$emit('changeScreen', $event)" />
      <div class="max-w-input-horizontal w-full">
        <InputUnitWithOptions
          id="gap"
          :units="['px']"
          :value="value?.gap ?? ''"
          :size-config="settingConfig?.gap?.sizeConfig"
          :display-options="settingConfig?.gap?.displayOptions"
          :global-style-container-width="globalStyleContainerWidth"
          @change="handleChange"
          @on-change="handleOnChange" />
      </div>
    </div>
  </template>
  <g-button
    v-if="!hiddenButtonShowMore"
    type="secondary"
    size="medium"
    class="mb-16 w-full"
    @click="emit('onClickCollapse', !collapsed)">
    <span class="text-12 flex select-none items-center font-medium">{{ collapsed ? 'Show more' : 'Show less' }}</span>
    <svg
      width="20"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="transition-all duration-300"
      :class="{
        'rotate-180': !collapsed,
      }">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.63313 8.12204C5.81064 7.95932 6.09845 7.95932 6.27596 8.12204L10.5 11.9941L14.724 8.12204C14.9016 7.95932 15.1894 7.95932 15.3669 8.12204C15.5444 8.28476 15.5444 8.54858 15.3669 8.71129L10.8214 12.878C10.6439 13.0407 10.3561 13.0407 10.1786 12.878L5.63313 8.71129C5.45562 8.54858 5.45562 8.28476 5.63313 8.12204Z"
        fill="currentColor" />
    </svg>
  </g-button>
</template>
