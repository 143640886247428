<script lang="ts" setup>
import Item from './child-icon/Item.vue';

withDefaults(
  defineProps<{
    id?: string;
    value?: string[];
    hasAddMore?: boolean;
    expandItem?: boolean;
    iconItem?: string;
    sectionId: string;
    componentUid: string;
  }>(),
  { hasAddMore: true },
);

const emit = defineEmits<{
  (e: 'change', value: string, index: string): void;
}>();

const handleChange = (value?: string, index?: string) => {
  if (index) emit('change', value ?? '', index);
};
</script>

<template>
  <div v-for="(item, index) in value" :key="index" class="gemx-control mt-8">
    <Item
      :id="id"
      :icon="item"
      :index="index.toString()"
      :section-id="sectionId"
      :component-uid="componentUid"
      @change="handleChange">
    </Item>
  </div>
</template>
