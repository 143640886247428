<script lang="ts" setup>
import { ref, watch } from 'vue';
import PickLinkModal from './modal/PickLinkModal.vue';
import Segment from './Segment.vue';
import type { TabType } from '../type/pickLink';

type List = { title?: string; handle?: string; id?: string };

type Props = {
  id?: string;
  value?: { link?: string; target?: string };
  list?: List[];
  loading?: boolean;
  instant?: boolean;
  pickLinkLabel?: string;
  pickLinkPlaceholder?: string;
  shopDomain?: string;
};

const props = defineProps<Props>();

const pickLinkVisibility = ref<boolean>(false);
const enteredInput = ref(!!props.value?.link?.length);
const refInput = ref<HTMLInputElement>();

const emit = defineEmits<{
  (e: 'control-search', value?: string): void;
  (e: 'control-on-change', controlId?: string | number, value?: any): void;
  (e: 'control-change', controlId?: string | number, value?: any): void;
  (e: 'control-focus', controlId?: string | number, value?: any): void;
  (e: 'control-blur', controlId?: string | number, value?: any): void;
  (e: 'set-tab-selected', tab?: TabType): void;
  (e: 'showMore'): void;
}>();

const val = ref(props.value ?? { link: '/', target: '_self' });
const targets = ref<{ label: string; value: string }[]>([
  { label: 'Current', value: '_self' },
  { label: 'New tab', value: '_blank' },
]);

const setTabSelected = (tab?: TabType) => {
  emit('set-tab-selected', tab);
};

const close = () => {
  pickLinkVisibility.value = false;
};

const handleClearUrl = () => {
  val.value.link = '';
  changeLink('');
  enteredInput.value = false;

  refInput.value?.focus();
};

const save = (value?: { link: string; target: string; title: string }) => {
  if (value) {
    val.value = value;

    if (value.link.length) {
      enteredInput.value = true;
    }

    emit('control-change', props.id, val.value);
  }
  pickLinkVisibility.value = false;
};

const changeTarget = (_id: any, value: any) => {
  val.value.target = value;
  emit('control-change', props.id, val.value);
};

const changeLink = (value: any) => {
  enteredInput.value = true;
  val.value.link = value;
  enteredInput.value = true;
  emit('control-change', props.id, val.value);
};
const onSearch = (value?: string) => {
  emit('control-search', value);
};

const handleOnBlurInput = (value?: string) => {
  if (value === '') enteredInput.value = false;
};

watch(pickLinkVisibility, (v) => {
  if (v) {
    setTabSelected('pages');
  }
});

watch(
  () => props.value,
  (value) => {
    if (value !== undefined) val.value = value;
  },
);
</script>
<template>
  <div class="gemx-control">
    <slot name="label"></slot>
    <div>
      <div class="flex flex-col">
        <div class="flex items-start justify-between" :class="{ 'mb-16': enteredInput }">
          <div class="gemx-control-label text-12 font-regular text-dark-low flex min-h-[36px] items-center pb-8 pt-10">
            {{ pickLinkLabel || 'Source URL' }}
          </div>
          <div class="w-[140px]">
            <div class="relative mb-8 w-full">
              <g-input
                ref="refInput"
                type="text"
                input-style="secondary"
                :placeholder="pickLinkPlaceholder ?? 'E.g. https://gempages.net'"
                :classes="val.link ? '!pr-32' : ''"
                :value="val.link"
                @on-change="changeLink"
                @handle-blur="handleOnBlurInput" />
              <g-base-icon
                v-if="val.link"
                name="close-round"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                class="text-dark-high absolute top-[calc(50%-8px)] right-8 cursor-pointer"
                @click="handleClearUrl" />
            </div>
            <g-button
              type="secondary"
              button-classes="flex w-full items-center justify-center"
              size="medium"
              @click="pickLinkVisibility = true">
              <div class="absolute flex items-center justify-center">
                <g-base-icon name="pick-link" width="16" height="16"></g-base-icon>
                <span class="text-12 text-dark-high ml-8 font-medium">Pick link</span>
              </div>
            </g-button>
          </div>
        </div>
        <div v-if="enteredInput" class="flex items-start justify-between">
          <div class="gemx-control-label text-12 font-regular text-dark-low flex items-center py-8">Open link in</div>
          <Segment id="target" class="!w-[140px]" :value="val.target" :options="targets" @control-change="changeTarget">
          </Segment>
        </div>
      </div>

      <PickLinkModal
        v-if="pickLinkVisibility"
        :list="list"
        :value="val"
        :loading="loading"
        :targets="targets"
        :instant="instant"
        :shop-domain="shopDomain"
        @close="close"
        @save="save"
        @show-more="emit('showMore')"
        @on-search="onSearch"
        @on-set-tab-selected="setTabSelected" />
    </div>
    <slot name="info"></slot>
  </div>
</template>
