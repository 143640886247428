<template>
  <Teleport to="body">
    <g-modal
      :is-open="galleryModelVisible"
      container-class="left-[337px] top-[50px] w-[38.75rem] h-[45rem]"
      modal-class="rounded-none"
      body-class="p-16 pt-0 bg-light-300"
      hide-actions
      show-btn-close
      header-class="cursor-default border-b border-light-400 px-0 py-0"
      @close="close">
      <template #title>
        <div class="flex items-center justify-between p-16 py-12 pr-8">
          <div class="text-16 text-light-high font-medium">Choose from gallery</div>
        </div>
      </template>
      <template #default>
        <TabGroup :selected-index="selectedIndex">
          <TabList class="border-b-dark-200/0.1 first-letter:r mb-16 flex space-x-4 border-b">
            <Tab v-for="tab in Object.keys(tabs)" :key="tab" v-slot="{ selected }" as="template">
              <button
                :class="[
                  'text-14 font-regular mr-16 rounded-lg py-8 text-sm leading-5',
                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none',
                  selected && 'border-b-primary-300 border-b-2 font-[500] text-[#0a0a3a]',
                ]"
                @click="handleSelectedTab(tab)">
                {{ Object(tabs)[tab] }}
              </button>
            </Tab>
          </TabList>
          <TabPanels class="mt-4 flex-1 overflow-hidden">
            <TabPanel :class="['ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none']">
              <div v-show="selectedTab === 'gallery'" class="custom-image-upload-modal-display-body">
                <perfect-scrollbar ref="imageListInfinityScroll" class="!h-[582px]">
                  <div
                    class="custom-image-upload-modal-display-body-list"
                    :class="{
                      'h-[136px]': !imageList?.length,
                    }">
                    <div class="custom-image_upload_button custom-image_upload_item flex items-center justify-around">
                      <input
                        id="input"
                        class="custom-item-upload-input h-full w-full"
                        type="file"
                        accept="image/*"
                        @change="handleChangeFile" />
                      <div class="custom-add_more_text">
                        <svg color="black" width="14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
                          <path
                            d="M7.5 0.5C7.5 0.223858 7.27614 0 7 0C6.72386 0 6.5 0.223858 6.5 0.5V6.5H0.5C0.223858 6.5 0 6.72386 0 7C0 7.27614 0.223858 7.5 0.5 7.5H6.5V13.5C6.5 13.7761 6.72386 14 7 14C7.27614 14 7.5 13.7761 7.5 13.5V7.5H13.5C13.7761 7.5 14 7.27614 14 7C14 6.72386 13.7761 6.5 13.5 6.5H7.5V0.5Z"
                            fill="currentColor" />
                        </svg>
                      </div>
                    </div>
                    <div
                      v-for="item in imageList"
                      :key="item?.node?.id"
                      class="custom-image_upload_item"
                      @click.stop="
                        handleClickImageItem({
                          filePath: item?.node?.filePath,
                          width: item?.node?.width,
                          height: item?.node?.height,
                          fileName: item?.node?.fileName,
                          backupFileKey: item?.node?.backupFileKey,
                          mimeType: item?.node?.mimeType,
                        })
                      ">
                      <div class="custom-image_wrap_image_item overflow-hidden">
                        <img
                          class="custom-image_item"
                          :src="getImageUrlByMimeType(item?.node)"
                          alt="image"
                          @load="loadingImageList(item?.node?.id)" />
                      </div>
                      <div
                        v-if="loading.indexOf(item?.node?.id) === -1"
                        class="custom-loading absolute h-full w-full"></div>
                      <div class="custom-background-item-blur">
                        <div v-if="!item.isShow">
                          <div class="custom-delete-label-isShow">
                            <div class="filename-wrap">
                              <p>{{ handleFileName(item.node) }}</p>
                            </div>
                            <p>{{ (item.node.size / 1024).toFixed(2) }} kb</p>
                          </div>
                          <div class="custom-delete_item" @click.stop="handleClickDelete(item?.node?.id)">
                            <svg
                              color="white"
                              width="14"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512">
                              <path
                                fill="currentColor"
                                d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"></path>
                            </svg>
                          </div>
                        </div>

                        <!-- <div v-else class="custom-delete_confirm" @click.stop="handleDeleteItemCancel(item?.node?.id)"> -->
                        <div v-else class="custom-delete_confirm" @click.stop="handleDeleteItemCancel(item?.node?.id)">
                          <div class="custom-delete-label">Delete Image?</div>
                          <div class="custom-delete-action">
                            <div
                              class="text-small rounded-medium ml-3 mr-8 mb-8 flex h-24 w-[56px] cursor-pointer items-center justify-center bg-[#494949] font-medium text-white decoration-white"
                              @click="handleDeleteItemCancel(item?.node?.id)">
                              Cancel
                            </div>
                            <div
                              class="text-small rounded-medium mr-8 mb-8 bg-[#f44336] px-8 py-4 text-white"
                              @click.stop="handleDeleteItem(item?.node?.id)">
                              Delete
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </perfect-scrollbar>
              </div>
            </TabPanel>
            <TabPanel
              :class="[
                'bg-light-300 h-full rounded-xl',
                'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none',
              ]">
              <div v-show="selectedTab === 'recentlyDeleted'" class="custom-image-upload-modal-display-body">
                <perfect-scrollbar ref="imageDeletedListInfinityScroll" class="!h-[582px]">
                  <div class="custom-image-upload-modal-display-body-list">
                    <div v-for="item in imageDeletedList" :key="item?.node?.id" class="custom-image_upload_item">
                      <div class="custom-image_wrap_image_item overflow-hidden">
                        <img
                          class="custom-image_item"
                          :src="getImageUrlByMimeType(item?.node)"
                          alt="image"
                          @load="loadingImageDeleted(item?.node?.id)" />
                      </div>
                      <div
                        v-if="loading.indexOf(item?.node?.id || '') === -1"
                        class="custom-loading absolute h-full w-full"></div>
                      <div class="custom-background-item-blur">
                        <div v-if="!item.node?.isShow" class="h-full">
                          <div class="custom-delete-label-isShow">
                            <div class="filename-wrap">
                              <p>{{ handleFileName(item.node) }}</p>
                            </div>
                            <p>{{ (item.node?.size || 0 / 1000).toFixed(2) }} kb</p>
                          </div>
                          <!-- <div
                              class="custom-delete-confirm_option"
                              @click.stop="handleClickRestoreDeleteItem(item?.node?.id)">
                              Recover
                            </div>
                            <div class="custom-delete_item" @click.stop="handleClickForceDeleteItem(item?.node?.id)">
                              <svg
                                color="red"
                                width="13"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512">
                                <path
                                  fill="currentColor"
                                  d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"></path>
                              </svg>
                            </div>
                          </div> -->
                          <!-- <div class="custom-delete_item" @click.stop="handleClickRestoreDeleteItem(item?.node?.id)"> -->
                          <div class="custom-delete-action flex w-full items-center justify-end">
                            <div
                              class="text-small ml-3 mr-8 mb-8 flex h-24 w-[71px] cursor-pointer items-center justify-center rounded-xl bg-[#494949] font-medium text-white decoration-white"
                              @click.stop="handleClickRestoreDeleteItem(item?.node?.id)">
                              Recover
                            </div>
                            <div
                              class="text-small mr-8 mb-8 rounded-xl bg-[#f44336] px-[7px] py-4"
                              @click.stop="handleClickForceDeleteItem(item?.node?.id)">
                              <svg
                                color="white"
                                width="14"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512">
                                <path
                                  fill="currentColor"
                                  d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"></path>
                              </svg>
                            </div>
                          </div>
                          <!-- </div> -->
                        </div>
                        <div v-else class="custom-delete_confirm">
                          <div class="custom-delete-label-isShow">
                            <p>Permanently Delete Image?</p>
                          </div>
                          <div class="custom-delete-action">
                            <div
                              class="text-small rounded-medium ml-3 mr-8 mb-8 flex h-24 w-[56px] cursor-pointer items-center justify-center bg-[#494949] font-medium text-white decoration-white"
                              @click.stop="handleForceDeleteItemCancel(item?.node?.id)">
                              Cancel
                            </div>
                            <div
                              class="text-small rounded-medium mr-8 mb-8 bg-[#f44336] px-8 py-4 text-white"
                              @click.stop="handleForceDeleteItem(item?.node?.id)">
                              Delete
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </perfect-scrollbar>
              </div>
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </template>
    </g-modal>
  </Teleport>
</template>
<script lang="ts" setup>
import { GModal } from '@gem/uikit';
import { ref, computed } from 'vue';
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue';
import { useInfiniteScroll } from '@vueuse/core';
import type { FileSelectFragment } from '../ImageUpload.vue';
import type * as Types from '../../type/graphql';

const props = defineProps<{
  galleryModelVisible?: boolean;
  imageSource?: any;
  imageList?: any[];
  imageDeletedList?: FileSelectFragment[];
  viewMore?: boolean;
  viewMoreDeleted?: boolean;
  maximumSize: number;
  allowedFiles: string[];
}>();

const emit = defineEmits<{
  (e: 'closeGalleryVisible', visible: boolean): void;
  (
    e: 'modalChange',
    value: {
      filePath: string;
      width: number;
      height: number;
      fileName: string;
      backupFileKey: string;
    },
  ): void;
  (e: 'deleteImage', id: string): void;
  (e: 'deleteForceImage', id: string): void;
  (e: 'showMore', value: string): void;
  (e: 'handleChangeListImage', value: string): void;
  (e: 'handleLoadImagesDeleted', value: string): void;
  (e: 'restore', id: string): void;
  (e: 'uploadImage', file: File): void;
  (e: 'isLoading', loading: boolean): void;
  (e: 'alert', value: 'size' | 'format' | ''): void;
  (e: 'updateImageProp', value: object): void;
  (e: 'deleteItemCancel', id: string): void;
}>();

const modalVisible = ref(props.galleryModelVisible);
const selectedTab = ref<'gallery' | 'recentlyDeleted'>('gallery');
const loading = ref<string[]>([]);
const imageListInfinityScroll = ref<HTMLElement | null>(null);
const imageDeletedListInfinityScroll = ref<HTMLElement | null>(null);
const MAX_CHARACTERS_ALLOWED = 28;
const tabs = {
  gallery: 'Gallery',
  recentlyDeleted: 'Recently Deleted',
};

const getImageUrlByMimeType = (imageData?: Types.File) => {
  if (imageData?.mimeType && imageData?.filePath) {
    const mimeType = imageData?.mimeType;
    if (mimeType === 'image/svg+xml') return `${imageData.filePath}-/format/auto/`;
  }
  return imageData?.filePath || '';
};
// image list
useInfiniteScroll(
  imageListInfinityScroll,
  () => {
    // load more
    if (props.viewMore) {
      emit('showMore', 'listImage');
    }
  },
  { distance: 100 },
);

// deleted list
useInfiniteScroll(
  imageDeletedListInfinityScroll,
  () => {
    // load more
    if (props.viewMoreDeleted) {
      emit('showMore', 'deleted');
    }
  },
  { distance: 100 },
);

const selectedIndex = computed(() => {
  const index = Object.keys(tabs).findIndex((key) => key === selectedTab.value);
  return index !== -1 ? index : 0;
});

const handleSelectedTab = (tab: string) => {
  if (selectedTab.value == tab) return;
  if (tab == 'gallery') {
    handleClickListImage();
  } else {
    handleClickDeleted();
  }
};

const handleFileName = (file: any) => {
  let fileName = '';
  const fileExtension = file.fileName.split('.').pop();

  //file name has no extension
  if (!fileExtension) {
    file.fileName.length > MAX_CHARACTERS_ALLOWED
      ? (fileName = `${file.fileName.substring(0, MAX_CHARACTERS_ALLOWED)}...`)
      : (fileName = file.fileName);
  } else {
    file.fileName.length > MAX_CHARACTERS_ALLOWED
      ? (fileName = `${file.fileName.substring(
          0,
          MAX_CHARACTERS_ALLOWED - fileExtension.length - 9,
        )}...${file.fileName.substring(
          file.fileName.length - fileExtension.length - 6,
          file.fileName.length - fileExtension.length - 1,
        )}.${fileExtension}`)
      : (fileName = file.fileName);
  }
  return fileName;
};

const postUploadImage = async (file: File) => {
  emit('uploadImage', file);
};

const handleClickImageItem = (data: any) => {
  emit('modalChange', {
    filePath: getImageUrlByMimeType(data),
    width: data.width,
    height: data.height,
    fileName: data.filename,
    backupFileKey: data.backupFileKey,
  });
  close();
};

const close = () => {
  modalVisible.value = false;
  emit('closeGalleryVisible', modalVisible.value);
};

const handleClickDeleted = () => {
  loading.value.length = 0;
  selectedTab.value = 'recentlyDeleted';
  emit('handleLoadImagesDeleted', 'deleted');
};

const handleClickListImage = () => {
  loading.value.length = 0;
  selectedTab.value = 'gallery';
};

const handleChangeFile = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (!target.files?.length) return;
  const file = target.files.item(0);
  if (!file) return;
  if (!props.allowedFiles.includes(file.type)) {
    emit('alert', 'format');
    close();
    return;
  }
  if (file.size > props.maximumSize) {
    emit('alert', 'size');
    close();
    return;
  }
  postUploadImage(file);
  emit('isLoading', true);
  close();
};

// Image List
const handleClickDelete = (id: string) => {
  emit('updateImageProp', { id, type: 'list', isShow: true });
};

// const handleDeleteItemCancel = (id: string) => {
//   emit('updateImageProp', { id, type: 'list', isShow: false });
// };

const handleDeleteItemCancel = (id: string) => {
  emit('deleteItemCancel', id);
};

// Image List Deleted
const handleClickForceDeleteItem = (id?: string) => {
  if (id) emit('updateImageProp', { id, type: 'deleted', isShow: true });
};

const handleForceDeleteItemCancel = (id: string) => {
  emit('updateImageProp', { id, type: 'deleted', isShow: false });
};

const handleClickRestoreDeleteItem = (id?: string) => {
  if (id) emit('restore', id);
};

const handleForceDeleteItem = (_id: string) => {
  emit('deleteForceImage', _id);
};

// Delete Image
const handleDeleteItem = (id: string) => {
  emit('deleteImage', id);
};

const loadingImageList = (id: string) => {
  loading.value.push(id);
};

const loadingImageDeleted = (id?: string) => {
  loading.value.push(id || '');
};
</script>

<style lang="scss" scoped>
.tung {
  width: 39em;
}
.custom-image-upload-modal-display {
  background-color: white;
  border-radius: 3px;

  &-body {
    min-height: 31rem;
    max-height: 36.4rem;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    &-list:not(.custom-image-list_none_image) {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
    }
    &-list {
      gap: 0.5rem;
      .custom-image_upload_item {
        position: relative;
        width: 100%;
        overflow: hidden;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .custom-item-upload-input {
          position: absolute;
          margin: 0;
          padding: 0;
          outline: none;
          opacity: 0;
          cursor: pointer;
          border-radius: 3px;
          z-index: 2;
          background-color: rgba(0, 0, 0, 0.05);
        }

        .custom-add_more_icon {
          max-height: 160px;
          img {
            max-height: 160px;
          }
        }
        // .custom-add_more_text {
        //   background: transparent;
        //   border-radius: 0.1875rem;
        //   color: #212121;
        //   cursor: pointer;
        //   font-size: 0.75rem;
        //   font-weight: 600;
        //   line-height: 1;
        //   padding: 0.25rem 0.375rem;
        //   position: relative;
        //   width: 14px;
        //   height: 14px;
        // }
        .custom-image_wrap_image_item {
          align-items: center;
          border-radius: 5px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          overflow: hidden;
          transform: translateZ(0);
          width: 100%;
          .custom-image_item {
            position: absolute;
            max-height: 100%;
            max-width: 100%;
          }
        }
        &:hover:not(.custom-image_upload_button) {
          background-color: #fafbfb;
          // border-color: #458fff;
          .custom-background-item-blur {
            display: block;
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            border-radius: 3px;
            background-color: rgba(37, 37, 37, 0.85);

            .custom-delete-label-isShow {
              margin: auto;
              height: 105px;
              text-overflow: ellipsis;
              .filename-wrap {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;

                p {
                  color: #fff;
                  margin: auto;
                  font-size: 14px;
                  padding: 5px 8px;
                  word-break: break-all;
                }
              }
              p {
                color: #fff;
                margin: auto;
                font-size: 14px;
                padding: 8px;
                text-overflow: ellipsis;
              }
            }
            .custom-delete_confirm {
              display: flex;

              .custom-delete-label {
                color: #fff;
                margin: 5px 0px 0px 10px;
                font-size: 14px;
              }

              .custom-delete-action {
                display: flex;
                flex-direction: row;
                position: absolute;
                bottom: 0;
                right: 0;
              }
            }
            .custom-delete_item {
              height: 25px;
              width: 25px;
              // background-color: #eff1f2;
              position: absolute;
              bottom: 0;
              right: 0;
              cursor: pointer;
              margin-right: 10px;
              margin-bottom: 10px;
              color: #43464b;
              font-size: 14px;
              font-weight: 500;
              border-radius: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              &:hover {
                background-color: rgba(255, 255, 255, 0.2);
              }
            }
          }
        }

        &img {
          object-fit: fill;
        }
        .custom-background-item-blur {
          display: none;
        }
        .overflow-hidden:after {
          content: '';
          display: block;
          padding-bottom: 100%;
          width: 100%;
        }
      }
      .custom-image_upload_button {
        border: 1px solid #eeeeee;
        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
    }
    .custom-image-list_none_image {
      .custom-image_upload_button {
        min-height: 31rem;
      }
    }
  }
}

@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 1000px 0;
  }
}

.custom-loading {
  background-image: linear-gradient(90deg, #e4e4e4 0%, #f1f1f1 40%, #ededed 60%, #e4e4e4 100%);
  background-position: 0px 0px;
  background-repeat: repeat;
  animation: animatedBackground 5s linear infinite;
}
</style>
