<script setup lang="ts">
const props = defineProps<{
  id: string;
  value?: any;
}>();

const emit = defineEmits<{
  (e: 'controlChange', controlId?: string, value?: boolean): void;
}>();

const OPTIONS = [
  {
    label: 'Update when buyer change in this product on page',
    value: true,
    icon: 'sync-product-properties-on',
  },
  {
    label: 'Independent',
    value: false,
    icon: 'sync-product-properties-off',
  },
];

const handleChange = (value: boolean) => {
  if (props.value != value) {
    emit('controlChange', props.id, value);
  }
};
</script>
<template>
  <div>
    <div
      v-for="(item, index) in OPTIONS"
      :key="index"
      ref="optionItem"
      @click="handleChange(item.value)"
      class="relative mb-8 flex h-64 cursor-pointer items-center justify-between overflow-hidden rounded-xl border border-transparent bg-[#212121] p-[7px] hover:bg-[#3B3B3B]"
      :class="{ 'border-[#3C67FF]': value == item.value }">
      <div class="relative z-10 flex items-center">
        <div class="flex h-[48px] w-[64px] items-center">
          <g-base-icon width="64" height="48" viewBox="0 0 64 48" :name="item?.icon"></g-base-icon>
        </div>
        <span class="text-12 ml-8 font-medium text-[#F9F9F9]"> {{ item.label }}</span>
      </div>
    </div>
  </div>
</template>
