import type * as Types from '@/types/graphql';

import type { ComputedRef, Ref } from 'vue';
import { ProductSelectFragmentDoc } from '../fragments/product.generated';
import { VariantSelectFragmentDoc } from '../fragments/variant.generated';
import { useQuery, type UseQueryOptions } from 'vue-query';
import { appFetcher } from '@/api/gatewayClient';
export type ProductsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['Cursor']>;
  before?: Types.InputMaybe<Types.Scalars['Cursor']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  orderBy?: Types.InputMaybe<Types.ProductOrder>;
  where?: Types.InputMaybe<Types.ProductWhereInput>;
  orderVariantBy?: Types.InputMaybe<Types.ProductVariantOrder>;
  variantsQuery?: Types.InputMaybe<Types.ProductVariantWhereInput>;
  firstVariant?: Types.InputMaybe<Types.Scalars['Int']>;
}>;

export type ProductsQueryResponse = {
  products?: Types.Maybe<
    Pick<Types.ProductConnection, 'totalCount'> & {
      edges: Array<
        Pick<Types.ProductEdge, 'cursor'> & {
          node?: Types.Maybe<
            Pick<
              Types.Product,
              | 'averageRating'
              | 'baseID'
              | 'createdAt'
              | 'currentVersion'
              | 'deletedAt'
              | 'description'
              | 'descriptionMeta'
              | 'featuredImageId'
              | 'handle'
              | 'id'
              | 'platform'
              | 'publishedAt'
              | 'reviewCount'
              | 'sku'
              | 'status'
              | 'templateSuffix'
              | 'title'
              | 'titleMeta'
              | 'updatedAt'
              | 'vendor'
            > & {
              variants?: Types.Maybe<{
                edges: Array<
                  Pick<Types.ProductVariantEdge, 'cursor'> & {
                    node?: Types.Maybe<
                      Pick<
                        Types.ProductVariant,
                        | 'barcode'
                        | 'baseID'
                        | 'costPrice'
                        | 'description'
                        | 'height'
                        | 'id'
                        | 'inventoryPolicy'
                        | 'inventoryQuantity'
                        | 'inventoryStatus'
                        | 'isDigital'
                        | 'length'
                        | 'lowInventoryAmount'
                        | 'manageInventory'
                        | 'mediaId'
                        | 'platform'
                        | 'price'
                        | 'salePrice'
                        | 'sku'
                        | 'soldIndividually'
                        | 'title'
                        | 'weight'
                        | 'width'
                      > & { selectedOptions: Array<Pick<Types.SelectedOption, 'name' | 'optionType' | 'value'>> }
                    >;
                  }
                >;
              }>;
              featuredImage?: Types.Maybe<Pick<Types.Media, 'contentType' | 'previewImage' | 'src'>>;
              options?: Types.Maybe<
                Array<
                  Pick<Types.ProductOption, 'id' | 'name' | 'optionType'> & {
                    values: Array<
                      Pick<Types.ProductOptionValue, 'baseID' | 'id' | 'isDefault' | 'label' | 'platform' | 'updatedAt'>
                    >;
                  }
                >
              >;
            }
          >;
        }
      >;
      pageInfo?: Types.Maybe<Pick<Types.PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>>;
    }
  >;
};

export const ProductsDocument = `
    query products($after: Cursor, $before: Cursor, $first: Int, $last: Int, $orderBy: ProductOrder, $where: ProductWhereInput, $orderVariantBy: ProductVariantOrder, $variantsQuery: ProductVariantWhereInput, $firstVariant: Int = 1) {
  products(
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    where: $where
  ) {
    edges {
      cursor
      node {
        ...ProductSelect
        variants(orderBy: $orderVariantBy, where: $variantsQuery, first: $firstVariant) {
          edges {
            cursor
            node {
              ...VariantSelect
            }
          }
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
  }
}
    ${ProductSelectFragmentDoc}
${VariantSelectFragmentDoc}`;
export const useProductsQuery = <TData = ProductsQueryResponse, TError = unknown>(
  variables?: ProductsQueryVariables | ComputedRef<ProductsQueryVariables> | Ref<ProductsQueryVariables>,
  options?: UseQueryOptions<ProductsQueryResponse, TError, TData>,
) =>
  useQuery<ProductsQueryResponse, TError, TData>(
    variables === undefined ? ['products'] : ['products', variables],
    appFetcher<ProductsQueryResponse, ProductsQueryVariables>(ProductsDocument, variables),
    options,
  );

useProductsQuery.getKey = (
  variables?: ProductsQueryVariables | ComputedRef<ProductsQueryVariables> | Ref<ProductsQueryVariables>,
) => (variables === undefined ? ['products'] : ['products', variables]);
