<script lang="ts" setup>
import { ref, watch } from 'vue';

const emit = defineEmits<{
  (e: 'edit', index: string): void;
  (e: 'changeTitle', value: string, index: string): void;
  (e: 'duplicate', index: string): void;
  (e: 'delete', index: string): void;
}>();

export type ChildItemTag =
  | 'AccordionItem'
  | 'Accordion'
  | 'TabItem'
  | 'CarouselItem'
  | 'IconListItem'
  | 'IconListItemHoz';

const props = defineProps<{
  index?: string;
  title?: string;
  icon?: string;
  tag?: ChildItemTag;
  isActive?: boolean;
  isExpand?: boolean;
}>();

const showAction = ref(false);
const hasHover = ref(false);
const title = ref(props.title);

const triggerDestroyTextInlineForIcon = () => {
  const customEvent = new CustomEvent('destroyTextInlineForIcon', { detail: null });
  dispatchEvent(customEvent);
};

const handleDeleteItem = () => {
  triggerDestroyTextInlineForIcon();
  showAction.value = true;
};

const cancelDeleteItem = () => {
  showAction.value = false;
};

const deleteItem = () => {
  showAction.value = false;
  triggerDestroyTextInlineForIcon();
  if (props.index) emit('delete', props.index);
};

const showEditTitle = () => {
  if (props.index && props.isExpand) emit('edit', props.index);
};

const handleDuplicateItem = () => {
  if (props.index) emit('duplicate', props.index);
};

const onChange = (value: string) => {
  title.value = value;
  if (props.index) emit('changeTitle', value, props.index);
};

const preventDrag = (event: DragEvent) => {
  event.preventDefault();
  event.stopPropagation();
};

watch(
  () => props.title,
  (value) => {
    title.value = value;
  },
);
</script>

<template>
  <div :class="{ 'border-dark-300 mb-8 overflow-hidden rounded-xl border': isActive }">
    <div
      :class="{
        'mb-8 rounded-xl': !isActive,
        'rounded-t-xl border-b-0': isActive,
      }"
      class="bg-dark-400 group-[.sortable-fallback]/sortable-item:bg-dark-400 relative flex h-36 cursor-ns-resize select-none items-center justify-between overflow-hidden pl-8 group-[.sortable-ghost]/sortable-item:bg-white/20 group-[&:not(.dragging)]/sortable:hover:bg-white/20"
      @mouseover="hasHover = true"
      @mouseleave="hasHover = false">
      <div class="relative z-10 flex items-center">
        <span
          class="hover:text-dark-high mr-4 inline-flex cursor-pointer p-4 text-white transition-all duration-200"
          :class="{ 'rotate-180': isActive }"
          @click="showEditTitle()">
          <template v-if="tag && ['IconListItemHoz', 'TabItem', 'AccordionItem'].includes(tag)">
            <div v-html="icon"></div>
          </template>
          <template v-else>
            <g-base-icon v-if="!isExpand" name="item-drag" width="16" height="16" viewBox="0 0 16 16"></g-base-icon>
            <g-base-icon v-else name="instant-arrow-down" width="16" height="16" viewBox="0 0 16 16"></g-base-icon>
          </template>
        </span>
        <span
          class="text-12 text-light-100 truncate"
          :class="{ 'max-w-[114px]': showAction, 'max-w-[160px]': !showAction }"
          v-html="title">
        </span>
      </div>
      <div
        v-if="!showAction"
        class="non-draggable relative z-10 flex h-full items-center justify-center px-8 hover:cursor-pointer">
        <template v-if="hasHover">
          <span
            class="text-dark-high hover:text-dark-high cursor-pointer p-4 transition-all duration-200"
            @click.stop="handleDuplicateItem">
            <g-base-icon name="duplicate-page" width="16" height="16"></g-base-icon>
          </span>
          <span
            class="text-dark-high hover:text-dark-high cursor-pointer p-4 transition-all duration-200"
            @click.stop="handleDeleteItem">
            <g-base-icon name="trash" width="16" height="16"></g-base-icon>
          </span>
        </template>
      </div>
      <div v-else class="non-draggable flex h-full items-center gap-4 px-8 hover:cursor-pointer">
        <g-button
          type="grey"
          class="!bg-dark-200 text-dark-high border-none p-4 !px-4 hover:!bg-[#5B5B5B]"
          size="small"
          @click.stop="cancelDeleteItem"
          >Cancel
        </g-button>
        <g-button
          type="danger"
          class="!text-light-100 border-none !px-4 hover:bg-[#F6695E]"
          size="small"
          @click.stop="deleteItem">
          Delete
        </g-button>
      </div>
    </div>

    <div
      v-if="isActive"
      class="bg-dark-500 flex items-center justify-between p-16"
      draggable="true"
      @dragstart="preventDrag">
      <div class="text-12 text-dark-low mb-4">Title</div>
      <g-input
        class="!bg-dark-400 text-dark-high max-w-input-horizontal h-36 w-full rounded-xl border-none leading-[18px]"
        size="small"
        :value="title"
        @on-change="onChange">
      </g-input>
    </div>
  </div>
</template>
