import { notify } from 'notiwind';
import useShopStore from '@/stores/shop';
import { sentryCaptureException } from '@/modules/editor/modules/common/use-cases/sentry';
import { getConfig } from '@/config';
import { clearLoginAs } from '@/api/helpers';

export default function useNotification() {
  const handleByStatus = (statusCode: number, code: number, message: any) => {
    if (statusCode == 500) {
      alertError({ code, message });
    } else if (statusCode == 401 || [401101, 400120].includes(code)) {
      // status 401 or instant no match pricing, cookie
      alertError(errorAuth({ code, message }));
    } else if (statusCode == 413) {
      alertError({ code, message: 'PayloadTooLargeError: request entity too large' });
    } else {
      alertError({ code, message });
    }
  };

  return {
    handleByStatus,
    handleError: (error: any) => {
      const errors = error?.response?.errors;
      const isUnAuthorized = errors?.some((error: any) => error?.extensions?.response?.status === 401);
      if (isUnAuthorized) {
        clearLoginAs();
      }
      const { code, statusCode, message } = errorParser(error);
      handleByStatus(statusCode, code, message);
    },
    handleSuccess: (msg: string) => {
      notify(
        {
          type: 'success',
          text: msg,
          group: 'bottom',
        },
        3000,
      );
    },
    handleAlert(type: 'error' | 'success' = 'error', msg: string, time = 3000) {
      notify(
        {
          type,
          text: msg,
        },
        time,
      );
    },
  };
}

const alertError = ({ code, message }: { code: number; message: string }) => {
  const msg = mapMessage({ code, message });
  notify(
    {
      type: 'error',
      text: msg,
    },
    3000,
  );
};

const mapMessage = ({ code, message }: { code: number; message: string }): string => {
  switch (code) {
    case 403003:
      return `${code}: Your plan is not advanced yet, please upgrade to use this feature!`;
    case 500100:
      return `${code}: Login GemPages to continue using this features.!`;
    case 400103:
      return `${code}: Shop has connected`;
  }
  return `${code}: ${message}`;
};

const errorParser = (
  error: any,
): {
  statusCode: number;
  code: number;
  message: string;
} => {
  if (error.response) {
    // Graphql type 1
    const errors = error?.response?.errors;
    if (errors?.length) {
      for (let index = 0; index < errors.length; index++) {
        const error = errors[index];
        const statusCode = error?.extensions?.response?.status;
        const graphqlCode = error?.extensions?.code;
        if (statusCode) {
          const statusText = error?.extensions?.response?.statusText;
          return {
            statusCode: statusCode || 200,
            code: 400000,
            message: statusText, // works, `e` narrowed to string
          };
        } else if (graphqlCode) {
          const message = error?.extensions?.exception?.message;
          return {
            statusCode: statusCode || 200,
            code: 400000,
            message: message, // works, `e` narrowed to string
          };
        } else if (!statusCode) {
          return {
            statusCode: statusCode || 200,
            code: 400000,
            message: error, // works, `e` narrowed to string
          };
        }
      }
    } else if (error?.response?.status) {
      const statusCode = error?.response?.status;
      if (statusCode) {
        const err = error?.response?.errors?.[0] || error?.response?.error;
        if (err) {
          return {
            statusCode: statusCode || 200,
            code: 400000,
            message: err, // works, `e` narrowed to string
          };
        } else {
          return {
            statusCode: statusCode || 200,
            code: 400000,
            message: err, // works, `e` narrowed to string
          };
        }
      } else if (!statusCode) {
        return {
          statusCode: 200,
          code: 400000,
          message: error, // works, `e` narrowed to string
        };
      }
    }
  } else if (typeof error === 'string') {
    return {
      statusCode: 200,
      code: 400000,
      message: error, // works, `e` narrowed to string
    };
  } else if (error instanceof Error) {
    return {
      statusCode: 400,
      code: 400000,
      message: error.message, // works, `e` narrowed to string
    };
  } else if (error.code) {
    return {
      statusCode: 200,
      code: error.code,
      message: error.message || '',
    };
  }

  sentryCaptureException(
    'handleError',
    'Unidentified error',
    {
      error,
    },
    {
      level: 'warning',
    },
  );
  return {
    statusCode: 200,
    code: 400000,
    message: JSON.stringify(error),
  };
};

const errorAuth = ({
  code,
  message,
}: {
  code: number;
  message: string;
}): {
  code: number;
  message: string;
} => {
  if (!code) {
    code = 401998;
  }
  const shopStore = useShopStore();
  if (shopStore.getConnectedType == 'storefront') {
    if (code == 401998) {
      const redirectUri = window.location.href;
      const apiUrl = new URL(getConfig('url').account);
      window.location.replace(`https://${apiUrl.hostname}/login?redirect_uri=${redirectUri}`);
    } else if (code == 401999) {
      const apiUrl = new URL(getConfig('url').account);
      window.location.replace(`https://${apiUrl.hostname}/shops`);
    }
  } else if (shopStore.getConnectedType == 'instant') {
    return {
      code,
      message,
    };
  }
  return {
    code,
    message,
  };
};
