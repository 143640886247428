<script setup lang="ts">
import { useFloating, offset, shift, autoUpdate } from '@floating-ui/vue';
import { ref, computed } from 'vue';
import { onClickOutside } from '@vueuse/core';

type DisplayOptionValue = 'always' | 'after-first-scroll' | 'after-first-cart-button' | 'meet-section';
const props = defineProps<{
  id: string;
  value?: any;
}>();

const emit = defineEmits<{
  (e: 'controlChange', controlId?: string, value?: string): void;
}>();

const DISPLAY_OPTIONS = [
  {
    label: 'Always',
    value: 'always' as DisplayOptionValue,
    icon: 'sticky-display-always',
  },
  // {
  //   label: 'After first scroll',
  //   value: 'after-first-scroll' as DisplayOptionValue,
  //   icon: 'sticky-display-first-scroll',
  // },
  {
    label: 'After first cart button',
    value: 'after-first-cart-button' as DisplayOptionValue,
    icon: 'sticky-display-first-cart',
  },
  // {
  //   label: 'When meet a section',
  //   value: 'meet-section' as DisplayOptionValue,
  //   icon: 'sticky-display-meet-section',
  // },
];

const isOpenSelectDisplay = ref<boolean>(false);
const floating = ref<HTMLElement | null>(null);
const reference = ref<HTMLElement | null>(null);
const optionItem = ref<HTMLElement | null>(null);
const { y, strategy } = useFloating(reference, floating, {
  placement: 'bottom',
  middleware: [offset(16), shift()],
  whileElementsMounted: autoUpdate,
});

const currentOption = computed(() => {
  return DISPLAY_OPTIONS.find((item) => item.value == props.value);
});
const onOpenModalSelectDisplay = () => {
  isOpenSelectDisplay.value = !isOpenSelectDisplay.value;
};

onClickOutside(floating, () => {
  if (!isOpenSelectDisplay.value) return;
  isOpenSelectDisplay.value = false;
});
const handleChangeDisplayOption = (value: DisplayOptionValue) => {
  emit('controlChange', props.id, value);
  isOpenSelectDisplay.value = false;
};

const btnChangeClass = computed(() => {
  let str = 'px-8 border-0';
  if (isOpenSelectDisplay.value) {
    str += ' text-[#7190FF] bg-[#3c67ff26] hover:bg-[#3c67ff26]';
  }
  return str;
});
</script>
<template>
  <div
    ref="optionItem"
    class="relative flex h-64 items-center justify-between overflow-hidden rounded-xl bg-[#212121] p-8">
    <div class="relative z-10 flex items-center">
      <div class="flex h-[48px] w-[64px] items-center">
        <g-base-icon
          width="96"
          height="72"
          viewBox="0 0 96 72"
          custom-class="min-w-[64px]"
          :name="currentOption?.icon"></g-base-icon>
      </div>
      <span class="text-12 ml-8 font-medium text-[#F9F9F9]"> {{ currentOption?.label }}</span>
    </div>
    <g-button
      ref="reference"
      :button-classes="btnChangeClass"
      type="secondary"
      size="small"
      @click="onOpenModalSelectDisplay"
      >Change</g-button
    >
  </div>
  <Teleport to="#root-modal">
    <Transition>
      <div
        v-if="isOpenSelectDisplay"
        ref="floating"
        :style="{
          position: strategy,
          top: y !== null ? `${y + 10}px` : '',
          left: '16px',
        }"
        class="bg-dark-400 rounded-12 z-[999] w-[280px]">
        <div class="p-16">
          <div
            v-for="(item, index) in DISPLAY_OPTIONS"
            :key="index"
            class="text-12 border-dark-400 hover:border-dark-300 hover:bg-dark-300 mb-12 flex h-[72px] cursor-pointer items-center overflow-hidden rounded-xl border pr-12 text-[#F9F9F9] last:mb-0"
            :class="{ '!border-primary-300 !bg-dark-400': item.value == value }"
            @click="handleChangeDisplayOption(item.value)">
            <div class="flex h-full items-center">
              <div class="bg-dark-250 flex h-full items-center overflow-hidden rounded-xl">
                <g-base-icon :name="item.icon" width="96px" viewBox="0 0 96 72" fill="none"></g-base-icon>
              </div>
              <span class="pl-16">{{ item.label }}</span>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>
