import type * as Types from '@/types/graphql';

export type IconSelectFragment = Pick<
  Types.Icon,
  'createdAt' | 'createdBy' | 'data' | 'id' | 'name' | 'updatedAt' | 'updatedBy'
>;

export const IconSelectFragmentDoc = `
    fragment iconSelect on Icon {
  createdAt
  createdBy
  data
  id
  name
  updatedAt
  updatedBy
}
    `;
