<script lang="ts" setup>
import { ref, computed } from 'vue';
import { GSyncProgress } from '@gem/uikit';
import { useInfiniteScroll } from '@vueuse/core';
import { replaceImageToSize } from '../../hook/useResizeImage';
import type { ProductOption } from '../../type/common';

export type List = {
  id: string;
  title?: string;
  cursor?: string;
  image?: string;
  options?: ProductOption[];
  status?: string;
};
// props & emit
type Props = {
  value?: {
    title?: string;
    image?: string;
    id?: string;
  };
  list?: List[];
  isLoading?: boolean;
  open?: boolean;
  syncPercent?: number;
  pageType?: string;
};

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'showMore'): void;
  (e: 'refresh'): void;
  (e: 'setValueSelected', id?: string): void;
  (e: 'onSearch', value?: string): void;
}>();
//================ const =================
const productInfinityScroll = ref<HTMLElement | null>(null);
const searchKeyword = ref('');
const productListNotFound = computed(() => props.list?.length == 0);
const isInstant = computed(() => props.pageType === 'STATIC');

//================ methods =================
useInfiniteScroll(
  productInfinityScroll,
  () => {
    // load more
    emit('showMore');
  },
  { distance: 100 },
);
const onSearch = () => {
  setTimeout(() => {
    emit('onSearch', searchKeyword.value);
  }, 200);
};
const setValueSelected = (id?: string) => {
  if (id) {
    emit('setValueSelected', id);
    close();
  }
};
const close = () => {
  clear();
  emit('close');
};
const clear = () => {
  searchKeyword.value = '';
  onSearch();
};
</script>
<template>
  <div>
    <Teleport to="body">
      <g-modal
        :hide-actions="true"
        :is-open="open"
        :show-btn-close="true"
        :is-draggable="true"
        modal-class="!w-[580px]"
        header-class="cursor-default h-[52px] border-b border-light-400 px-0 py-0 pl-16"
        container-class="absolute !left-[337px] [@media(max-height:465px)]:max-h-full [@media(max-height:465px)]:bottom-16"
        header-title-class="h-full"
        @close="close">
        <template #title>
          <div class="bg-light-100 flex h-full w-full items-center">
            <div>
              <p>Select a product</p>
            </div>
            <div class="flex h-full flex-1 justify-end">
              <button
                class="bg-light-100 !text-light-high rounded-medium group flex aspect-square h-full flex-none items-center justify-center outline-none"
                :disabled="isLoading"
                @click.stop="emit('refresh')">
                <div
                  class="bg-light-100 group-hover:bg-light-400 flex h-32 w-32 items-center justify-center rounded-xl">
                  <GBaseIcon
                    v-if="isLoading"
                    :class="{ 'animate-spin': isLoading }"
                    name="small-loading"
                    width="16"
                    height="16"></GBaseIcon>
                  <GBaseIcon v-else name="refresh" width="16" height="16" view-box="0 0 16 16"></GBaseIcon>
                </div>
              </button>
            </div></div
        ></template>
        <div>
          <div class="flex items-center bg-[#ffffff]">
            <label for="simple-search" class="sr-only">Search</label>
            <div class="relative w-full">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center px-16">
                <svg
                  :class="{
                    'text-[#212121]': searchKeyword.length > 0,
                  }"
                  class="h-16 w-16 text-gray-500 dark:text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"></path>
                </svg>
              </div>
              <input
                v-model="searchKeyword"
                type="text"
                class="text-12 block h-[44px] w-full border border-gray-300 bg-[#ffffff] p-8 pl-[48px] text-[#212121] outline-none focus:border focus:border-y-[#3C67FF] dark:border-gray-600 dark:bg-gray-700 dark:text-white"
                placeholder="Search products"
                @keyup="onSearch" />
              <div
                v-if="searchKeyword.length > 0"
                class="absolute inset-y-0 right-24 flex items-center px-16"
                @click="clear()">
                <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center px-16">
                  <svg
                    class="h-16 w-16 text-gray-500 dark:text-gray-400"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 11L21 21M21 11L11 21" stroke="#676767" stroke-width="2" stroke-linecap="round" />
                    <circle cx="16" cy="16" r="13" stroke="#676767" stroke-width="2" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="flex flex-col px-16 pb-16 pt-8">
              <g-sync-progress :percent-sync-done="syncPercent" />
              <div
                v-if="!productListNotFound && isInstant"
                class="text-14 text-light-low sticky top-0 flex py-8 font-medium">
                <p>Instant Page only show “Active” products.</p>
              </div>
              <perfect-scrollbar ref="productInfinityScroll" class="!h-[53vh]">
                <div class="bg-light-100 relative mt-8 flex flex-1 flex-col">
                  <div class="flex-1">
                    <div
                      v-if="!productListNotFound"
                      class="hover:bg-light-400 flex cursor-pointer items-center border-x border-[#e0e5ee] p-8"
                      :class="{ 'border-t': list?.length !== 0, 'border-y': list?.length === 0 }"
                      @click="setValueSelected('latest')"
                      @keypress="setValueSelected('latest')">
                      <img class="mr-20 h-40 w-40" src="../images/latest.png" alt="product latest" />
                      <div class="text-14 font-regular text-[#212121]">Automatic (Latest product)</div>
                    </div>
                    <div
                      v-for="(item, index) in list"
                      :key="index"
                      class="hover:bg-light-400 flex cursor-pointer items-center border-x border-t border-[#e0e5ee] p-8"
                      :class="{
                        'border-b': (list?.length ?? 0) - 1 === index,
                        '!cursor-default !bg-slate-300': item.id == value?.id,
                      }"
                      @click="setValueSelected(item.id)"
                      @keypress="setValueSelected(item.id)">
                      <img
                        v-if="item.image"
                        class="mr-20 h-40 w-40"
                        :src="replaceImageToSize(item.image, '100x100') ?? item.image"
                        alt="collections" />
                      <img v-else class="mr-20 h-40 w-40" src="../images/defaultProduct.png" alt="product" />
                      <div class="text-14 font-regular w-full truncate text-[#212121]">{{ item?.title }}</div>
                      <div
                        v-if="item.status?.toLowerCase() !== 'active'"
                        class="rounded-medium text-12 font-regular ml-auto bg-[#aaa] p-[2px] capitalize text-[#212121]">
                        {{ item.status?.toLowerCase() }}
                      </div>
                    </div>
                    <div
                      v-if="isLoading"
                      class="flex items-center border-x border-b border-[#e0e5ee]"
                      :class="{ 'border-y': list?.length !== 0, 'border-b': list?.length === 0 }">
                      <GLoadingPlaceholder width="100%" height="58px"></GLoadingPlaceholder>
                    </div>
                  </div>
                </div>
                <div v-if="productListNotFound" class="flex h-[calc(100%_-_10px)] flex-col items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M20.4826 61.894C29.0334 61.894 35.9652 54.9622 35.9652 46.4113C35.9652 37.8605 29.0334 30.9287 20.4826 30.9287C11.9318 30.9287 5 37.8605 5 46.4113C5 54.9622 11.9318 61.894 20.4826 61.894ZM20.4826 64.894C30.6903 64.894 38.9652 56.619 38.9652 46.4113C38.9652 36.2037 30.6903 27.9287 20.4826 27.9287C10.275 27.9287 2 36.2037 2 46.4113C2 56.619 10.275 64.894 20.4826 64.894Z"
                      fill="#9E9E9E" />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M43.5869 71.6359L30.8672 58.9162L32.9885 56.7949L45.7082 69.5146L43.5869 71.6359Z"
                      fill="#9E9E9E" />
                    <path
                      d="M37.8904 20C37.91 23.3133 35.2402 26.0159 31.9258 26.0355C35.2402 26.0159 37.942 28.6865 37.9615 32C37.942 28.6865 40.6126 25.9841 43.9258 25.9644C40.6126 25.9841 37.91 23.3133 37.8904 20Z"
                      fill="#9E9E9E" />
                    <path
                      d="M10.4794 66C10.4908 67.9328 8.9334 69.5093 7 69.5207C8.9334 69.5093 10.5095 71.0671 10.5208 73C10.5095 71.0671 12.0673 69.4907 14 69.4792C12.0673 69.4907 10.4908 67.9328 10.4794 66Z"
                      fill="#9E9E9E" />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M18 53H75V11L18 11L18 53ZM18 8C16.3431 8 15 9.34315 15 11V53C15 54.6569 16.3431 56 18 56H75C76.6569 56 78 54.6569 78 53V11C78 9.34315 76.6569 8 75 8H18Z"
                      fill="#9E9E9E" />
                    <path
                      d="M60.2374 17C62.4286 17 64.1992 17.6197 65.5493 18.8592C66.8994 20.0986 67.5745 21.7586 67.5745 23.8391C67.5745 26.163 66.8552 27.8894 65.4165 29.0181C63.9779 30.1248 62.0302 30.6781 59.5735 30.6781L59.4739 33.832H56.8511L56.7183 28.5866H57.6811C59.8723 28.5866 61.5876 28.2546 62.827 27.5906C64.0664 26.9266 64.6862 25.6761 64.6862 23.8391C64.6862 22.5111 64.2878 21.4598 63.491 20.6851C62.6942 19.9105 61.6208 19.5231 60.2706 19.5231C58.8984 19.5231 57.8139 19.8994 57.0171 20.6519C56.2425 21.3823 55.8551 22.3894 55.8551 23.6731H53C53 22.3451 53.2988 21.1831 53.8964 20.1871C54.494 19.169 55.335 18.3833 56.4195 17.83C57.5262 17.2767 58.7988 17 60.2374 17ZM58.1459 40.6379C57.5704 40.6379 57.0835 40.4387 56.6851 40.0403C56.2867 39.6419 56.0875 39.155 56.0875 38.5795C56.0875 38.0041 56.2867 37.5172 56.6851 37.1188C57.0835 36.7204 57.5704 36.5212 58.1459 36.5212C58.6992 36.5212 59.164 36.7204 59.5403 37.1188C59.9387 37.5172 60.1378 38.0041 60.1378 38.5795C60.1378 39.155 59.9387 39.6419 59.5403 40.0403C59.164 40.4387 58.6992 40.6379 58.1459 40.6379Z"
                      fill="#9E9E9E" />
                  </svg>
                  <p class="text-14 font-regular text-[#676767]">No products match your search.</p>
                  <p class="text-14 font-regular text-[#676767]">Please try another keyword</p>
                </div>
              </perfect-scrollbar>
            </div>
          </div>
        </div>
      </g-modal>
    </Teleport>
  </div>
</template>
