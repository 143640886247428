<script lang="ts" setup>
import type { StyleValue } from 'vue';
import { computed, onMounted, ref, watch } from 'vue';
import { lightenDarkenColor } from '../../utils/lightenDarkenColor';
import { Color } from './Color';
import type { WhereInput } from './types';

const props = withDefaults(
  defineProps<{
    value?: string;
    isAutoUpdateValue?: boolean;
    hexClass?: string;
    alphaClass?: string;
    colorSvgEyeDropper?: string;
    bgHoverSvgEyeDropper?: string;
    variant?: boolean;
  }>(),
  {
    hexClass: 'text-white',
    alphaClass: 'text-white',
    colorSvgEyeDropper: '#E2E2E2',
    bgHoverSvgEyeDropper: 'hover:bg-[#5b5b5b]',
    variant: false,
  },
);

const emit = defineEmits<{
  (e: 'change-color', input: string, whereInput?: WhereInput): void;
}>();

const color = ref(Color.fromString(props.value));
const hueArea = ref<HTMLDivElement>();
const huePicker = ref<HTMLDivElement>();
const surface = ref<HTMLDivElement>();
const picker = ref<HTMLDivElement>();
const alphaArea = ref<HTMLDivElement>();
const alphaPicker = ref<HTMLDivElement>();
const iframeDocument = ref<HTMLIFrameElement>();
let debounceChangeValue: ReturnType<typeof setTimeout>;

const limitDim = (pos: number, dimension: number) => {
  return Math.max(-4, Math.min(pos - 4, dimension - 12));
};

const alphaPickerStyle = computed<StyleValue>(() => {
  const rect = alphaArea.value?.getBoundingClientRect();
  if (rect) {
    const left = Math.round(rect.width * color.value.a);
    return {
      left: `${limitDim(left, rect.width)}px`,
      background: `hsl(${color.value.hue}, 100%, 50%)`,
    };
  }
  return {};
});

const pickerStyle = computed<StyleValue>(() => {
  const rect = surface.value?.getBoundingClientRect();
  let value = color.value.value;
  if (color.value.format === 'HSV') value = color.value.value;
  if (color.value.format === 'HSL') value = color.value.lightness;
  if (rect) {
    const width = rect.width;
    const height = rect.height;
    const x = ((color.value.saturation * width) / 100) | 0;
    const y = (height - (value * height) / 100) | 0;
    return {
      transform: `translate(${limitDim(x, width)}px, ${limitDim(y, height)}px)`,
      background: `rgb(${color.value.r}, ${color.value.g}, ${color.value.b})`,
    };
  }
  return {};
});

const hueStyle = computed<StyleValue>(() => {
  const rect = hueArea.value?.getBoundingClientRect();
  const left = ((color.value.hue * (rect?.width ?? 0)) / 360) | 0;
  return {
    left: `${limitDim(left, rect?.width ?? 248)}px`,
    background: `hsl(${color.value.hue}, 100%, 50%)`,
  };
});

const alphaStyle = computed<StyleValue>(() => {
  return {
    background: `linear-gradient(to left, ${color.value.formatRGBA(1)} 0%, ${color.value.formatRGBA(0)} 100%)`,
  };
});

const surfaceStyle = computed<StyleValue>(() => {
  const nc = color.value.clone();
  nc.setHSV(nc.hue, 100, 100);
  return { backgroundColor: nc.getHexa() };
});

const noHashtagHexColor = computed(() => color.value?.getHexa()?.replace('#', ''));

function changeColor(whereInput?: WhereInput) {
  clearTimeout(debounceChangeValue);
  debounceChangeValue = setTimeout(
    () => {
      emit('change-color', color.value.getColor(), whereInput);
    },
    whereInput === 'hexInput' ? 200 : whereInput === 'opacityInput' ? 500 : 20,
  );
}

function onChangeHexValue(e: Event) {
  clearTimeout(debounceChangeValue);
  const value = (e.target as HTMLInputElement).value;
  const noHashtagHexRegexp = /^[0-9A-F]{6}$/i;
  if (noHashtagHexRegexp.test(value)) {
    color.value = Color.fromString('#' + value);
    changeColor('hexInput');
    return;
  }
  const rgbaRegexp = /^rgb(a?)[(]\s*([\d.]+\s*%?)\s*,\s*([\d.]+\s*%?)\s*,\s*([\d.]+\s*%?)\s*(?:,\s*([\d.]+)\s*)?[)]$/;
  const hexRegexp = /^#[0-9A-F]{6}$/i;
  const hexWithoutPrefixRegexp = /^[0-9A-F]{6}$/i;
  if (hexRegexp.test(value) || rgbaRegexp.test(value) || hexWithoutPrefixRegexp.test(value)) {
    color.value = Color.fromString(value);
    changeColor('hexInput');
  }
}
function onChangeAlphaValue(e: Event) {
  clearTimeout(debounceChangeValue);
  const inputVal = Number((e.target as HTMLInputElement).value);
  const value = Number((Math.max(0, Math.min(100, inputVal)) / 100).toFixed(2));
  if (value >= 0 && value <= 1) {
    color.value.setAlpha(value);
    changeColor('opacityInput');
  }
}

function updateColor(e: MouseEvent) {
  if (surface.value && picker.value) {
    const rect = surface.value.getBoundingClientRect();
    let x = e.pageX - rect.x;
    let y = e.pageY - rect.y;

    const width = rect.width;
    const height = rect.height;

    if (x > width) x = width;
    if (y > height) y = height;
    if (x < 0) x = 0;
    if (y < 0) y = 0;

    const value = (100 - (y * 100) / height) | 0;
    const saturation = ((x * 100) / width) | 0;

    if (color.value.format === 'HSV') color.value.setHSV(color.value.hue, saturation, value);
    if (color.value.format === 'HSL') color.value.setHSL(color.value.hue, saturation, value);

    changeColor();
  }
}

function updateHueSlider(e: MouseEvent) {
  if (hueArea.value && huePicker.value) {
    const rec = hueArea.value.getBoundingClientRect();
    let x = e.pageX - rec.x;
    const width = rec.width;

    if (x < 0) x = 0;
    if (x > width) x = width;

    // TODO 360 => 359
    let hue = Math.floor((359 * x) / width);

    if (hue === 360) hue = 359;
    color.value.setHue(hue);

    changeColor();
  }
}

function updateAlphaSlider(e: MouseEvent) {
  if (alphaArea.value && alphaPicker.value) {
    const alphaRect = alphaArea.value.getBoundingClientRect();
    let x = e.pageX - alphaRect.x;
    const width = alphaRect.width;

    if (x < 0) x = 0;
    if (x > width) x = width;
    color.value.a = parseFloat((x / width).toFixed(2));

    changeColor();
  }
}

function setMouseTracking(elem: HTMLElement, callback: (e: MouseEvent) => void) {
  document.addEventListener('mouseup', function () {
    document.removeEventListener('mousemove', callback);
  });
  iframeDocument.value?.addEventListener('mouseup', function () {
    document.removeEventListener('mousemove', callback);
  });
  elem.addEventListener('mousedown', function (e) {
    e.preventDefault();
    callback(e);
    document.addEventListener('mousemove', callback);
  });
}

function getIframeDocument() {
  const iframe = document.querySelector('iframe');
  if (!iframe) {
    return;
  }
  iframeDocument.value = iframe.contentDocument || (iframe.contentWindow as any).document;
}

const stopPropagation = (e: Event) => {
  e.stopPropagation();
};

const disableShortcutDelete = (e: KeyboardEvent) => {
  if (e.key === 'Delete') {
    e.stopPropagation();
  }
};

declare global {
  interface Window {
    EyeDropper: any;
  }
}
interface EyeDropperConstructor {
  new (): EyeDropperInterface;
}

interface ColorSelectionOptions {
  signal: AbortSignal;
}

interface ColorSelectionResult {
  sRGBHex: string;
}

interface EyeDropperInterface extends EyeDropperConstructor {
  open: (options?: ColorSelectionOptions) => Promise<ColorSelectionResult>;
}

declare let EyeDropper: {
  prototype: EyeDropperInterface;
  new (): EyeDropperInterface;
};

const isCanUseEyeDropper = computed(() => {
  return window?.EyeDropper;
});

const openEyeDropper = () => {
  clearTimeout(debounceChangeValue);
  const eyeDropper = new EyeDropper();
  eyeDropper
    .open()
    .then((result: any) => {
      color.value = Color.fromString(result.sRGBHex);
      changeColor('hexInput');
    })
    .catch((e: any) => {
      console.error(e);
    });
};

onMounted(() => {
  getIframeDocument();
  if (surface.value) {
    setMouseTracking(surface.value, updateColor);
  }

  if (hueArea.value) {
    setMouseTracking(hueArea.value, updateHueSlider);
  }
  if (alphaArea.value) {
    setMouseTracking(alphaArea.value, updateAlphaSlider);
  }
});

watch(
  () => props.value,
  (newV) => {
    if (!props.isAutoUpdateValue) {
      return;
    }
    setTimeout(() => {
      color.value = Color.fromString(newV);
    }, 0);
  },
);

const borderColor = computed(() => lightenDarkenColor(props.value as string));
</script>
<template>
  <div class="flex flex-col gap-12">
    <div class="flex gap-8">
      <div v-if="variant" class="bg-light-300 rounded-medium flex h-[36px] w-full items-center justify-start p-8">
        <div v-if="value && value !== 'transparent'" class="h-17 w-17 relative rounded-full">
          <div
            class="gemx-transparent-bg absolute top-1/2 left-1/2 h-[17px] w-[17px] -translate-y-1/2 transform rounded-full border"
            :style="{
              'border-color': borderColor,
            }" />
          <div
            :style="{
              'background-color': value,
              'border-color': borderColor,
            }"
            class="absolute top-1/2 left-1/2 h-[17px] w-[17px] -translate-y-1/2 transform rounded-full border"></div>
        </div>
        <input
          :value="noHashtagHexColor"
          class="gemx-hex-input text-12 bg-light-300 h-full w-full pl-[28px] outline-none"
          :class="hexClass"
          @input="onChangeHexValue"
          @mousedown="stopPropagation"
          @keydown="disableShortcutDelete" />
      </div>
      <!-- <div
        v-if="isCanUseEyeDropper"
        class="eye-dropper rounded-medium bg-light-300 flex w-[36px] shrink-0 cursor-pointer items-center justify-center"
        :class="bgHoverSvgEyeDropper"
        @click="openEyeDropper">
        <g-base-icon
          name="eye-dropper"
          width="14"
          height="14"
          viewBox="6 6 12 12"
          :fill="colorSvgEyeDropper"></g-base-icon>
      </div> -->
    </div>
    <div class="flex flex-col gap-16">
      <div class="h-[100px]">
        <div ref="surface" :style="surfaceStyle" class="rounded-medium relative h-full cursor-crosshair">
          <div class="gemx-picking-bg-1 rounded-medium absolute inset-0"></div>
          <div class="gemx-picking-bg-2 rounded-medium absolute inset-0"></div>
          <div
            ref="picker"
            class="gemx-pick-surface z-10 aspect-square w-16 cursor-move rounded-full border border-white will-change-transform"
            :style="pickerStyle"></div>
        </div>
      </div>
      <div class="flex items-center">
        <div
          v-if="isCanUseEyeDropper && !variant"
          class="eye-dropper rounded-medium mr-8 cursor-pointer"
          :class="bgHoverSvgEyeDropper"
          @click="openEyeDropper">
          <g-base-icon
            name="eye-dropper"
            width="14"
            height="14"
            viewBox="6 6 12 12"
            :fill="colorSvgEyeDropper"></g-base-icon>
        </div>
        <div class="flex w-full flex-col gap-16">
          <div ref="hueArea" aria-label="Hue" class="gemx-hue relative h-8 rounded-full">
            <div
              ref="huePicker"
              :style="hueStyle"
              class="absolute -inset-y-4 aspect-square w-16 cursor-col-resize rounded-full border border-white"></div>
          </div>
          <div v-if="!variant" ref="alphaArea" class="gemx-alpha-bg h-8 rounded-full">
            <div class="relative h-full rounded-full" :style="alphaStyle">
              <div
                ref="alphaPicker"
                :style="alphaPickerStyle"
                class="absolute -inset-y-4 aspect-square w-16 cursor-col-resize rounded-full border border-white"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!variant" class="rounded-medium flex h-32 flex-nowrap items-center border border-transparent">
      <div class="flex h-full flex-1 items-center gap-8 p-8">
        <div class="rounded-large gemx-transparent-bg border-dark-200 relative h-16 w-16">
          <div
            class="rounded-medium absolute top-0 left-0 h-full w-full border"
            :style="{ background: color.getColor(), borderColor: lightenDarkenColor(color.getColor()) }"></div>
        </div>
        <div class="flex h-14">
          <input
            :value="noHashtagHexColor"
            class="gemx-hex-input text-12 bg-dark-400 h-full w-[129px] outline-none"
            :class="hexClass"
            @input="onChangeHexValue"
            @mousedown="stopPropagation"
            @keydown="disableShortcutDelete" />
        </div>
      </div>
      <div class="relative h-full">
        <input
          :value="Math.round(color.a * 100)"
          class="gemx-opacity-input text-12 bg-dark-400 h-full w-[48px] border-l border-transparent pl-8 pr-16 text-center outline-none"
          :class="alphaClass"
          type="number"
          min="0"
          max="100"
          @input="onChangeAlphaValue"
          @keydown="disableShortcutDelete" />
        <span class="text-12 absolute top-1/2 right-8 -translate-y-1/2" :class="alphaClass">%</span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.gemx-picking-bg-1 {
  background: linear-gradient(to right, #fff 0%, rgba(255, 255, 255, 0) 100%);
}

.gemx-picking-bg-2 {
  background: linear-gradient(to bottom, transparent 0%, #000 100%);
}

.gemx-hue {
  background-image: linear-gradient(to right, red 0%, #ff0 17%, lime 33%, cyan 50%, blue 66%, #f0f 83%, red 100%);
}

.gemx-alpha-bg {
  background-image: url('../../assets/opacity.png');
  background-size: cover;
}

.gemx-transparent-bg {
  background-image: url('../../assets/transparent-color.svg');
  background-size: cover;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  appearance: none;
  -moz-appearance: textfield;
}
</style>
