import type { IconsQueryVariables, IconsQueryResponse } from '@/api/app/queries/icons.generated';
import { IconsDocument } from '@/api/app/queries/icons.generated';
import { appFetcher } from '@/api/gatewayClient';
import type { ComputedRef, Ref } from 'vue';
import { isRef } from 'vue';
import type { UseInfiniteQueryOptions } from 'vue-query';
import { useInfiniteQuery } from 'vue-query';

export const useInfinityIcons = <TData = IconsQueryResponse, TError = unknown>(
  keys?: any,
  variables?: IconsQueryVariables | ComputedRef<IconsQueryVariables> | Ref<IconsQueryVariables>,
  options?: Omit<UseInfiniteQueryOptions<IconsQueryResponse, TError, TData>, 'queryKey' | 'queryFn'>,
) => {
  return useInfiniteQuery(
    keys === undefined ? ['icons-infinity'] : ['icons-infinity', keys],
    (args) => {
      if (isRef(variables)) {
        variables.value.after = args.pageParam;
      } else {
        if (variables) {
          variables.after = args.pageParam;
        } else {
          variables = {
            after: args.pageParam,
          } as any;
        }
      }
      return appFetcher<IconsQueryResponse, IconsQueryVariables>(IconsDocument, variables)();
    },
    options,
  );
};
