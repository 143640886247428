<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import type { File as ImageFile, Maybe } from '../../type/graphql';
import type { GlobalSwatchesData, SwatchesOptionType, SwatchesOptionValue } from './types';
import SwatchesPreviewImage from './components/SwatchesPreviewImage.vue';
import SwatchesItemImage from './components/SwatchesItemImage.vue';

const emit = defineEmits<{
  (e: 'changeData', value: SwatchesOptionValue[], index: string): void;
  (e: 'uploadImage', formData: FormData, index?: string, toVariant?: string): void;
  (e: 'typeChange', index: number, type: SwatchesOptionType): void;
}>();

const props = withDefaults(
  defineProps<{
    data?: GlobalSwatchesData[];
    index: number;
    images?: ImageFile[];
    maximumSize?: number;
    allowedFiles?: string[];
    shopId: string;
    optionTitle?: string;
    saveClicked: boolean;
    isDataDiff: boolean;
    showOther: boolean;
    isImageNotSet: boolean;
  }>(),
  {
    allowedFiles: () => [
      'image/avif',
      'image/jpeg',
      'image/tiff',
      'image/bmp',
      'image/gif',
      'image/png',
      'image/webp',
      'image/svg+xml',
    ],
    maximumSize: 2 * 1024 * 1024, // 2MB
    data: () => [],
    showOther: false,
    isImageNotSet: false,
  },
);

const dataClone = ref<GlobalSwatchesData[]>(props.data);
const scrollSection = ref();

const handleChangeFile = (event: Event, index: string) => {
  const target = event.target as HTMLInputElement;
  if (!target.files?.length) return;
  const file = target.files.item(0);
  if (!file) return;

  if (!props.allowedFiles.includes(file.type) || file.size > props.maximumSize) {
    return;
  }

  postUploadImage(file, index);
};

const postUploadImage = async (_urlImage: File, index: string) => {
  const formData = new FormData();
  formData.append('file', _urlImage);
  formData.append('shopID', props.shopId);
  formData.append('appName', 'gemx');

  emit('uploadImage', formData, index, props.index.toString());
};

watch(
  () => props.data,
  () => {
    dataClone.value = props.data;
  },
);

watch(
  () => props.isImageNotSet,
  () => {
    if (!scrollSection.value?.$el) {
      return;
    }
    scrollSection.value.$el.scrollTop = 0;
  },
);
</script>

<template>
  <div class="flex border-y">
    <perfect-scrollbar ref="scrollSection" class="flex !h-[554px] shrink-0">
      <div class="w-[360px] p-24">
        <div class="">
          <div class="text-14 mb-8 font-medium">Display style</div>
          <div class="rounded-medium flex h-[48px] w-full items-center border p-[12px]">
            <g-base-icon
              name="variant-style-image-title-light"
              width="70px"
              viewBox="0 0 70 24"
              fill="none"></g-base-icon>
            <span class="text-14 text-light-high pl-12">Upload image</span>
          </div>
        </div>
        <div class="mt-24">
          <span class="text-light-low text-12 font-medium">Value of this product</span>
          <div v-if="isImageNotSet" class="text-small rounded-medium mt-8 flex bg-red-100 p-8">
            <g-base-icon
              class="mr-8 shrink-0"
              name="error"
              width="16"
              height="16"
              view-box="0 0 16 16"
              custom-class="text-red-300"></g-base-icon>
            <span class="text-light-high">
              There were blank options. You should upload image to display this product properly</span
            >
          </div>
          <div v-if="dataClone[index]">
            <template v-for="(option, i) in dataClone[index].optionValues" :key="i">
              <SwatchesItemImage
                v-if="!option.other"
                :option="option"
                :show-error="isImageNotSet"
                :index="i"
                @handle-change-file="handleChangeFile">
              </SwatchesItemImage>
            </template>
          </div>
        </div>
        <div v-if="showOther" class="mt-16">
          <span class="text-light-low text-12 font-medium">Other value</span>
          <div class="pb-24">
            <template v-for="(option, i) in dataClone[index].optionValues" :key="i">
              <SwatchesItemImage v-if="option.other" :option="option" :index="i" @handle-change-file="handleChangeFile">
              </SwatchesItemImage>
            </template>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
    <SwatchesPreviewImage />
  </div>
</template>
