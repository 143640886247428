<script lang="ts" setup>
// import BorderColorSetting from './BorderColorSetting.vue';
import Segment from '../Segment.vue';
import type { ColorProps } from '../global-color/types';
import type { BorderType, BorderStyle, BorderPosition } from './types';

const emit = defineEmits<{
  (e: 'controlChange', value: BorderType): void;
  (e: 'controlOnChange', value: BorderType): void;
}>();

type Props = {
  id: string;
  value?: BorderType;
  globalColors?: ColorProps[];
  colorMapping?: Record<string, string>;
};

const props = defineProps<Props>();

const styleOptions = [
  {
    label: 'Solid',
    value: 'solid',
    type: 'border',
  },
  {
    label: 'Dotted',
    value: 'dotted',
    type: 'border',
  },
  {
    label: 'Dashed',
    value: 'dashed',
    type: 'border',
  },
];

const positionOptions = [
  {
    label: 'All',
    value: 'all',
    icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect/>
      <rect x="17.375" y="2.625" width="14.75" height="14.75" transform="rotate(90 17.375 2.625)" stroke="currentColor" stroke-width="1.25"/>
      </svg>`,
  },
  {
    label: 'Top',
    value: 'top',
    icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="17.375" y="2.625" width="14.75" height="14.75" transform="rotate(90 17.375 2.625)" stroke="#494949" stroke-width="1.25"/>
      <rect x="2" y="2" width="16" height="1.25" fill="currentColor"/>
      </svg>`,
  },
  {
    label: 'Bottom',
    value: 'bottom',
    icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="17.375" y="2.625" width="14.75" height="14.75" transform="rotate(90 17.375 2.625)" stroke="#494949" stroke-width="1.25"/>
      <rect x="2" y="16.75" width="16" height="1.25" fill="currentColor"/>
      </svg>`,
  },
  {
    label: 'Left',
    value: 'left',
    icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="17.375" y="2.625" width="14.75" height="14.75" transform="rotate(90 17.375 2.625)" stroke="#494949" stroke-width="1.25"/>
      <rect x="2" y="18" width="16" height="1.25" transform="rotate(-90 2 18)" fill="currentColor"/>
      </svg>`,
  },
  {
    label: 'Right',
    value: 'right',
    icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="17.375" y="2.625" width="14.75" height="14.75" transform="rotate(90 17.375 2.625)" stroke="#494949" stroke-width="1.25"/>
      <rect x="16.75" y="18" width="16" height="1.25" transform="rotate(-90 16.75 18)" fill="currentColor"/>
      </svg>
`,
  },
];

const changeValue = (id?: string, value?: string) => {
  if (id && value) setVal(id as 'style' | 'position', value, 'controlChange');
};

const onChangeValue = (id?: string, value?: string) => {
  if (id && value) setVal(id as 'style' | 'position', value, 'controlOnChange');
};

const setVal = (id: 'style' | 'position', value: string, type: 'controlOnChange' | 'controlChange') => {
  if (id === 'position') {
    const data: BorderType = {
      ...props.value,
      position: value as BorderPosition,
    };
    change(data, type);
  } else {
    const data: BorderType = {
      ...props.value,
      border: value as BorderStyle,
    };
    change(data, type);
  }
};

const change = (data: BorderType, type: 'controlOnChange' | 'controlChange') => {
  if (type === 'controlChange') emit('controlChange', data);
  else emit('controlOnChange', data);
};
</script>

<template>
  <g-popover
    :closeable="true"
    class="aspect-square h-full"
    :overlay="false"
    noShadowBox
    arrow-class="!text-dark-400 translate-x-[-42px]"
    overlay-container="#root-modal"
    placement="bottom-end"
    wrapper-class="translate-x-[42px] "
    cls="!p-0 ">
    <template #default="{ open }">
      <g-button :active="open" type="moreSetting" size="medium" :only-icon="true" class="h-full">
        <g-base-icon name="more-setting-20" width="20" height="20" view-box="0 0 20 20"></g-base-icon>
      </g-button>
    </template>
    <template #content="{ close }">
      <div class="bg-dark-400 rounded-12">
        <div class="text-dark-high text-14 flex h-[56px] items-center justify-between px-16">
          <span>Border</span>
          <g-button type="ghost" size="small" :only-icon="true" @click="close">
            <g-base-icon class="cursor-pointer" name="close-more-setting" width="16" height="16" view-box="0 0 16 16" />
          </g-button>
        </div>
        <div class="rounded-12 w-screen max-w-[264px] p-16 pt-0">
          <!-- Border Style -->
          <div class="mb-16 flex items-center justify-between">
            <span class="text-12 text-dark-low mb-8 block">Style</span>
            <Segment
              :id="'style'"
              class="!w-[164px]"
              background-class="bg-dark-300"
              :value="props.value?.border === 'none' || !props.value?.border ? 'solid' : props.value?.border"
              :options="styleOptions"
              @control-change="changeValue"
              @control-on-change="onChangeValue" />
          </div>
          <!-- Border Position -->
          <div class="flex items-center justify-between">
            <span class="text-12 text-dark-low mb-8 block">Position</span>
            <Segment
              :id="'position'"
              class="!w-[164px]"
              background-class="bg-dark-300"
              :value="props.value?.position || 'all'"
              :options="positionOptions"
              @control-change="changeValue"
              @control-on-change="onChangeValue" />
          </div>
          <!-- Border Color -->
          <!-- <BorderColorSetting
            :value="value"
            :global-colors="globalColors"
            :color-mapping="colorMapping"
            @control-change="change" /> -->
        </div>
      </div>
    </template>
  </g-popover>
</template>
