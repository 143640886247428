import type * as Types from '@/types/graphql';

export type VariantSelectFragment = Pick<
  Types.ProductVariant,
  | 'barcode'
  | 'baseID'
  | 'costPrice'
  | 'description'
  | 'height'
  | 'id'
  | 'inventoryPolicy'
  | 'inventoryQuantity'
  | 'inventoryStatus'
  | 'isDigital'
  | 'length'
  | 'lowInventoryAmount'
  | 'manageInventory'
  | 'mediaId'
  | 'platform'
  | 'price'
  | 'salePrice'
  | 'sku'
  | 'soldIndividually'
  | 'title'
  | 'weight'
  | 'width'
> & { selectedOptions: Array<Pick<Types.SelectedOption, 'name' | 'optionType' | 'value'>> };

export const VariantSelectFragmentDoc = `
    fragment VariantSelect on ProductVariant {
  barcode
  baseID
  costPrice
  description
  height
  id
  inventoryPolicy
  inventoryQuantity
  inventoryStatus
  isDigital
  length
  lowInventoryAmount
  manageInventory
  mediaId
  platform
  price
  salePrice
  selectedOptions {
    name
    optionType
    value
  }
  sku
  soldIndividually
  title
  weight
  width
}
    `;
