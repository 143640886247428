<script lang="ts" setup>
import { useInfiniteScroll } from '@vueuse/core';
import { ref, watch } from 'vue';
type PickLinkList = {
  handle?: string;
  title?: string;
  name?: string;
  id?: string;
  isThemeSection?: boolean;
  index?: number;
};
type Props = {
  list?: PickLinkList[];
  loading?: boolean;
  type: 'pages' | 'collections' | 'products' | 'articles' | 'scrollToList' | 'emails' | 'phone';
  shopDomain?: string;
  customUrl?: string;
  link?: string;
  isInstant?: boolean;
};
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'showMore'): void;
  (e: 'setCustomUrl', value: string): void;
  (e: 'setCustomTitle', value: string): void;
  (e: 'onSearch', value: string): void;
  (e: 'onHandleDisable', value: boolean): void;
}>();

const SHOPIFY_PAGE_DATA: {
  [key: string]: { title: string; uri: string; pathName: string };
} = {
  collections: {
    title: 'Collection',
    uri: `https://${props.shopDomain}/collections`,
    pathName: '/collections',
  },
  products: {
    title: 'Product',
    uri: `https://${props.shopDomain}/products`,
    pathName: '/products',
  },
  articles: {
    title: 'Articles',
    uri: `https://${props.shopDomain}/blogs`,
    pathName: '/blogs',
  },
  pages: {
    title: 'Page',
    uri: `https://${props.shopDomain}/pages`,
    pathName: '/pages',
  },
};

const INSTANT_PAGE_DATA: {
  [key: string]: { title: string; uri: string; pathName: string };
} = {
  pages: {
    title: 'Page',
    uri: `https://${props.shopDomain}/`,
    pathName: '/',
  },
};

const getPhoneDefaultUrl = () => {
  if (props.customUrl?.includes('tel:')) {
    const phone = props.customUrl?.replace('tel:', '');

    return phone;
  }
  return '';
};

const getMailDefaultUrl = () => {
  if (props.customUrl?.includes('mailto:')) {
    const mails = props.customUrl?.replace('mailto:', '');
    const splitMails = mails?.split('?');
    return {
      email: splitMails?.[0],
      subject: splitMails?.[1]?.split('&')[0]?.replace('subject=', '') || '',
      message: splitMails?.[1]?.split('&')[1]?.replace('body=', '') || '',
    };
  }
  return { email: '', subject: '', message: '' };
};

const getDefaultSelectedItem = () => {
  if (
    props.type === 'articles' ||
    props.type === 'pages' ||
    props.type === 'collections' ||
    props.type === 'products'
  ) {
    const pageData = props.isInstant ? INSTANT_PAGE_DATA : SHOPIFY_PAGE_DATA;
    const basePathName = pageData[props.type]?.pathName || '';
    if (props.link?.includes(basePathName)) {
      const handle = props.link?.replace(`${basePathName}/`, '') || '';
      const id = props.list?.find((item) => item.handle === handle)?.id || '';
      return { id, handle };
    }
  } else if (props.link?.startsWith('#') && props.type === 'Scroll_To') {
    return { id: props.link?.replace('#', '') || '', handle: '' };
  }
  return { id: '', handle: '' };
};

const searchVal = ref('');
const searchFocus = ref(false);
const phoneUrl = ref<string>(getPhoneDefaultUrl());
const isShowScrollToGuideline = ref<boolean>(true);

const selectedItem = ref<{ id?: string; handle?: string }>(getDefaultSelectedItem());
const emailUrl = ref<{ email?: string; subject?: string; message?: string }>(getMailDefaultUrl());
const infinityScroll = ref<HTMLElement | null>(null);

// code bẩn sửa sau
watch(emailUrl.value, (value) => {
  if (value.email) {
    const emailContent =
      'mailto:' +
      (value.email && value.email) +
      (value.subject && '?subject=' + value.subject) +
      (value.message && '&body=' + value.message);
    emit('setCustomUrl', emailContent);
  }
});

watch(phoneUrl, (value) => {
  emit('setCustomUrl', 'tel:' + value);
});

watch(
  () => props.link,
  (value) => {
    if (props.type !== 'scrollToList') {
      const pageData = props.isInstant ? INSTANT_PAGE_DATA : SHOPIFY_PAGE_DATA;
      const basePathName = pageData[props.type]?.pathName || '';
      if (value !== `${basePathName}/${selectedItem.value?.handle}`) {
        selectedItem.value = { id: '', handle: '' };
      }
    } else {
      if (value !== `#${selectedItem.value?.id}`) {
        selectedItem.value = { id: '', handle: '' };
      }
    }
  },
);

useInfiniteScroll(
  infinityScroll,
  () => {
    // load more

    emit('showMore');
  },
  { distance: 100 },
);

const onSearch = () => {
  infinityScrollToTop();
  emit('onSearch', searchVal.value);
};

const clear = () => {
  searchVal.value = '';
  infinityScrollToTop();
  emit('onSearch', searchVal.value || '');
};

const infinityScrollToTop = () => {
  if (infinityScroll.value) {
    infinityScroll.value.scrollTop = 0;
  }
};

const onClick = (type: Props['type'], item: PickLinkList) => {
  if (selectedItem.value?.id === item.id) {
    emit('setCustomUrl', '');
    selectedItem.value = {
      id: '',
      handle: '',
    };

    return;
  }
  selectedItem.value = item;
  emit('setCustomTitle', item.title ?? '');
  if (type !== 'scrollToList') {
    const pageData = props.isInstant ? INSTANT_PAGE_DATA : SHOPIFY_PAGE_DATA;
    const baseUrl = pageData[type]?.uri || '';
    emit('setCustomUrl', `${baseUrl}/${item.handle}` ?? '#');
  } else {
    emit('setCustomUrl', '#' + item.id ?? '#');
  }
};
const closeScrollToGuideline = () => {
  isShowScrollToGuideline.value = false;
};
</script>
<template>
  <div class="bg-light-300 h-full pt-0">
    <div class="flex h-full flex-col">
      <div
        v-if="
          type === 'pages' ||
          type === 'collections' ||
          type === 'products' ||
          type === 'articles' ||
          type === 'scrollToList'
        "
        class="w-full">
        <div class="my-16 flex items-center px-16">
          <label for="simple-search" class="sr-only">Search</label>
          <div
            class="hover:border-dark-100 border-light-100 bg-light-100 relative flex h-[40px] w-full items-center overflow-hidden rounded-xl border pr-16"
            :class="{
              '!border-primary-300': searchFocus,
            }">
            <div class="text-light-disabled pointer-events-none absolute inset-y-0 left-0 flex items-center pl-16 pr-8">
              <g-base-icon name="search" width="16" height="16"></g-base-icon>
            </div>
            <input
              v-model="searchVal"
              type="text"
              class="text-14 text-light-high block w-full p-8 pl-[48px] outline-none"
              placeholder="Search ..."
              @focus="searchFocus = true"
              @blur="searchFocus = false"
              @input="onSearch" />
            <button
              type="button"
              class="absolute inset-y-0 right-0 flex cursor-pointer items-center px-16 transition-all"
              :class="{
                'visible opacity-100': !!searchVal.length,
                'invisible opacity-0': !searchVal.length,
              }"
              @click="clear()">
              <svg
                class="h-16 w-16 text-gray-500 dark:text-gray-400"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M11 11L21 21M21 11L11 21" stroke="#676767" stroke-width="2" stroke-linecap="round" />
                <circle cx="16" cy="16" r="13" stroke="#676767" stroke-width="2" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="type === 'pages' || type === 'collections' || type === 'products' || type === 'articles'"
        class="flex !h-[500px] flex-col overflow-hidden lg:h-[350px]">
        <perfect-scrollbar ref="infinityScroll">
          <div
            v-for="(item, idx) in props.list"
            :key="idx"
            class="rounded-medium mx-16 mb-16 flex min-h-[56px] cursor-pointer items-center justify-between bg-white px-16"
            @click="onClick(type, item)">
            <div class="flex w-[calc(100%-32px)] items-center">
              <div class="mr-16">
                <svg
                  v-if="type === 'articles'"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9.10001 8.50012C8.82386 8.50012 8.60001 8.72398 8.60001 9.00012C8.60001 9.27626 8.82386 9.50012 9.10001 9.50012H11.6C11.8761 9.50012 12.1 9.27626 12.1 9.00012C12.1 8.72398 11.8761 8.50012 11.6 8.50012H9.10001Z"
                    fill="#676767" />
                  <path
                    d="M8.60001 10.7501C8.60001 10.474 8.82386 10.2501 9.10001 10.2501H10.6C10.8761 10.2501 11.1 10.474 11.1 10.7501C11.1 11.0263 10.8761 11.2501 10.6 11.2501H9.10001C8.82386 11.2501 8.60001 11.0263 8.60001 10.7501Z"
                    fill="#676767" />
                  <path
                    d="M4.2 8.50012C3.92385 8.50012 3.7 8.72398 3.7 9.00012C3.7 9.27626 3.92385 9.50012 4.2 9.50012H6.7C6.97614 9.50012 7.2 9.27626 7.2 9.00012C7.2 8.72398 6.97614 8.50012 6.7 8.50012H4.2Z"
                    fill="#676767" />
                  <path
                    d="M3.7 10.7501C3.7 10.474 3.92385 10.2501 4.2 10.2501H6.7C6.97614 10.2501 7.2 10.474 7.2 10.7501C7.2 11.0263 6.97614 11.2501 6.7 11.2501H4.2C3.92385 11.2501 3.7 11.0263 3.7 10.7501Z"
                    fill="#676767" />
                  <path
                    d="M4.2 12.0001C3.92385 12.0001 3.7 12.224 3.7 12.5001C3.7 12.7763 3.92385 13.0001 4.2 13.0001H6.7C6.97614 13.0001 7.2 12.7763 7.2 12.5001C7.2 12.224 6.97614 12.0001 6.7 12.0001H4.2Z"
                    fill="#676767" />
                  <path
                    d="M8.60001 12.5001C8.60001 12.224 8.82386 12.0001 9.10001 12.0001H11.6C11.8761 12.0001 12.1 12.224 12.1 12.5001C12.1 12.7763 11.8761 13.0001 11.6 13.0001H9.10001C8.82386 13.0001 8.60001 12.7763 8.60001 12.5001Z"
                    fill="#676767" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.5 3.00092C3.94772 3.00092 3.5 3.44863 3.5 4.00092V6.50092C3.5 7.0532 3.94772 7.50092 4.5 7.50092H11.5C12.0523 7.50092 12.5 7.0532 12.5 6.50092V4.00092C12.5 3.44863 12.0523 3.00092 11.5 3.00092H4.5ZM4.5 4.00092H11.5V6.50092H4.5V4.00092Z"
                    fill="#676767" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3 1C2.17157 1 1.5 1.67157 1.5 2.5V13.5C1.5 14.3284 2.17157 15 3 15H13C13.8284 15 14.5 14.3284 14.5 13.5V2.5C14.5 1.67157 13.8284 1 13 1H3ZM2.5 2.5C2.5 2.22386 2.72386 2 3 2H13C13.2761 2 13.5 2.22386 13.5 2.5V13.5C13.5 13.7761 13.2761 14 13 14H3C2.72386 14 2.5 13.7761 2.5 13.5V2.5Z"
                    fill="#676767" />
                </svg>
                <svg
                  v-if="type === 'collections'"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.5 3C1.5 2.17157 2.17157 1.5 3 1.5H5.85C6.67843 1.5 7.35 2.17157 7.35 3V5.85C7.35 6.67843 6.67843 7.35 5.85 7.35H3C2.17157 7.35 1.5 6.67843 1.5 5.85V3ZM3 2.5H5.85C6.12614 2.5 6.35 2.72386 6.35 3V5.85C6.35 6.12614 6.12614 6.35 5.85 6.35H3C2.72386 6.35 2.5 6.12614 2.5 5.85V3C2.5 2.72386 2.72386 2.5 3 2.5Z"
                    fill="#676767" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.5 10.1501C1.5 9.32169 2.17157 8.65012 3 8.65012H5.85C6.67843 8.65012 7.35 9.32169 7.35 10.1501V13.0001C7.35 13.8285 6.67843 14.5001 5.85 14.5001H3C2.17157 14.5001 1.5 13.8285 1.5 13.0001V10.1501ZM3 9.65012H5.85C6.12614 9.65012 6.35 9.87397 6.35 10.1501V13.0001C6.35 13.2763 6.12614 13.5001 5.85 13.5001H3C2.72386 13.5001 2.5 13.2763 2.5 13.0001V10.1501C2.5 9.87397 2.72386 9.65012 3 9.65012Z"
                    fill="#676767" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.1501 1.5C9.32167 1.5 8.6501 2.17157 8.6501 3V5.85C8.6501 6.67843 9.32167 7.35 10.1501 7.35H13.0001C13.8285 7.35 14.5001 6.67843 14.5001 5.85V3C14.5001 2.17157 13.8285 1.5 13.0001 1.5H10.1501ZM13.0001 2.5H10.1501C9.87396 2.5 9.6501 2.72386 9.6501 3V5.85C9.6501 6.12614 9.87396 6.35 10.1501 6.35H13.0001C13.2762 6.35 13.5001 6.12614 13.5001 5.85V3C13.5001 2.72386 13.2762 2.5 13.0001 2.5Z"
                    fill="#676767" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.6501 10.1501C8.6501 9.32169 9.32167 8.65012 10.1501 8.65012H13.0001C13.8285 8.65012 14.5001 9.32169 14.5001 10.1501V13.0001C14.5001 13.8285 13.8285 14.5001 13.0001 14.5001H10.1501C9.32167 14.5001 8.6501 13.8285 8.6501 13.0001V10.1501ZM10.1501 9.65012H13.0001C13.2762 9.65012 13.5001 9.87397 13.5001 10.1501V13.0001C13.5001 13.2763 13.2762 13.5001 13.0001 13.5001H10.1501C9.87396 13.5001 9.6501 13.2763 9.6501 13.0001V10.1501C9.6501 9.87397 9.87396 9.65012 10.1501 9.65012Z"
                    fill="#676767" />
                </svg>
                <svg
                  v-if="type === 'pages'"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.5 2.16667C2.5 1.52233 3.02233 1 3.66667 1H9.90754C10.2367 1 10.5505 1.13904 10.7717 1.38284L13.1975 4.05719C13.3922 4.27182 13.5 4.55123 13.5 4.84101V13.8333C13.5 14.4777 12.9777 15 12.3333 15H3.66667C3.02233 15 2.5 14.4777 2.5 13.8333V2.16667ZM3.66667 2C3.57462 2 3.5 2.07462 3.5 2.16667V11H12.5V5.2H9.97209C9.69595 5.2 9.47209 4.97614 9.47209 4.7V2H3.66667ZM10.4721 2.54099V4.2H11.9769L10.4721 2.54099ZM3.5 13.8333V12H12.5V13.8333C12.5 13.9254 12.4254 14 12.3333 14H3.66667C3.57462 14 3.5 13.9254 3.5 13.8333Z"
                    fill="#676767" />
                </svg>
                <svg
                  v-if="type === 'products'"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.5832 5.30411C5.58299 5.02815 5.35922 4.8045 5.0832 4.8045C4.80706 4.8045 4.5832 5.02836 4.5832 5.3045H5.0832C4.5832 5.3045 4.5832 5.30427 4.5832 5.3045L4.58321 5.30551L4.58321 5.30683L4.58323 5.31039L4.58334 5.32112C4.58347 5.32985 4.5837 5.34172 4.58413 5.35652C4.58499 5.38609 4.58666 5.42745 4.58987 5.47892C4.5963 5.58171 4.60896 5.72574 4.63401 5.89749C4.68373 6.23842 4.78409 6.70159 4.98971 7.17158C5.19544 7.64181 5.51452 8.13671 6.01065 8.51471C6.51337 8.89773 7.16831 9.13783 7.99987 9.13783C8.82856 9.13783 9.48017 8.90432 9.9797 8.52901C10.4732 8.15822 10.788 7.67212 10.989 7.20982C11.1898 6.74815 11.2846 6.29344 11.3301 5.95905C11.3531 5.79055 11.3639 5.6492 11.3691 5.54826C11.3716 5.4977 11.3728 5.45705 11.3733 5.42792C11.3735 5.41335 11.3736 5.40164 11.3736 5.393L11.3736 5.38235L11.3735 5.37879L11.3735 5.37746C11.3735 5.37722 11.3735 5.37643 10.8736 5.38303L11.3735 5.37643C11.3699 5.10031 11.1431 4.87943 10.867 4.88308C10.591 4.88672 10.3702 5.1133 10.3736 5.38924L10.3736 5.39175L10.3734 5.41126C10.3731 5.4298 10.3723 5.45907 10.3704 5.49752C10.3664 5.57458 10.3579 5.68751 10.3393 5.82414C10.3017 6.10003 10.225 6.45902 10.0719 6.81104C9.91914 7.16244 9.69776 7.49004 9.37902 7.72952C9.06629 7.96449 8.62952 8.13783 7.99987 8.13783C7.3731 8.13783 6.93429 7.96126 6.61669 7.71928C6.29252 7.47229 6.06472 7.13385 5.90587 6.77076C5.7469 6.40741 5.66497 6.03725 5.62354 5.75318C5.60302 5.61243 5.5929 5.49605 5.58793 5.41654C5.58545 5.37687 5.58427 5.34661 5.5837 5.32736L5.58324 5.30701L5.5832 5.30411C5.5832 5.30424 5.5832 5.30398 5.5832 5.30411ZM5.0832 5.3045C5.5832 5.3045 5.5832 5.3043 5.5832 5.30411L5.0832 5.3045Z"
                    fill="#676767" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.30176 1C2.51207 1 1.85761 1.61227 1.80509 2.40022L1.07175 13.4002C1.01403 14.266 1.70073 15 2.56843 15H13.4307C14.2984 15 14.9851 14.266 14.9274 13.4002L14.194 2.40022C14.1415 1.61227 13.4871 1 12.6974 1H3.30176ZM2.80287 2.46674C2.82038 2.20409 3.03853 2 3.30176 2H12.6974C12.9606 2 13.1787 2.20409 13.1963 2.46674L13.9296 13.4667C13.9488 13.7553 13.7199 14 13.4307 14H2.56843C2.27919 14 2.0503 13.7553 2.06953 13.4667L2.80287 2.46674Z"
                    fill="#676767" />
                </svg>
              </div>
              <p class="text-14 text-light-high max-w-[calc(100%_-_32px)] truncate font-medium">{{ item.title }}</p>
            </div>
            <svg
              v-if="selectedItem?.id !== item.id"
              class="h-[20px] w-[20px]"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <rect x="2.625" y="2.625" width="14.75" height="14.75" rx="7.375" stroke="#676767" stroke-width="1.25" />
            </svg>
            <svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="10" cy="10" r="4.25" fill="#3C67FF" />
              <rect x="2.625" y="2.625" width="14.75" height="14.75" rx="7.375" stroke="#3C67FF" stroke-width="1.25" />
            </svg>
          </div>
          <div v-if="loading">
            <div v-for="i in Array.from(Array(2).keys())" :key="i" class="my-16 px-16">
              <GLoadingPlaceholder width="100%" height="50px"></GLoadingPlaceholder>
            </div>
          </div>
        </perfect-scrollbar>
      </div>

      <div v-if="type === 'scrollToList'" class="flex !h-[500px] flex-col overflow-hidden lg:h-[350px]">
        <perfect-scrollbar>
          <div
            v-if="isShowScrollToGuideline"
            class="rounded-medium bg-primary-100 mx-16 mb-16 flex w-[calc(100%_-_32px)] justify-between p-16">
            <div class="flex">
              <div class="text-primary-300 mr-16 h-24 w-24">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M22.5 12C22.5 17.799 17.799 22.5 12 22.5C6.20101 22.5 1.5 17.799 1.5 12C1.5 6.20101 6.20101 1.5 12 1.5C17.799 1.5 22.5 6.20101 22.5 12ZM12.7002 6.52321C12.7002 6.93742 12.3644 7.27321 11.9502 7.27321C11.536 7.27321 11.2002 6.93742 11.2002 6.52321C11.2002 6.10899 11.536 5.77321 11.9502 5.77321C12.3644 5.77321 12.7002 6.10899 12.7002 6.52321ZM10.3696 8.22949C9.95542 8.22949 9.61963 8.56528 9.61963 8.97949C9.61963 9.39371 9.95542 9.72949 10.3696 9.72949H11.3401V15.8259C11.3401 16.5162 11.8997 17.0759 12.5901 17.0759H14.3572C14.7714 17.0759 15.1072 16.7401 15.1072 16.3259C15.1072 15.9117 14.7714 15.5759 14.3572 15.5759H12.8401V9.47949C12.8401 8.78914 12.2804 8.22949 11.5901 8.22949H10.3696Z"
                    fill="#2856F8" />
                </svg>
              </div>
              <div class="flex flex-col overflow-hidden">
                <p class="text-14 font-regular text-light-high mb-8 w-full truncate">
                  You can use a link to scroll to any section on current page.
                </p>
                <a
                  class="text-14 text-primary-325 truncate font-medium no-underline"
                  href="https://help.gempages.net/articles/create-anchor-link"
                  target="_blank">
                  Watch Tutorial
                </a>
              </div>
            </div>
            <button
              class="bg-primary-100 !text-light-high hover:bg-light-400 rounded-medium flex h-32 w-32 items-center justify-center"
              @click="closeScrollToGuideline">
              <g-base-icon name="close" width="16" height="16" />
            </button>
          </div>
          <div
            v-for="(item, idx) in props.list"
            :key="idx"
            class="rounded-medium mx-16 mb-16 flex min-h-[56px] w-[calc(100%-32px)] cursor-pointer items-center justify-between bg-white px-16"
            @click="onClick(type, item)">
            <div class="flex w-[calc(100%-32px)] items-center">
              <div class="mr-16">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.3335 1.33301C1.78121 1.33301 1.3335 1.78072 1.3335 2.33301V6.33301C1.3335 6.88529 1.78121 7.33301 2.3335 7.33301H6.3335C6.88578 7.33301 7.3335 6.88529 7.3335 6.33301V2.33301C7.3335 1.78072 6.88578 1.33301 6.3335 1.33301H2.3335ZM6.3335 2.33301H2.3335V6.33301H6.3335V2.33301Z"
                    fill="#676767" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.66683 8.66634C9.11454 8.66634 8.66683 9.11406 8.66683 9.66634V13.6663C8.66683 14.2186 9.11454 14.6663 9.66683 14.6663H13.6668C14.2191 14.6663 14.6668 14.2186 14.6668 13.6663V9.66634C14.6668 9.11406 14.2191 8.66634 13.6668 8.66634H9.66683ZM13.6668 9.66634H9.66683V13.6663H13.6668V9.66634Z"
                    fill="#676767" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.3335 9.66634C1.3335 9.11406 1.78121 8.66634 2.3335 8.66634H6.3335C6.88578 8.66634 7.3335 9.11406 7.3335 9.66634V13.6663C7.3335 14.2186 6.88578 14.6663 6.3335 14.6663H2.3335C1.78121 14.6663 1.3335 14.2186 1.3335 13.6663V9.66634ZM2.3335 9.66634H6.3335V13.6663H2.3335V9.66634Z"
                    fill="#676767" />
                </svg>
              </div>
              <p class="text-14 text-light-high max-w-[calc(100%_-_32px)] truncate font-medium">
                {{ item.isThemeSection ? item.name : `${item.title} ${(item.index || 0) + 1}` }}
                <span class="text-light-low font-regular ml-8">ID: #{{ item.id }}</span>
              </p>
            </div>
            <svg
              v-if="selectedItem?.id !== item.id"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <rect x="2.625" y="2.625" width="14.75" height="14.75" rx="7.375" stroke="#676767" stroke-width="1.25" />
            </svg>
            <svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="10" cy="10" r="4.25" fill="#3C67FF" />
              <rect x="2.625" y="2.625" width="14.75" height="14.75" rx="7.375" stroke="#3C67FF" stroke-width="1.25" />
            </svg>
          </div>
        </perfect-scrollbar>
      </div>

      <div v-if="type === 'emails'">
        <div class="bg-light-300 h-full w-full flex-1 flex-col p-16">
          <div class="mb-16 w-full">
            <p class="text-14 text-light-high mb-8 font-medium">Email Address</p>
            <input
              v-model="emailUrl.email"
              type="text"
              placeholder="chris@gempages.help"
              class="bg-light-100 rounded-medium text-light-high text-14 h-[40px] w-full px-8 outline-none" />
          </div>

          <div class="mb-16 w-full">
            <p class="text-14 text-light-high mb-8 font-medium">Subject</p>
            <input
              v-model="emailUrl.subject"
              type="text"
              placeholder="Need support!"
              class="bg-light-100 rounded-medium text-light-high text-14 h-[40px] w-full px-8 outline-none" />
          </div>
          <div class="w-full">
            <p class="text-14 text-light-high mb-8 font-medium">Messages</p>
            <textarea
              v-model="emailUrl.message"
              type="text"
              placeholder="Email Content"
              class="bg-light-100 rounded-medium text-light-high text-14 h-[40px] min-h-[96px] w-full py-10 px-8 outline-none"></textarea>
          </div>
        </div>
      </div>
      <div v-if="type === 'phone'">
        <div class="bg-light-300 h-full w-full flex-1 flex-col p-16">
          <div class="mb-16 w-full">
            <p class="text-14 text-light-high mb-8 font-medium">Phone number</p>
            <input
              v-model="phoneUrl"
              type="text"
              placeholder="E.g +1-708-943-8364"
              class="bg-light-100 rounded-medium text-light-high text-14 h-[40px] w-full px-8 outline-none" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
