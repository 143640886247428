import type * as Types from '@/types/graphql';

import type { ComputedRef, Ref } from 'vue';
import { ThemePageSelectFragmentDoc } from '../fragments/theme-page.generated';
import { useQuery, type UseQueryOptions } from 'vue-query';
import { appFetcher } from '@/api/gatewayClient';
export type ThemePageQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type ThemePageQueryResponse = {
  themePage?: Types.Maybe<
    Pick<
      Types.ThemePage,
      | 'id'
      | 'name'
      | 'description'
      | 'type'
      | 'default'
      | 'isGlobal'
      | 'isMobile'
      | 'status'
      | 'sectionPosition'
      | 'splitPercentage'
      | 'handle'
      | 'createdAt'
      | 'updatedAt'
      | 'publishedAt'
    > & {
      themeStyle?: Types.Maybe<Pick<Types.ThemeStyle, 'id' | 'data'>>;
      themePageOnlineStoreData?: Types.Maybe<Pick<Types.ThemePageOnlineStoreData, 'id' | 'onlineStoreObjectBaseIDs'>>;
    }
  >;
};

export const ThemePageDocument = `
    query themePage($id: ID!) {
  themePage(id: $id) {
    ...ThemePageSelect
  }
}
    ${ThemePageSelectFragmentDoc}`;
export const useThemePageQuery = <TData = ThemePageQueryResponse, TError = unknown>(
  variables: ThemePageQueryVariables | ComputedRef<ThemePageQueryVariables> | Ref<ThemePageQueryVariables>,
  options?: UseQueryOptions<ThemePageQueryResponse, TError, TData>,
) =>
  useQuery<ThemePageQueryResponse, TError, TData>(
    ['themePage', variables],
    appFetcher<ThemePageQueryResponse, ThemePageQueryVariables>(ThemePageDocument, variables),
    options,
  );

useThemePageQuery.getKey = (
  variables: ThemePageQueryVariables | ComputedRef<ThemePageQueryVariables> | Ref<ThemePageQueryVariables>,
) => ['themePage', variables];
